.box-document {
  background: $white;
  box-shadow: 0 0 10px rgba($black, .1);
  border-radius: 10px;
  padding: 30px 15px 15px;
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: 14px;

  &__icon {
    display: flex;
    justify-content: center;
  }

  &__name {
    margin-top: 10px;
    margin-bottom: 15px;
    overflow: hidden;
    text-overflow: ellipsis;
    width: 100%;
    text-align: center;
  }

  &__download {
    font-weight: 500;
  }
}
