.message-alert {
  display: flex;
  align-items: center;
  border-radius: 10px;
  min-height: 40px;
  padding: 13px 15px 13px 40px;
  position: relative;
  font-size: 12px;
  line-height: 1.1;
  max-width: 600px;
  width: 100%;

  &::after {
    content: '';
    position: absolute;
    left: 15px;
    top: 11px;
    width: 15px;
    height: 15px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &__full-width {
    max-width: none;
  }

  &.m-top {
    margin-top: 10px;
  }

  &.m-bot {
    margin-bottom: 10px;
  }

  &.error {
    box-shadow: inset 0 0 0 1px $beautyBush;
    color: $red;
    background: rgba($red, .06);

    &::after {
      background: url("/images/icons/messages/error.svg") no-repeat;
    }
  }

  &.moderation {
    box-shadow: inset 0 0 0 1px $fireBush;
    color: $fireBush;
    background: rgba($fireBush, .06);

    &::after {
      background: url("/images/icons/messages/moderation.svg") no-repeat;
    }
  }
}
