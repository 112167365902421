.block-content-social {
  display: flex;
  flex-direction: column;
  gap: 0 20px;

  @media ($tablet) {
    flex-direction: row;

    > div {
      width: 50%;
      display: flex;
      flex-direction: column;
    }
  }

  &__content {
    padding: 30px 0 0 0;
    position: relative;
    display: flex;
    flex-direction: column;

    @media ($tablet) {
      padding: 5% 0 5% 35px;
      justify-content: center;
    }
  }

  &__title {
    margin-bottom: 10px;
    line-height: 1.2;
  }

  &__description {
    font-size: 14px;
    margin-bottom: 20px;
    align-self: flex-start;
  }

  &__picture {
    display: flex;
    position: relative;

    @media ($tablet) {
      min-height: 420px;
    }


    img {
      width: 100%;
      object-fit: cover;
      border-radius: 0 50px;
      overflow: hidden;
      padding-right: 15px;
      height: 280px;

      @media ($tablet) {
        height: 100%;
      }

      @media ($desktop) {
        padding-right: 25px;
        border-radius: 0 125px;
      }
    }

    &::after {
      content: '';
      position: absolute;
      width: calc(100% - 15px);
      height: 100%;
      right: 0;
      bottom: -15px;
      border: 2px dashed $tumbleweed;
      z-index: -1;
      border-radius: 0 50px;

      @media ($desktop) {
        right: 0;
        bottom: -25px;
        width: calc(100% - 25px);
        border-radius: 0 125px;
      }
    }
  }

  .social-link {
    align-self: flex-start;
  }
}
