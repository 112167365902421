.c-table-row {
  display: flex;
  align-items: center;
  padding: 15px;
  font-size: 14px;
  flex-wrap: wrap;

  @media ($tablet) {
    flex-wrap: nowrap;
  }

  &__photo {
    display: flex;
    width: 60px;
    height: 36px;
    align-items: center;
    justify-content: center;
    overflow: hidden;
    border-radius: 10px;
    flex: none;
    margin-bottom: auto;

    @media ($tablet) {
      width: 125px;
      height: 75px;
    }

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  &__name {
    font-weight: 500;
    flex-grow: 1;
    line-height: 1.1;
    width: calc(100% - 60px);

    @media ($tablet) {
      order: 0;
      width: auto;
    }

    .weight {
      font-size: 12px;
      line-height: 1;
      font-weight: 400;
      margin-bottom: 12px;
      margin-top: 7px;
      display: flex;

      @media ($tablet) {
        display: none;
      }
    }
  }

  &__price {
    font-weight: 500;
    text-align: center;

    .name {
      font-size: 12px;
      color: #000;
    }

    @media ($tablet) {
      order: 0;
      text-align: left;
    }
  }

  &__action {
    margin-right: -10px;

    @media ($tablet) {
      margin-right: 0;
      display: flex;
    }
  }

  &__status {
    margin-left: -15px;
    height: 36px;
    display: flex;
    align-items: center;

    @media ($tablet) {
      margin-left: 0;
      height: auto;
    }
  }

  .edit {
    display: flex;
    align-items: center;
    justify-content: end;
    margin-left: auto;

    &__image {
      display: flex;
      align-items: center;
      transform: translateY(-2px);
      width: 36px;
      height: 36px;
      justify-content: center;
      background: #F7F3EF;
      border-radius: 5px;

      @media ($large) {
        display: flex;
        margin-right: 10px;
        width: auto;
        height: auto;
        background: transparent;
        border-radius: 0;
      }
    }

    &__name {
      line-height: 1;
      font-weight: 500;
      display: none;

      @media ($large) {
        display: flex;
      }
    }
  }

  & + .c-table-row {
    position: relative;

    &::after {
      content: '';
      position: absolute;
      bottom: 100%;
      left: 15px;
      transform: translateY(-50%);
      height: 1px;
      width: calc(100% - 30px);
      background: $pampasTwo;
    }
  }
}
