.header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 8px 15px;
  background: $white;
  box-shadow: 0px 10px 15px rgba(0, 0, 0, 0.03);
  position: fixed;
  z-index: 10;
  top: 0;
  left: 0;
  right: 0;
  transition: transform 404ms linear;
  transform: translateY(0);
  min-height: 60px;

  &.scroll-up {
    transform: translateY(-100%);
  }

  @media ($desktop) {
    padding: 10px 15px;
    min-height: 82px;
  }

  &:not(&__profile) {
    @media ($large) {
      padding-left: calc(50vw - 600px);
      padding-right: calc(50vw - 600px);
    }
  }

  &::before,
  &::after {
    content: '';
    position: absolute;
    top: 0;
    height: 100%;
    width: 100vw;
    background: $white;
    z-index: -1;
  }

  &::before {
    right: calc(100% - 2px);
  }

  &::after {
    left: calc(100% - 2px);
  }

  &__logo {
    display: flex;
    align-items: center;
    justify-content: center;
    max-width: 50px;
    width: 100%;
    height: auto;
    margin-right: auto;

    @media ($desktop) {
      max-width: 70px;
      margin-right: 2%;
    }
  }

  .burger {
    margin-left: auto;
  }

  .user-block {
    display: none;

    @media ($large-handset) {
      display: flex;
    }
  }

  &__nav {
    display: none;
    margin-right: auto;

    @include breakpoint('lg') {
      @include adaptive-value('gap', 50, 20, 1);
      display: flex;
      width: 100%;
    }

    .item {
      a {
        padding: 0 8px;
        line-height: 30px;
        transition: background 404ms linear;
        display: flex;
        border-radius: 5px;
        font-weight: 500;
        white-space: nowrap;

        &:hover {
          background: $pampas;
        }

        &.active {
          position: relative;

          &::before {
            content: '';
            position: absolute;
            bottom: 0;
            left: 0;
            width: 100%;
            height: 2px;
            background: $bracken;
          }
        }
      }
    }

    .last {
      @include breakpoint('lg') {
        margin-left: auto;
      }
    }
  }

  &__profile {
    padding-top: 4px;
    padding-bottom: 4px;
    min-height: 70px;

    .burger {
      margin-left: 15px;
    }
  }

  &__profile & {
    &__logo {
      margin-right: 15px;
    }

    &__messages {
      margin-left: auto;
      margin-right: 20px;
      position: relative;
      display: none;

      @media ($tablet) {
        display: flex;
      }

      &.not-empty {
        &::after {
          content: '';
          position: absolute;
          top: 2px;
          right: 0;
          width: 7px;
          height: 7px;
          background: $tumbleweed;
          border-radius: 50%;
          overflow: hidden;
        }
      }
    }
  }
}

