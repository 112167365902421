.contacts {
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  &__title {
    font-size: 16px;
    font-weight: bold;
    color: $gray;
    margin-bottom: 10px;

    @media ($tablet) {
      font-size: 18px;
    }
  }

  &__item {
    font-size: 24px;

    @media ($tablet) {
      font-size: 26px;
    }

    span {
      font-weight: 700;
    }
  }
}
