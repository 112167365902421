.section-poll {
  margin-top: 50px;
  margin-bottom: 100px;
  width: calc(100% + var(--page-space));
  max-width: 480px;
  margin-inline: auto;
  display: flex;
  flex-direction: column;
  gap: 24px;

  @include breakpoint('md') {
    margin-bottom: 200px;
  }
}
