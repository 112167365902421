.not-found {
  padding-bottom: 70px;
  padding-top: 30px;

  @media ($tablet) {
    padding-bottom: 100px;
    padding-top: 100px;
    display: flex;
    flex-direction: row-reverse;
    align-items: center;
  }

  &__content {
    margin-top: 30px;

    @media ($tablet) {
      margin-top: 0;
      padding-right: 20px;
      width: 47%;
    }
  }

  &__image {
    max-width: 500px;
    margin: 0 auto;

    @media ($tablet) {
      max-width: none;
      width: 53%;
    }
  }

  &__title {
    font-weight: 500;
    margin-bottom: 10px;
  }

  &__text {
    font-size: 14px;
    line-height: 21px;
  }

  &__actions {
    display: flex;
    align-items: center;
    margin: 20px -9px 0;

    @media ($tablet) {
      margin: 20px -15px 0;
    }

    .btn {
      flex-grow: 1;
      max-width: 200px;
    }

    .action {
      margin: 0 9px;
      font-weight: 500;

      @media ($tablet) {
        margin: 0 15px;
      }
    }
  }
}

.not-found-page {
  background: $white;
}
