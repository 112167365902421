.swiper-navigation {
  display: flex;
  align-items: center;
  font-size: 20px;
  color: $white;

  .swiper-pagination-current {
    font-size: 40px;
  }
}
