.modal {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 15;
  opacity: 0;
  visibility: hidden;
  pointer-events: none;

  &.opened {
    transition: opacity 404ms linear;
    opacity: 1;
    visibility: visible;
    pointer-events: auto;
  }

  &__overlay {
    background: rgba($black, .7);
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    cursor: pointer;
  }

  &__wrapper {
    padding: 30px 20px;
    border-radius: 20px;
    background: #F9F7F5;
    position: relative;
    z-index: 1;
    width: 100%;
    max-width: 700px;
    margin: 15px;
  }

  &__description {
    margin-bottom: 20px;
    text-align: center;
    font-size: 14px;

    @media ($desktop) {
      margin: 0 -35px 20px;
    }
  }

  &__title {
    text-align: center;
    font-size: 20px;
    font-weight: 700;
    padding-bottom: 20px;

    @media ($desktop) {
      font-size: 30px;
    }
  }

  &__content {
    width: 100%;
    max-width: 440px;
    margin: 0 auto;
  }

  &__actions {
    display: flex;
    justify-content: center;
    align-items: center;

    .btn {
      max-width: none;
      flex-grow: 1;
    }

    .link {
      margin-left: 30px;
      font-weight: 500;

      @media ($desktop) {
        margin: 0 40px;
      }
    }
  }
}
