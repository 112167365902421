.message {
  display: flex;
  flex-direction: column;
  padding: 20px 15px;
  box-shadow: 0 0 10px rgba($black, 0.1);
  border-radius: 10px;
  background: $white;
  font-size: 14px;

  & + .message {
    margin-top: 8px;
  }

  @media ($tablet) {
    flex-direction: row;
  }

  @media ($desktop) {
    padding: 25px 20px;
  }

  &.unread {
    position: relative;

    &::after {
      content: '';
      position: absolute;
      left: 5px;
      top: 5px;
      width: 7px;
      height: 7px;
      border-radius: 50%;
      background: $tumbleweed;
    }
  }

  &__left {
    column-gap: 5px;
    min-width: 150px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;

    @media ($tablet) {
      flex-direction: column;
      width: 180px;
      flex: none;
    }

    .title {
      font-weight: 500;
    }

    .date {
      color: $gray;
    }
  }

  &__center {
    flex-grow: 1;
    margin: 8px 0 0 0;

    @media ($tablet) {
      margin: 0 15px;
    }
  }

  &__right {
    color: $gray;
  }

  &__open {
    background: $pearlBush;
    border-radius: 5px;
    padding: 0 10px;
    line-height: 36px;
    display: flex;
    font-weight: 500;
    transition: opacity 404ms linear;
    justify-content: center;
    margin-top: 10px;

    @media ($tablet) {
      margin-top: 0;
    }

    &:hover {
      opacity: .7;
    }
  }

  &.read & {
    &__right {
      display: none;

      @media ($tablet) {
        display: flex;
      }
    }

    &__left .date {
      @media ($tablet) {
        display: none;
      }
    }
  }
}
