.form-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 5px;

  &__title {
    font-size: 24px;
    font-weight: 500;

    @media ($desktop) {
      font-size: 26px;
    }
  }

  &__link {
    font-size: 14px;
    line-height: 1.2;
    cursor: pointer;
    border-radius: 4px;
    transition: background 404ms linear;
    padding: 4px;

    &:hover {
      background: $beautyBush;
    }
  }
}
