.menu {
  margin: 60px 0;

  @media ($tablet) {
    margin: 70px 0 100px;
  }

  &__description {
    font-size: 14px;
    margin-bottom: 15px;
  }

  &__items {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    margin: 10px -10px;

    .shop-item {
      width: calc(100% - 20px);
      margin: 10px;

      @media ($tablet) {
        width: calc(50% - 20px);
      }

      @media ($desktop) {
        width: calc((100% / 3) - 20px);
      }

      @media ($large) {
        width: calc((100% / 4) - 20px);
      }
    }
  }
}
