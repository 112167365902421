.address-item {
  display: grid;
  gap: 15px;
  grid-template-columns: 60px 1fr;
  padding-block: 20px;
  padding-inline: 10px;
  position: relative;

  &::after,
  &::before {
    content: '';
    position: absolute;
    width: 100%;
    height: 1px;
    background-color: #e0d2c3;
    left: 0;
  }

  &::after {
    top: 0;
    transform: translateY(-50%);
  }

  &::before {
    bottom: 0;
    transform: translateY(50%);
  }


  @media (hover: hover) {
    cursor: pointer;
    transition: opacity 404ms ease-in-out;

    &:hover {
      opacity: .7;
    }
  }

  &__main {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 8px;
    line-height: 1;

    h5 {
      color: $bracken;
      font-weight: 500;
    }

    p, a {
      font-size: 12px;
      color: rgba($black, .6);
    }
  }
}
