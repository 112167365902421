.page {
  margin: 30px 0 100px;

  @media ($desktop) {
    margin: 40px 0 150px;
  }

  &.not-full-width {
    text-align: left;

    @media ($desktop) {
      padding-right: 30%;
    }
  }

  &__title {
    display: flex;
    justify-content: center;
    margin-bottom: 20px;
    white-space: break-spaces;

    &.left {
      justify-content: start;
    }

    @media ($desktop) {
      margin-bottom: 30px;
    }
  }

  &__content {
    font-size: 14px;
    line-height: 21px;

    p {
      margin-bottom: 15px;
    }
  }
}
