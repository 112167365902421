.contact-block {
  margin-bottom: 90px;

  &__picture {
    order: 1;
    margin-top: 40px;
  }

  &__items {
    margin-top: 20px;
    display: flex;
    flex-direction: column;

    .social-media {
      margin-top: 20px;

      @media ($large-handset) {
        margin-top: 0;
      }
    }

    @media ($large-handset) {
      flex-direction: row;
      justify-content: space-between;
    }

    @media ($tablet) {
      margin-top: 30px;
    }
  }
}
