.product-page {
  display: flex;
  flex-direction: column;
  margin: 25px 0 80px;

  @media ($tablet) {
    flex-direction: row;
    flex-shrink: 1;
    margin: 30px 0 100px;

    > div {
      width: 50%;
    }

    .product {
      padding-left: 30px;
    }
  }

  &__picture {
    display: flex;
    justify-content: space-around;
    align-items: flex-start;
    margin-bottom: 15px;

    @media ($tablet) {
      margin-bottom: 0;
    }
  }

  &__image-wrapper {
    display: flex;
    flex-direction: column;
    border-radius: 10px;
    overflow: hidden;
  }
}

@media ($tablet) {
  .sticky-element {
    position: -webkit-sticky;
    position: sticky;
    top: 90px;
    transition: top 404ms linear;
    z-index: 1;
  }

  .hidden-menu {
    .sticky-element {
      top: 10px;
    }
  }
}
