.form-check {
  display: flex;
  align-items: center;
}

.toggle {
  --size: 25px;
  --width: calc(22px * 2);
  display: inline-grid;
  align-items: center;
  width: var(--width);
  height: var(--size);
  cursor: pointer;
  appearance: none;

  &::before {
    content: "";
    grid-area: 1 / -1;
    width: var(--width);
    height: var(--size);
    transition: all 250ms ease;
    border-radius: 25px;
    background-color: rgba(79, 43, 4, 0.3);;
  }

  &:hover::before {
    box-shadow: 0 0 0 2px rgba($tumbleweed, .3);
  }

  &::after {
    content: "";
    grid-area: 1 / -1;
    width: calc(var(--size) * 0.8);
    height: calc(var(--size) * 0.8);
    transform: translateX(10%);
    transform-origin: center center;
    transition: all 250ms ease;
    border-radius: 100%;
    background: #fff;
  }

  &:checked {
    &::before {
      background-color: $bracken;
    }

    &::after {
      transform: translateX(calc(var(--width) - var(--size) * 0.8 - 10%));
    }
  }

  &:disabled {
    pointer-events: none;
    filter: grayscale(1);
  }

  &:disabled::before {
    background: #2be;
  }

  & + label {
    padding-left: 10px;
    cursor: pointer;
    font-size: 14px;
  }

  & + .p-left {
    padding-left: 15px;
  }
}
