.pay-item {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: repeat(4, 1fr);
  gap: 15px;
  grid-template-areas:
    "number number"
    "name name"
    "pay pay"
    "date price";

  @media ($tablet) {
    grid-template-columns: repeat(3, 1fr);
    grid-template-rows: repeat(2, 1fr);
    gap: 15px;
    grid-template-areas:
    "number name name"
    "pay date price";
  }

  @media ($large) {
    display: flex;
    justify-content: space-between;

    .name {
      width: 220px;
    }
  }

  &__status {
    &--red {
      color: $burntSienna;
      font-weight: 500;
    }
  }

  .number {
    grid-area: number
  }

  .name {
    grid-area: name
  }

  .pay {
    grid-area: pay
  }

  .date {
    grid-area: date
  }

  .price {
    grid-area: price
  }
}

.payment-page {
  .form-body + .form-body {
    margin-top: 10px;
  }
}
