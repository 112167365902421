.custom-select {
  position: relative;
  font-size: 14px;
  min-height: 50px;
  width: 100%;

  select {
    display: none;
  }

  &.is-invalid {
    .select-selected {
      border: 1px solid $red;
      background: #FEF7F7;
    }
  }

  .select-selected {
    background: $white;
    border-radius: 5px;
    padding: 14px 12px;
    font-size: 14px;
    border: 1px solid rgba(79, 43, 4, 0.2);
    width: 100%;
    position: relative;
    cursor: pointer;
    transition: all 404ms linear;

    &::before {
      content: '';
      position: absolute;
      bottom: 0;
      width: 0;
      left: 50%;
      transform: translateX(-50%);
      height: 1px;
      background: rgba(79, 43, 4, 0.2);
    }

    &.select-arrow-active {
      border: 1px solid rgba(79, 43, 4, 0.4);
      border-bottom: 1px solid transparent;
      border-radius: 5px 5px 0 0;
      background: $white;

      &::before {
        width: calc(100% - 12px);
        transition: width 404ms linear;
      }
    }
  }

  &:not(.search-element) .select-selected {
    &::after {
      content: '';
      position: absolute;
      top: 50%;
      right: 15px;
      background: url('/images/icons/select-arrow.svg') no-repeat center;
      background-size: contain;
      width: 12px;
      height: 10px;
      transform: translateY(-50%) scale(-1);
      opacity: .7;
      transition: all 404ms linear;
    }

    &.select-arrow-active {
      &::after {
        transform: translateY(-50%) scale(1);
        opacity: 1;
      }
    }
  }

  .select-items {
    max-height: 190px;
    padding-top: 10px;
    padding-bottom: 10px;
    overflow: auto;
    position: absolute;
    background-color: $white;
    top: calc(100% - 1px);
    left: 0;
    right: 0;
    z-index: 99;
    border: 1px solid rgba(79, 43, 4, 0.5);
    border-top: none;
    border-radius: 0 0 5px 5px;
    transition: all 404ms linear;
    opacity: 1;
    visibility: visible;
    pointer-events: all;
    transform: translateY(0);

    .select-list div {
      line-height: 35px;
      padding: 0 12px;
      cursor: pointer;
      transition: background 404ms linear;

      &.same-as-selected,
      &:hover {
        background: $pampas;
      }
    }

    &.select-hide {
      opacity: 0;
      visibility: hidden;
      pointer-events: none;
      transform: translateY(20px);
    }
  }

  .btn-search {
    position: absolute;
    width: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    right: 0;
    top: 0;
    transition: box-shadow 404ms linear;
    z-index: 1;
    cursor: pointer;

    &.disabled {
      pointer-events: none;
      opacity: .4;
    }

    &:hover {
      box-shadow: inset 0 0 0 1px rgba($black, .1);
    }

    &::after {
      content: '';
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      background: url('/images/icons/search-icon.svg') no-repeat center;
      width: 18px;
      height: 18px;
    }
  }

  .select-search {
    padding: 0 12px 10px;

    input {
      padding-top: 8px;
      padding-bottom: 8px;
      height: 30px;
      min-height: auto;
    }
  }

  &.is-arrow {
    .btn-search {
      display: none;
    }

    .select-selected {
      &::after {
        content: '';
        position: absolute;
        top: 50%;
        right: 15px;
        background: url('/images/icons/select-arrow.svg') no-repeat center;
        background-size: contain;
        width: 12px;
        height: 10px;
        transform: translateY(-50%) scale(-1);
        opacity: .7;
        transition: all 404ms linear;
      }

      &.select-arrow-active {
        &::after {
          transform: translateY(-50%) scale(1);
          opacity: 1;
        }
      }
    }
  }
}
