.form-body {
  padding: 30px 15px;
  background: $white;
  box-shadow: 0 0 10px rgba($black, .1);
  border-radius: 10px;

  @media ($tablet) {
    padding: 30px 20px;
  }

  &.red-status {
    border: 1px solid $burntSienna;
    background: rgba($burntSienna, .07);
  }

  .item-text {
    margin-bottom: 20px;

    &.not-margin {
      margin-bottom: 0;
    }
  }

  .empty-text {
    font-size: 16px;
    font-weight: 500;
    line-height: 1.2;
    color: $cloudy;
    display: flex;
    justify-content: center;
    text-align: center;

    @media ($tablet) {
      font-size: 18px;
    }
  }

  .label {
    font-size: 14px;
    font-weight: 500;
    line-height: 1;
    margin-bottom: 5px;
    color: $cloudy;
  }

  .text {
    font-size: 14px;
    line-height: 1.2;
  }

  .paragraph {
    font-size: 14px;
    line-height: 21px;

    & + .paragraph {
      margin-top: 15px;
    }

    &.m-top {
      display: flex;
      margin-top: 15px;
    }
  }

  .photo {
    display: flex;
    align-items: center;
    position: relative;
    min-height: 20px;

    &::after {
      content: '';
      position: absolute;
      left: 0;
      top: 43%;
      transform: translateY(-50%);
      width: 18px;
      height: 15px;
      display: flex;
      align-items: center;
      justify-content: center;
      background: url('/images/icons/photo-icon.svg') no-repeat center;
    }

    &__name {
      font-size: 14px;
      padding-left: 25px;
    }
  }

  .phones {
    display: flex;
    flex-direction: column;

    &__item {
      font-size: 14px;
    }
  }

  .m-bot {
    margin-bottom: 15px;
  }

  .work-schedule__item + .work-schedule__item {
    margin-top: 0;
  }
}

.form-block {
  & + .form-block {
    margin-top: 25px;
  }

  &.max-container {
    width: 100%;
    max-width: 600px;
    margin: 0 auto;
  }
}

.form-action {
  display: flex;
  align-items: center;
  margin: 0 -9px;

  @media ($tablet) {
    margin: 0 -15px;
  }

  .btn {
    flex-grow: 1;
    max-width: 200px;
  }

  .action {
    margin: 0 9px;
    font-weight: 500;

    @media ($tablet) {
      margin: 0 15px;
    }
  }
}
