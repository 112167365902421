.google-search-location {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 6px;
  padding-bottom: 10px;

  @media ($tablet) {
    padding-bottom: 20px;
    margin-bottom: 20px;
    position: relative;

    &:after {
      content: '';
      width: calc(100% + 30px);
      left: -15px;
      height: 1px;
      bottom: 0;
      position: absolute;
      background: $mercury;
    }
  }

  label {
    font-size: 14px;
    display: none;

    @media ($tablet) {
      display: inline-flex;
    }
  }
}
