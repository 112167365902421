.blog-item {
  display: flex;
  flex-direction: column;

  &.swiper-slide {
    min-width: 280px;
    max-width: 80%;

    @media ($large-handset) {
      max-width: 60%;
    }

    @media ($tablet) {
      max-width: 385px;
    }

    @media ($desktop) {
      max-width: none;
      width: calc(100% / 3);
      flex-shrink: 1;
    }
  }

  @media (hover: hover) {
    cursor: pointer;

    &:hover & {
      &__picture img {
        transform: scale(1.1);
      }
    }
  }

  &__picture {
    display: flex;
    flex-direction: column;
    height: 100%;
    max-height: 190px;
    border-radius: 10px;
    overflow: hidden;

    img {
      object-fit: cover;
      height: 100%;
      transition: transform 404ms linear;
    }

    @media ($desktop) {
      max-height: 280px;
    }
  }

  &__content {
    margin-top: 15px;
    font-size: 18px;
    display: flex;
    flex-direction: column;
    flex-grow: 1;
  }

  &__title {
    font-weight: 500;
    margin-bottom: auto;
  }

  &__date {
    margin-top: 10px;
  }
}
