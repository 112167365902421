.form-poll {
  display: flex;
  flex-direction: column;
  gap: 24px;

  &__main {
    display: flex;
    flex-direction: column;
    gap: 20px;
  }

  &__block {
    display: flex;
    flex-direction: column;
    gap: 14px;
  }

  &__sub-title {
    font-weight: 500;
    font-size: 18px;
  }

  &__items {
    display: flex;
    flex-direction: column;
    gap: 18px;
  }

  .form-group {
    margin-bottom: initial;
  }

  &__group {
    display: flex;
    flex-direction: column;
    gap: 10px;
  }

  &__item-title {
    display: flex;
    flex-direction: column;
    gap: 16px;
    font-weight: 500;
    font-size: 14px;
  }

  &__action {
    .btn {
      @include breakpoint('md') {
        width: auto;
        padding-inline: 60px;
      }
    }
  }
}
