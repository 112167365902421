.blog {
  &__header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 25px;
  }

  &__all {
    font-weight: 500;
  }

  &__items {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;

    @media ($tablet) {
      margin-left: -10px;
      margin-right: -10px;
    }

    .blog-item {
      width: 100%;
      margin-bottom: 30px;

      @media ($tablet) {
        width: calc(50% - 20px);
        margin-left: 10px;
        margin-right: 10px;
      }

      @media ($desktop) {
        width: calc((100% / 3) - 20px);
        margin: 0 10px;
      }
    }
  }
}
