.statistics-profile {
  display: flex;
  flex-wrap: wrap;
  margin: -15px -4px 10px;
  padding: 0 8px;

  @media ($desktop) {
    margin: -30px -10px 0;
    padding: 0 30px;
  }

  &__item {
    width: calc(50% - 8px);
    margin: 0 4px 8px;

    @media ($tablet) {
      margin: 0 10px 8px;
      width: calc(25% - 20px);
    }
  }
}
