.load-file {
  display: flex;
  align-items: center;
  gap: 8px;
  color: $bracken;
  cursor: pointer;

  &__icon {
    width: 24px;
    aspect-ratio: 1;
  }
}
