.menu-page {
  .search-shop {
    padding: 0;
  }

  .search-shop-block {
    margin: 0 auto;
    transform: translateY(60px);
  }

  .menu .custom-select {
    display: block;

    @media ($desktop) {
      display: none;
    }
  }

  .position-menu {
    margin-top: 15px;
    margin-bottom: 20px;

    @media ($desktop) {
      margin-top: 25px;
    }

    &__items {
      display: none;
      flex-wrap: wrap;

      @media ($desktop) {
        display: flex;
        flex-wrap: wrap;
        gap: 15px;
      }

      .item {
        font-size: 18px;
        line-height: 18px;
        cursor: pointer;
        transition: text-shadow 404ms linear;

        &:hover {
          text-shadow: 0 0 1px;
        }

        &.active {
          font-size: 24px;
          font-weight: 500;
        }
      }
    }
  }
}

@media (max-width: 992px) {
  .position-menu {
    position: -webkit-sticky;
    position: sticky;
    top: 65px;
    transition: top 404ms linear;
    z-index: 1;
  }

  .hidden-menu {
    .position-menu {
      top: 5px;
    }
  }
}
