.map {
    width: 100vw;
    height: 80vh;
}

.icon-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}
