.home-banner,
.contact-block {
  display: flex;
  flex-direction: column;
  margin-top: 30px;
  position: relative;

  &::after {
    content: '';
    background: url("/images/bg/home-banner.svg") no-repeat center;
    background-size: cover;
    position: absolute;
    top: 0;
    left: -15px;
    height: 80%;
    width: calc(100% + 30px);
    z-index: -2;
    transform: scaleX(-1);
  }

  @media ($tablet) {
    padding: 10% 30px 10% 0;
    width: 50%;
    max-width: 450px;

    &::after {
      background-size: cover;
      position: absolute;
      top: 10%;
      width: 900px;
      z-index: -2;
      left: -80%;
    }
  }

  &__title {
    margin-bottom: 10px;
  }

  &__description {
    font-size: 14px;
    margin-bottom: 20px;
  }

  &__picture {
    height: 230px;
    display: flex;
    flex-direction: column;
    position: relative;

    @media ($tablet) {
      position: absolute;
      top: 0;
      left: 100%;
      width: 100%;
      height: 100%;
    }

    @media ($desktop) {
      width: calc(100vw - 480px);
    }

    @media ($large) {
      width: calc(100vw);
      max-width: 750px;
    }

    &::after {
      content: '';
      position: absolute;
      width: calc(100% - 15px);
      height: calc(100% - 10px);
      right: 15px;
      bottom: 0;
      border: 2px dashed $tumbleweed;
      z-index: -1;
      border-radius: 50px 0;

      @media ($desktop) {
        right: 5px;
        border-radius: 125px 0;
      }
    }

    img {
      height: 100%;
      object-fit: cover;
      padding: 0 0 15px 10px;
      border-radius: 50px 0;
      overflow: hidden;

      @media ($desktop) {
        padding: 0 0 20px 30px;
        border-radius: 125px 0;
      }
    }
  }

  &__actions {
    display: flex;
    align-items: center;
    margin: 20px -9px 0;

    @media ($tablet) {
      margin: 0 -14px;
    }

    .btn {
      flex-grow: 1;
      max-width: 200px;
    }

    .action {
      margin: 0 9px;
      font-weight: 500;

      @media ($tablet) {
        margin: 0 14px;
      }
    }
  }
}
