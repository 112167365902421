.profile-header {
  position: relative;
  border-radius: 0 0 15px 15px;
  overflow: hidden;
  min-height: 90px;
  display: flex;
  align-items: center;
  justify-content: center;

  @media ($desktop) {
    font-size: 26px;
    min-height: 120px;
  }

  &__image {
    position: absolute;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100%;
    width: 100%;
    z-index: -1;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  &__title {
    font-size: 24px;
    font-weight: 500;
    color: $white;
    padding: 15px;

    @media ($tablet) {
      font-size: 26px;
    }
  }
}
