.c-file-input {
  display: flex;

  &__input {
    opacity: 0;
    height: 0;
    width: 0;
    pointer-events: none;
    visibility: hidden;
  }

  &__label {
    line-height: 33px;
    padding-left: 40px;
    padding-right: 15px;
    position: relative;
    background: $pampas;
    border-radius: 5px;
    transition: background-color 404ms linear;
    max-width: 350px;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;

    &:hover {
      background-color: rgba($tumbleweed, .5);
    }

    &::after {
      content: '';
      position: absolute;
      left: 10px;
      top: 8px;
      width: 18px;
      height: 15px;
      display: flex;
      background: url('/images/icons/photo-icon.svg') no-repeat center;
    }

    &.is-document::after {
      background: url('/images/icons/document-icon.svg') no-repeat center;
    }
  }
}
