.search-shop {
  padding: 70px 0;
  position: relative;
  min-height: 220px;

  &::after {
    content: '';
    position: absolute;
    top: 0;
    left: -15px;
    width: 100vw;
    height: 100%;
    background: url('/images/bg/map.jpg') no-repeat center;
    background-size: cover;
    z-index: -1;

    @media ($large) {
      left: calc(600px - 50vw);
    }
  }

  @media ($tablet) {
    padding: 40px 0;
  }
}
