.article-blog {
  margin-top: 30px;
  margin-bottom: 80px;

  > * + * {
    margin-top: 10px;
  }

  img {
    border-radius: 30px;
  }

  @media ($tablet) {
    margin-top: 50px;
    margin-bottom: 150px;
  }

  &__header {
    display: flex;
    flex-direction: column;
    margin-bottom: 15px;
  }

  &__date {
    font-size: 18px;
  }

  iframe {
    width: 100%;
    aspect-ratio: 16 / 9;
  }

  .picture-block {
    display: flex;
    justify-content: center;
  }

  .content {
    font-size: 14px;
    line-height: 21px;
    max-width: 840px;
    padding: 10px 0;
    white-space: break-spaces;
    flex-grow: 1;
  }

  .image {
    display: flex;

    @media ($tablet) {
      width: 50%;
      flex: none;
    }
  }

  .picture-text,
  .text-picture {
    display: flex;
    flex-direction: column;

    @media ($tablet) {
      flex-direction: row;
    }

    .content {
      @media ($tablet) {
        padding-right: 4%;
      }
    }
  }

  .picture-text {
    display: flex;
    flex-direction: column;

    @media ($tablet) {
      flex-direction: row;
    }

    .content {
      @media ($tablet) {
        padding-left: 4%;
      }
    }
  }
}
