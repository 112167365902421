.social-media {
  display: flex;
  flex-direction: column;

  &__title {
    font-size: 16px;
    font-weight: bold;
    color: $gray;
    margin-bottom: 10px;

    @media ($tablet) {
      font-size: 18px;
    }
  }

  &__items {
    display: flex;
    margin: 0 -10px;

    a {
      margin: 0 10px;
    }
  }
}
