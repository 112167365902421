.product {
  font-size: 14px;

  &__title {
    line-height: 1.2;
    font-weight: 500;
    margin-bottom: 10px;
  }

  &__weight,
  &__count {
    display: flex;
    align-items: center;
    margin: 15px 0;

    .product__sub-title {
      min-width: 90px;
      padding-right: 10px;
      margin-bottom: 0;
    }
  }

  &__price {
    font-size: 30px;
    font-weight: 500;
    line-height: 1;
    padding: 15px 0;
  }

  &__btns {
    display: flex;
    align-items: center;
    margin: 20px -9px 0;

    @media ($tablet) {
      margin: 20px -14px 0;
    }

    .btn {
      flex-grow: 1;
      max-width: 200px;
    }

    .action {
      margin: 0 9px;
      font-weight: 500;

      @media ($tablet) {
        margin: 0 14px;
      }
    }
  }

  &__info {
    font-size: 14px;
    margin-bottom: 20px;
  }

  &__action {
    display: flex;
    flex-direction: column;
    align-items: flex-start;

    .btn {
      width: auto;
      padding-inline: 15px;
    }
  }

  &__item {
    margin-top: 30px;
    display: flex;
    flex-direction: column;
  }

  &__sub-title {
    font-size: 14px;
    font-weight: 500;
    line-height: 1;
    color: $gray;
    margin-bottom: 10px;
  }

  &__video {
    width: 100%;
    height: 0;
    position: relative;
    padding-top: 56.25%;
    border-radius: 20px;
    overflow: hidden;

    iframe {
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0;
      left: 0;
      border: 0;
    }
  }
}

.recipes-step {
  & + .recipes-step {
    margin-top: 10px;
  }

  &__name {
    font-weight: 500;
    margin-bottom: 5px;
  }
}
