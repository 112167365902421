.img-text-row {
  display: flex;
  align-items: flex-start;

  &__picture {
    width: 60px;
    display: flex;
    align-items: center;
    flex: none;

    img {

    }
  }

  &__title {
    margin-bottom: 3px;
  }

  &__description {
    font-size: 13px;
    line-height: 19px;
  }
}
