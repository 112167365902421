.mobile-lang {
  display: flex;
  justify-content: center;
  align-items: center;

  &__item {
    font-size: 14px;
    font-weight: 500;
    padding: 0 25px;
    line-height: 33px;
    text-transform: uppercase;
    opacity: .5;

    &.active {
      opacity: 1;
    }
  }

  &__item + &__item {
    position: relative;

    &::after {
      content: '';
      position: absolute;
      left: 0;
      top: 0;
      height: 100%;
      width: 1px;
      transform: translateX(-50%);
      background: $seashell;
    }
  }
}
