.group-location {
  display: flex;
  flex-direction: column;
  gap: 5px;

  &__label {
    font-size: 14px;
  }

  &__items {
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
  }
}
