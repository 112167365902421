.slider-block {
  .swiper-navigation {
    @extend .unselect;
    bottom: 0;
    position: absolute;
    left: calc(50vw - 600px);
    z-index: 10;

    &__pagination {
      margin: 0 5px;
    }

    &__next,
    &__prev {
      padding: 10px;
      cursor: pointer;
    }
  }

  .swiper-image {
    position: relative;
    border-radius: 20px;
    overflow: hidden;

    @media ($large) {
      border-radius: 0;
      width: 100vw;
      left: calc(600px - 50vw);
    }

    .swiper-slide {
      display: flex;
      justify-content: center;
    }

    img {
      height: 100%;
      min-height: 200px;
      object-fit: cover;

      @media ($tablet) {
        height: 350px;
        width: 100%;
      }

      @media ($desktop) {
        height: 550px;
      }

      @media ($large) {
        width: 100%;
        height: 550px;
        object-fit: cover;
      }
    }
  }
}
