.preview-file {
  display: grid;
  align-items: center;
  gap: 8px;
  color: $bracken;
  grid-template-columns: auto auto auto;

  &__icon {
    width: 24px;
    aspect-ratio: 1;
    flex: none;
  }

  &__name {
    overflow: hidden;
    display: flex;

    span {
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
  }

  &__remove {
    background-color: rgba($bracken, .1);
    border-radius: 50%;
    width: 24px;
    aspect-ratio: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    flex: none;

    .icon {
      width: 10px;
    }

    @media (hover: hover) {
      transition: opacity 404ms ease-in-out;
      cursor: pointer;
      opacity: .6;

      &:hover {
        opacity: 1;
      }
    }
  }
}
