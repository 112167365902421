.side-cart {
  position: fixed;
  top: 0;
  right: 0;
  max-width: 450px;
  width: 100%;
  height: 100%;
  background: $white;
  z-index: 20;
  background: $bgGradient;
  box-shadow: 0 10px 15px rgba(0, 0, 0, 0.03);
  padding: 0 15px;
  display: flex;
  flex-direction: column;
  transform: translateX(100%);
  transition: transform 404ms linear;

  &__overlay {
    position: absolute;
    width: 100vw;
    height: 100%;
    background: rgba($black, .8);
    right: 100%;
    transform: translateX(-100%);
    transition: transform 404ms linear;
  }

  &.open-cart {
    transform: translateX(0);

    .side-cart__overlay {
      transform: translateX(0);
    }
  }

  &__header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 30px 0;
    border-bottom: 1px solid #CACACA;
  }

  &__title {
    font-size: 30px;
    font-weight: bold;
    line-height: 1;
  }

  &__count {
    margin-left: auto;
    font-size: 12px;

    span {
      padding-left: 5px;
    }
  }

  &__close {
    width: 20px;
    height: 20px;
    display: flex;
    align-items: center;
    margin-left: 15px;
    cursor: pointer;
    background: url('/images/icons/close-cart.svg') no-repeat center;
  }

  &__main {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    padding: 20px 15px 20px 0;
    margin-right: -15px;
    overflow: auto;
  }

  &__footer {
    padding: 20px 0;
  }

  .cart-item + .cart-item {
    margin-top: 15px;
  }
}

.side-cart-empty {
  margin: auto;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  &__title {
    font-size: 24px;
    font-weight: bold;
    margin-bottom: 10px;
  }

  .btn {
    max-width: 180px;
  }
}

.all-pricing {
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 24px;
  line-height: 36px;
  font-weight: bold;
  padding-bottom: 15px;
  position: relative;

  &::after {
    content: '';
    position: absolute;
    bottom: calc(100% + 20px);
    width: 100%;
    height: 1px;
    background: $silver;
  }
}
