.profile-wrapper {
  display: flex;
  background: url("/images/bg/footer.png");
  min-height: calc(100vh - 70px);
  flex-direction: column-reverse;

  @media ($desktop) {
    height: calc(100vh - 70px);
    min-height: auto;
    flex-direction: row;
  }

  &__side-nav {
    display: flex;
    flex-direction: column;
    margin-top: auto;

    @media ($desktop) {
      margin-top: 0;
      flex-grow: 1;
      height: 100%;
      overflow-y: auto;

      .partner-menu__link.active::before {
        left: 15px;
      }
    }

      .copyright {
      font-size: 12px;

      &::after,
      &::before {
        content: none;
      }
    }
  }

  .partner-menu {
    margin: 0 0 0 -15px;
    flex: none;
    background: $white;
    display: none;

    @media ($desktop) {
      display: block;
      width: 280px;
      flex-grow: 1;
    }

    &__items {
      display: flex;
      flex-direction: column;
      height: 100%;

      .mt-auto {
        margin-top: auto;
      }
    }
  }

  .profile-content {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;

    @media ($desktop) {
      width: calc(100vw - 265px);
      overflow-y: auto;
      overflow-x: hidden;
      margin-bottom: 15px;
      height: calc(100vh - 90px);
      min-height: auto;
    }
  }

  & + .copyright {
    box-shadow: 0px -10px 15px rgba(0, 0, 0, 0.03);
  }
}
