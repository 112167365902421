.second-btn {
  display: flex;
  position: relative;
  overflow-x: auto;
  gap: 10px;
  scrollbar-width: none;

  &::-webkit-scrollbar {
    display: none;
  }

  @include breakpoint('md') {
    display: grid;
    gap: 0;
    border-radius: 20px 5px;
    box-shadow: inset 0 0 0 1px $bracken;
    background: $white;
    grid-template-columns: repeat(3, 1fr);
    overflow: hidden;
  }

  &__item {
    font-size: 14px;
    line-height: 21px;
    padding: 10px;
    outline: none;
    font-weight: 500;
    border: none;
    transition: color 404ms linear, opacity 404ms linear;
    position: relative;
    z-index: 1;
    cursor: pointer;
    text-align: center;
    white-space: nowrap;
    background: $white;
    border-radius: 50vmax;

    @include breakpoint('md') {
      background: transparent;
      border-radius: 0;
    }

    &:not(.active):hover {
      opacity: .5;
    }

    &.active {
      color: $white;
      background-color: $bracken;
    }
  }
}
