.radio-item {
  display: flex;
  align-items: center;
  position: relative;
  border-radius: 50vmax;
  cursor: pointer;
  font-size: 14px;
  line-height: 1;
  color: $bracken;
  padding: 10px;
  box-shadow: inset 0 0 0 1px transparent;
  transition: box-shadow 404ms ease-in-out;
  background-color: $white;

  &:focus,
  &:hover {
    box-shadow: inset 0 0 0 1px $bracken;
  }

  &.active {
    color: $white;
    box-shadow: inset 0 0 0 2vmax $bracken;
  }
}
