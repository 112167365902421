.single-message {
  display: flex;
  flex-direction: column;

  &__header {
    position: relative;
    padding-left: 30px;
    margin-bottom: 10px;

    @media ($tablet) {
      padding-left: 40px;
    }
  }

  &__back-btn {
    position: absolute;
    top: 5px;
    left: 0;
    width: 17px;
    transform: scale(-1);
    display: flex;
    flex: none;

    img {
      width: 100%;
    }

    @media ($tablet) {
      width: 20px;
      top: 8px;
    }
  }

  &__title {
    font-size: 18px;
    font-weight: 500;

    @media ($tablet) {
      font-size: 26px;
    }
  }

  &__date {
    font-size: 14px;
    color: $silver;
  }

  .paragraph a {
    font-weight: 600;
  }

  .form-action,
  .element-location {
    margin-top: 20px;
  }
}
