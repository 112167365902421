.our-contacts {
  display: flex;
  flex-direction: column;

  @media ($tablet) {
    flex-direction: row;

    > div {
      width: 50%;
    }
  }

  &__title {
    margin-bottom: 20px;
  }

  &__content {
    padding-top: 20px;

    @media ($tablet) {
      padding: 30px 0 30px 8%;
    }
  }

  .work-schedule {
    padding-top: 20px;

    @media ($desktop) {
      padding-top: 20px;
    }
  }

  &__map {
    margin: 0 -15px;
    min-height: 220px;
    border-radius: 20px;
    box-shadow: 0 10px 15px rgba($black, .03);
    overflow: hidden;

    @media ($tablet) {
      margin: 0;
    }
  }
}

.map-locations {
  width: 100%;
  height: 100%;
}
