.profile-map-page {
  height: calc(100vh - 115px);
  width: 100%;
  position: relative;
  display: flex;
  flex-direction: column;
  flex-grow: 1;

  @media ($tablet) {
    height: 100%;
    flex-direction: column;
  }
}

.profile-map-wrapper, .profile-map {
  width: 100%;
  height: 100%;
}

.profile-map-menu {
  width: 100%;
  padding: 15px;
  background: rgba($white, 0.9);
  box-shadow: 0 0 10px rgba($black, 0.1);
  z-index: 5;
  display: flex;
  flex-direction: column;

  @media ($tablet) {
    position: absolute;
    left: 10px;
    top: 0;
    max-width: 320px;
    height: 100%;
  }
}

.profile-map-search {
  display: flex;
  flex-direction: column;

  &__title {
    font-size: 24px;
    margin-bottom: 10px;

    @media ($desktop) {
      font-size: 26px;
    }
  }

  &__description {
    font-size: 14px;
    margin-bottom: 5px;
  }

  .btn {
    margin-top: 15px;
  }
}

.booking-location {
  overflow: auto;
  height: 160px;
  flex: none;

  @media ($tablet) {
    height: 290px;
  }

  &__items {
    display: flex;
    flex-direction: column;
    min-height: 200px;
  }

  .booking-item + .booking-item {
    margin-top: 10px;
  }
}
