:root {
  --page-space: 15px;
}

html, body {
  min-height: 100%;
  scroll-behavior: smooth;
}

html {
  @media (max-width: 767px) {
    overflow-x: hidden;
  }
}

body {
  font-family: 'Gotham Pro', sans-serif;
  font-weight: 400;
  font-size: 16px;
  line-height: 1.4;
  background: $springWood;
  padding-top: 60px;
  overflow-x: hidden;

  @media ($desktop) {
    padding-top: 82px;

    &.body-profile {
      padding-top: 70px;
    }
  }

  main {
    flex-grow: 1;
  }
}

* {
  box-sizing: border-box;
  font-family: 'Gotham Pro', sans-serif;

  &::after,
  &::before {
    box-sizing: border-box;
  }
}

.container {
  display: flex;
  flex-direction: column;
  min-height: 100%;
  padding-left: 15px;
  padding-right: 15px;

  @media ($large) {
    padding-left: calc(50vw - 600px);
    padding-right: calc(50vw - 600px);

    &.big {
      padding-left: calc(50vw - 700px);
      padding-right: calc(50vw - 700px);
    }
  }
}

.lock-scroll {
  overflow-y: hidden;
}

.margin-section {
  margin-top: 80px;
  margin-bottom: 80px;

  @media ($tablet) {
    margin-top: 100px;
    margin-bottom: 100px;
  }

  @media ($desktop) {
    margin-top: 150px;
    margin-bottom: 150px;
  }
}

a {
  color: $black;
  text-decoration: none;
  background: linear-gradient(0deg, $black, $black) no-repeat right bottom / 0 1px;
  transition: background-size 350ms;

  @media (hover: hover) {
    &:not(.not-hover):where(:hover, :focus-visible) {
      background-size: 100% 1px;
      background-position-x: left;
    }
  }
}

b {
  font-weight: bold;
}

li {
  &::marker {
    content: '';
  }
}

img {
  max-width: 100%;
  width: auto;
  height: auto;
}

.unselect {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.grid-3 {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  column-gap: 25px;
}

.grid-2 {
  display: grid;
  grid-template-columns: repeat(2, minmax(150px, 1fr));
  column-gap: 25px;
}

.video-iframe {
  aspect-ratio: 16/9;
  width: 100%;
}

.icon {
  display: flex;
  max-width: 100%;
  height: 100%;
}
