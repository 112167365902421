@charset "UTF-8";
@font-face {
  font-family: "Gotham Pro";
  src: url("GothamPro-Bold.eot");
  src: local("Gotham Pro Bold"), local("GothamPro-Bold"), url("GothamPro-Bold.eot?#iefix") format("embedded-opentype"), url("GothamPro-Bold.woff2") format("woff2"), url("GothamPro-Bold.woff") format("woff"), url("GothamPro-Bold.ttf") format("truetype");
  font-weight: bold;
  font-style: normal;
}
@font-face {
  font-family: "Gotham Pro";
  src: url("GothamPro-Light.eot");
  src: local("Gotham Pro Light"), local("GothamPro-Light"), url("GothamPro-Light.eot?#iefix") format("embedded-opentype"), url("GothamPro-Light.woff2") format("woff2"), url("GothamPro-Light.woff") format("woff"), url("GothamPro-Light.ttf") format("truetype");
  font-weight: 300;
  font-style: normal;
}
@font-face {
  font-family: "Gotham Pro";
  src: url("GothamPro-Black.eot");
  src: local("Gotham Pro Black"), local("GothamPro-Black"), url("GothamPro-Black.eot?#iefix") format("embedded-opentype"), url("GothamPro-Black.woff2") format("woff2"), url("GothamPro-Black.woff") format("woff"), url("GothamPro-Black.ttf") format("truetype");
  font-weight: 900;
  font-style: normal;
}
@font-face {
  font-family: "Gotham Pro";
  src: url("GothamPro-Italic.eot");
  src: local("Gotham Pro Italic"), local("GothamPro-Italic"), url("GothamPro-Italic.eot?#iefix") format("embedded-opentype"), url("GothamPro-Italic.woff2") format("woff2"), url("GothamPro-Italic.woff") format("woff"), url("GothamPro-Italic.ttf") format("truetype");
  font-weight: normal;
  font-style: italic;
}
@font-face {
  font-family: "Gotham Pro Narrow";
  src: url("GothamProNarrow-Bold.eot");
  src: local("Gotham Pro Narrow Bold"), local("GothamProNarrow-Bold"), url("GothamProNarrow-Bold.eot?#iefix") format("embedded-opentype"), url("GothamProNarrow-Bold.woff2") format("woff2"), url("GothamProNarrow-Bold.woff") format("woff"), url("GothamProNarrow-Bold.ttf") format("truetype");
  font-weight: bold;
  font-style: normal;
}
@font-face {
  font-family: "Gotham Pro";
  src: url("GothamPro-Medium.eot");
  src: local("Gotham Pro Medium"), local("GothamPro-Medium"), url("GothamPro-Medium.eot?#iefix") format("embedded-opentype"), url("GothamPro-Medium.woff2") format("woff2"), url("GothamPro-Medium.woff") format("woff"), url("GothamPro-Medium.ttf") format("truetype");
  font-weight: 500;
  font-style: normal;
}
@font-face {
  font-family: "Gotham Pro";
  src: url("GothamPro-BoldItalic.eot");
  src: local("Gotham Pro Bold Italic"), local("GothamPro-BoldItalic"), url("GothamPro-BoldItalic.eot?#iefix") format("embedded-opentype"), url("GothamPro-BoldItalic.woff2") format("woff2"), url("GothamPro-BoldItalic.woff") format("woff"), url("GothamPro-BoldItalic.ttf") format("truetype");
  font-weight: bold;
  font-style: italic;
}
@font-face {
  font-family: "Gotham Pro";
  src: url("GothamPro.eot");
  src: local("Gotham Pro"), local("GothamPro"), url("GothamPro.eot?#iefix") format("embedded-opentype"), url("GothamPro.woff2") format("woff2"), url("GothamPro.woff") format("woff"), url("GothamPro.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "Gotham Pro";
  src: url("GothamPro-MediumItalic.eot");
  src: local("Gotham Pro Medium Italic"), local("GothamPro-MediumItalic"), url("GothamPro-MediumItalic.eot?#iefix") format("embedded-opentype"), url("GothamPro-MediumItalic.woff2") format("woff2"), url("GothamPro-MediumItalic.woff") format("woff"), url("GothamPro-MediumItalic.ttf") format("truetype");
  font-weight: 500;
  font-style: italic;
}
@font-face {
  font-family: "Gotham Pro";
  src: url("GothamPro-BlackItalic.eot");
  src: local("Gotham Pro Black Italic"), local("GothamPro-BlackItalic"), url("GothamPro-BlackItalic.eot?#iefix") format("embedded-opentype"), url("GothamPro-BlackItalic.woff2") format("woff2"), url("GothamPro-BlackItalic.woff") format("woff"), url("GothamPro-BlackItalic.ttf") format("truetype");
  font-weight: 900;
  font-style: italic;
}
@font-face {
  font-family: "Gotham Pro Narrow";
  src: url("GothamProNarrow-Medium.eot");
  src: local("Gotham Pro Narrow Medium"), local("GothamProNarrow-Medium"), url("GothamProNarrow-Medium.eot?#iefix") format("embedded-opentype"), url("GothamProNarrow-Medium.woff2") format("woff2"), url("GothamProNarrow-Medium.woff") format("woff"), url("GothamProNarrow-Medium.ttf") format("truetype");
  font-weight: 500;
  font-style: normal;
}
@font-face {
  font-family: "Gotham Pro";
  src: url("GothamPro-LightItalic.eot");
  src: local("Gotham Pro Light Italic"), local("GothamPro-LightItalic"), url("GothamPro-LightItalic.eot?#iefix") format("embedded-opentype"), url("GothamPro-LightItalic.woff2") format("woff2"), url("GothamPro-LightItalic.woff") format("woff"), url("GothamPro-LightItalic.ttf") format("truetype");
  font-weight: 300;
  font-style: italic;
}
html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed,
figure, figcaption, footer, header, hgroup,
menu, nav, output, ruby, section, summary,
time, mark, audio, video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
}

/* HTML5 display-role reset for older browsers */
article, aside, details, figcaption, figure,
footer, header, hgroup, menu, nav, section {
  display: block;
}

body {
  line-height: 1;
}

ol, ul {
  list-style: none;
}

blockquote, q {
  quotes: none;
}

blockquote:before, blockquote:after,
q:before, q:after {
  content: "";
  content: none;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

html, body {
  min-height: 100%;
  scroll-behavior: smooth;
}

@media (max-width: 767px) {
  html {
    overflow-x: hidden;
  }
}

body {
  font-family: "Gotham Pro", sans-serif;
  font-weight: 400;
  font-size: 16px;
  line-height: 1.4;
  background: #F9F7F4;
  padding-top: 60px;
  overflow-x: hidden;
}
@media (min-width: 992px) {
  body {
    padding-top: 82px;
  }
  body.body-profile {
    padding-top: 70px;
  }
}
body main {
  flex-grow: 1;
}

* {
  box-sizing: border-box;
  font-family: "Gotham Pro", sans-serif;
}
*::after, *::before {
  box-sizing: border-box;
}

.container {
  display: flex;
  flex-direction: column;
  min-height: 100%;
  padding-left: 15px;
  padding-right: 15px;
}
@media (min-width: 1230px) {
  .container {
    padding-left: calc(50vw - 600px);
    padding-right: calc(50vw - 600px);
  }
  .container.big {
    padding-left: calc(50vw - 700px);
    padding-right: calc(50vw - 700px);
  }
}

.lock-scroll {
  overflow-y: hidden;
}

.margin-section {
  margin-top: 80px;
  margin-bottom: 80px;
}
@media (min-width: 768px) {
  .margin-section {
    margin-top: 100px;
    margin-bottom: 100px;
  }
}
@media (min-width: 992px) {
  .margin-section {
    margin-top: 150px;
    margin-bottom: 150px;
  }
}

a {
  color: #000000;
  text-decoration: none;
  background: linear-gradient(0deg, #000000, #000000) no-repeat right bottom/0 1px;
  transition: background-size 350ms;
}
@media (hover: hover) {
  a:not(.not-hover):where(:hover, :focus-visible) {
    background-size: 100% 1px;
    background-position-x: left;
  }
}

b {
  font-weight: bold;
}

li::marker {
  content: "";
}

img {
  max-width: 100%;
  width: auto;
  height: auto;
}

.unselect, .slider-block .swiper-navigation, .current-location, .btn {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.grid-3 {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  column-gap: 25px;
}

.grid-2 {
  display: grid;
  grid-template-columns: repeat(2, minmax(150px, 1fr));
  column-gap: 25px;
}

.video-iframe {
  aspect-ratio: 16/9;
  width: 100%;
}

.icon {
  display: flex;
  max-width: 100%;
  height: 100%;
}

h1, h2 {
  font-size: 24px;
}
@media (min-width: 992px) {
  h1, h2 {
    font-size: 40px;
  }
}

h3 {
  font-size: 18px;
}

b {
  font-weight: bold;
}

.bold {
  font-weight: 500;
}

.second-btn {
  display: flex;
  position: relative;
  overflow-x: auto;
  gap: 10px;
  scrollbar-width: none;
}
.second-btn::-webkit-scrollbar {
  display: none;
}
@media (min-width: 768px) {
  .second-btn {
    display: grid;
    gap: 0;
    border-radius: 20px 5px;
    box-shadow: inset 0 0 0 1px #4F2B04;
    background: #ffffff;
    grid-template-columns: repeat(3, 1fr);
    overflow: hidden;
  }
}
.second-btn__item {
  font-size: 14px;
  line-height: 21px;
  padding: 10px;
  outline: none;
  font-weight: 500;
  border: none;
  transition: color 404ms linear, opacity 404ms linear;
  position: relative;
  z-index: 1;
  cursor: pointer;
  text-align: center;
  white-space: nowrap;
  background: #ffffff;
  border-radius: 50vmax;
}
@media (min-width: 768px) {
  .second-btn__item {
    background: transparent;
    border-radius: 0;
  }
}
.second-btn__item:not(.active):hover {
  opacity: 0.5;
}
.second-btn__item.active {
  color: #ffffff;
  background-color: #4F2B04;
}

.group-location {
  display: flex;
  flex-direction: column;
  gap: 5px;
}
.group-location__label {
  font-size: 14px;
}
.group-location__items {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
}

.radio-item {
  display: flex;
  align-items: center;
  position: relative;
  border-radius: 50vmax;
  cursor: pointer;
  font-size: 14px;
  line-height: 1;
  color: #4F2B04;
  padding: 10px;
  box-shadow: inset 0 0 0 1px transparent;
  transition: box-shadow 404ms ease-in-out;
  background-color: #ffffff;
}
.radio-item:focus, .radio-item:hover {
  box-shadow: inset 0 0 0 1px #4F2B04;
}
.radio-item.active {
  color: #ffffff;
  box-shadow: inset 0 0 0 2vmax #4F2B04;
}

.preview-file {
  display: grid;
  align-items: center;
  gap: 8px;
  color: #4F2B04;
  grid-template-columns: auto auto auto;
}
.preview-file__icon {
  width: 24px;
  aspect-ratio: 1;
  flex: none;
}
.preview-file__name {
  overflow: hidden;
  display: flex;
}
.preview-file__name span {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.preview-file__remove {
  background-color: rgba(79, 43, 4, 0.1);
  border-radius: 50%;
  width: 24px;
  aspect-ratio: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  flex: none;
}
.preview-file__remove .icon {
  width: 10px;
}
@media (hover: hover) {
  .preview-file__remove {
    transition: opacity 404ms ease-in-out;
    cursor: pointer;
    opacity: 0.6;
  }
  .preview-file__remove:hover {
    opacity: 1;
  }
}

.file-upload {
  display: flex;
  gap: 14px;
}
.file-upload input {
  display: none;
}
.file-upload [role=list] {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 14px;
}
.file-upload [role=list] li::marker {
  content: none;
}
.file-upload .preview:empty {
  display: none;
}

.file-upload-wrapper {
  position: relative;
  display: grid;
}
.file-upload-wrapper [role=alert] {
  bottom: 6px;
  left: 0;
  top: auto;
}

.load-file {
  display: flex;
  align-items: center;
  gap: 8px;
  color: #4F2B04;
  cursor: pointer;
}
.load-file__icon {
  width: 24px;
  aspect-ratio: 1;
}

.social-item {
  width: 36px;
  height: 36px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.social-item svg path {
  transition: all 404ms linear;
}
.social-item:hover svg path {
  fill: #DCAC78;
}

.blog-item {
  display: flex;
  flex-direction: column;
}
.blog-item.swiper-slide {
  min-width: 280px;
  max-width: 80%;
}
@media (min-width: 576px) {
  .blog-item.swiper-slide {
    max-width: 60%;
  }
}
@media (min-width: 768px) {
  .blog-item.swiper-slide {
    max-width: 385px;
  }
}
@media (min-width: 992px) {
  .blog-item.swiper-slide {
    max-width: none;
    width: 33.3333333333%;
    flex-shrink: 1;
  }
}
@media (hover: hover) {
  .blog-item {
    cursor: pointer;
  }
  .blog-item:hover .blog-item__picture img {
    transform: scale(1.1);
  }
}
.blog-item__picture {
  display: flex;
  flex-direction: column;
  height: 100%;
  max-height: 190px;
  border-radius: 10px;
  overflow: hidden;
}
.blog-item__picture img {
  object-fit: cover;
  height: 100%;
  transition: transform 404ms linear;
}
@media (min-width: 992px) {
  .blog-item__picture {
    max-height: 280px;
  }
}
.blog-item__content {
  margin-top: 15px;
  font-size: 18px;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
}
.blog-item__title {
  font-weight: 500;
  margin-bottom: auto;
}
.blog-item__date {
  margin-top: 10px;
}

.menu-item {
  display: flex;
  flex-direction: column;
  margin-bottom: 30px;
}
.menu-item__picture {
  display: flex;
  border-radius: 10px;
  overflow: hidden;
}
.menu-item__picture img {
  object-fit: cover;
  min-height: 180px;
  max-height: 180px;
  width: 100%;
  transition: transform 404ms linear;
}
@media (max-width: 500px) {
  .menu-item__picture img {
    min-height: 106px;
    max-height: 106px;
    max-width: 160px;
  }
}
@media (hover: hover) {
  .menu-item {
    cursor: pointer;
  }
  .menu-item:hover .menu-item__picture img {
    transform: scale(1.1) rotate(-2deg);
  }
}
.menu-item__name {
  font-size: 14px;
  font-weight: 500;
  margin-top: 8px;
  text-align: center;
}
@media (min-width: 768px) {
  .menu-item__name {
    font-size: 16px;
  }
}

.btn {
  line-height: 50px;
  font-size: 14px;
  font-weight: 500;
  transition: background 404ms linear;
  display: flex;
  justify-content: center;
  padding: 0 5px;
  border: none;
  width: 100%;
  cursor: pointer;
}
.btn__border {
  border-radius: 20px 5px;
  box-shadow: 0 0 0 2px #4F2B04;
  background: #ffffff;
  transition: background, color, opacity 404ms linear;
}
.btn__border.active {
  background: #4F2B04;
  color: #ffffff;
}
.btn__border:hover {
  opacity: 0.8;
}
.btn__brown {
  background: #4F2B04;
  color: #ffffff;
  border-radius: 20px 5px;
}
.btn__brown:hover {
  background: #DCAC78;
}
.btn__brown[disabled] {
  pointer-events: none;
  opacity: 0.3;
}

.current-location {
  background: transparent;
  outline: none;
  border: none;
  font-size: 14px;
  line-height: 1;
  color: #4F2B04;
  padding: 0;
  font-weight: 500;
  text-decoration: underline;
  text-underline-offset: 2px;
  position: relative;
}
.current-location.empty {
  cursor: not-allowed;
  padding-left: 20px;
  color: #4F2B04;
}
.current-location.empty::before {
  content: "";
}
.current-location::before {
  content: none;
  background: url("/images/icons/messages/circle-warning.svg") no-repeat center;
  background-size: contain;
  position: absolute;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
  width: 18px;
  aspect-ratio: 1;
}
.current-location::after {
  content: "";
  position: absolute;
  left: 50%;
  top: 50%;
  opacity: 0;
  visibility: hidden;
  transition: opacity 404ms;
  transform: translate(-50%, -50%) rotate(0);
  transition-property: transform;
  animation-name: rotateSpinner;
  animation-duration: 1.2s;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
  width: 20px;
  height: 20px;
  border: 2px solid #4F2B04;
  border-top: 4px solid transparent;
  border-radius: 50%;
  z-index: 2;
}
.current-location.spinner {
  pointer-events: none;
  color: #888888;
}
.current-location.spinner::after {
  opacity: 1;
  visibility: visible;
}
.current-location:not(.empty)[disabled] {
  cursor: not-allowed;
  color: #888888;
}
@media (hover: hover) {
  .current-location {
    cursor: pointer;
    transition: color 404ms ease-in-out;
  }
  .current-location:not(.current-location[disabled]):hover {
    color: #DCAC78;
  }
}

@keyframes rotateSpinner {
  0% {
    transform: translate(-50%, -50%) rotate(0);
  }
  to {
    transform: translate(-50%, -50%) rotate(360deg);
  }
}
.img-text-row {
  display: flex;
  align-items: flex-start;
}
.img-text-row__picture {
  width: 60px;
  display: flex;
  align-items: center;
  flex: none;
}
.img-text-row__title {
  margin-bottom: 3px;
}
.img-text-row__description {
  font-size: 13px;
  line-height: 19px;
}

.img-text-col {
  display: flex;
  flex-direction: column;
  position: relative;
}
.img-text-col::after {
  content: "";
  position: absolute;
  left: -10px;
  top: 10px;
  background: url("/images/bg/img-text-col.svg") no-repeat top left;
  background-size: contain;
  height: 70%;
  max-height: 110px;
  width: 100%;
  z-index: -1;
}
.img-text-col__picture {
  width: 80px;
  display: flex;
  align-items: center;
  flex: none;
  margin-bottom: 10px;
}
.img-text-col__title {
  margin-bottom: 5px;
  font-size: 24px;
}
.img-text-col__description {
  font-size: 14px;
  line-height: 21px;
}

.youtube {
  display: flex;
  width: 100%;
  border-radius: 20px;
  overflow: hidden;
}

.switcher {
  display: none;
  flex-direction: column;
  justify-content: center;
  position: relative;
  margin-left: 25px;
  font-size: 16px;
  font-weight: 500;
  text-transform: uppercase;
  cursor: pointer;
  min-width: 30px;
  text-align: center;
}
@media (min-width: 992px) {
  .switcher {
    display: flex;
  }
}
.switcher__current {
  padding: 6px 0 4px;
  line-height: 1;
}
.switcher::after {
  content: "";
  position: absolute;
  top: 50%;
  left: -25px;
  transform: translateY(-50%);
  background: url("/images/icons/lang.svg") no-repeat center;
  width: 20px;
  height: 20px;
}
.switcher .drop {
  position: absolute;
  top: 100%;
  width: 100%;
  opacity: 0;
  pointer-events: none;
  visibility: hidden;
  transition: opacity 404ms linear;
}
.switcher .drop li {
  transition: background 404ms linear;
}
.switcher .drop li:hover,
.switcher .drop .active {
  background: #F8F6F3;
  border-radius: 5px;
}
.switcher.show {
  box-shadow: 0 0 4px rgba(0, 0, 0, 0.1);
  border-radius: 5px 5px 0 0;
}
.switcher.show .drop {
  opacity: 1;
  pointer-events: auto;
  visibility: visible;
  box-shadow: 0 3px 4px 0 rgba(0, 0, 0, 0.1);
  border-radius: 0 0 5px 5px;
  background: white;
}

.user-block,
.cart {
  margin: 0 15px;
  display: flex;
  align-items: center;
  cursor: pointer;
  padding: 3px 7px;
  transition: background 404ms linear;
  border-radius: 5px;
}
@media (min-width: 992px) {
  .user-block,
.cart {
    margin: 0 20px 0 0;
  }
}
.user-block__icon,
.cart__icon {
  display: flex;
  align-items: center;
  margin-right: 8px;
  border-radius: 5px;
}
.user-block__label,
.cart__label {
  font-weight: 500;
}
.user-block:hover,
.cart:hover {
  background: #F8F6F3;
}

.cart__icon {
  position: relative;
}
.cart__icon::after {
  content: attr(data-count);
  position: absolute;
  top: 0;
  right: 0;
  background: #4F2B04;
  font-size: 8px;
  color: #ffffff;
  border-radius: 2px;
  padding: 0 2px;
  transform: translateX(50%);
}

.burger {
  width: 22px;
  height: 19px;
  cursor: pointer;
  z-index: 30;
  transition: transform 1s ease-in;
  position: relative;
  display: flex;
  justify-content: flex-end;
}
@media (min-width: 992px) {
  .burger {
    display: none;
  }
}
.burger span {
  display: block;
  position: absolute;
  height: 2px;
  right: 0;
  background: #000000;
  border-radius: 2px;
  opacity: 1;
  transform: rotate(0deg);
  transition: 0.25s ease-in-out;
}
.burger span:nth-child(1) {
  top: 0;
  width: 100%;
}
.burger span:nth-child(2) {
  top: 9px;
  width: 75%;
}
.burger span:nth-child(3) {
  top: 17px;
  width: 50%;
}
.burger.active span:nth-child(1) {
  top: 8px;
  transform: rotate(-135deg);
}
.burger.active span:nth-child(2) {
  opacity: 0;
}
.burger.active span:nth-child(3) {
  top: 8px;
  width: 100%;
  transform: rotate(135deg);
}

.mobile-lang {
  display: flex;
  justify-content: center;
  align-items: center;
}
.mobile-lang__item {
  font-size: 14px;
  font-weight: 500;
  padding: 0 25px;
  line-height: 33px;
  text-transform: uppercase;
  opacity: 0.5;
}
.mobile-lang__item.active {
  opacity: 1;
}
.mobile-lang__item + .mobile-lang__item {
  position: relative;
}
.mobile-lang__item + .mobile-lang__item::after {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  width: 1px;
  transform: translateX(-50%);
  background: #F1F1F1;
}

.cart-item {
  border-radius: 10px;
  background: #ffffff;
  display: flex;
  position: relative;
}
.cart-item__picture {
  width: 160px;
  display: flex;
  flex-direction: column;
  flex: none;
  border-radius: 10px 0 0 10px;
  overflow: hidden;
}
.cart-item__picture img {
  height: 100%;
  object-fit: cover;
}
@media (min-width: 768px) {
  .cart-item__picture {
    width: 180px;
  }
}
.cart-item__content {
  padding: 8px 10px;
  width: 100%;
  display: flex;
  flex-direction: column;
}
.cart-item__title {
  font-weight: 500;
  font-size: 14px;
  line-height: 18px;
}
.cart-item__gram {
  font-size: 12px;
  margin: 8px 0;
}
.cart-item__actions {
  margin-top: auto;
  display: flex;
  align-items: center;
}
.cart-item__actions .counter {
  margin-right: auto;
}
.cart-item__price {
  font-size: 14px;
  font-weight: 500;
}
.cart-item__price span {
  margin-right: 4px;
}
.cart-item__close {
  width: 16px;
  height: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  overflow: hidden;
  position: absolute;
  right: 0;
  top: 0;
  background: #DDDDDD url("/images/icons/close-item-cart.svg") no-repeat center;
  transform: translateY(-5px);
  cursor: pointer;
  transition: opacity 404ms linear;
  opacity: 1;
}
.cart-item__close:hover {
  opacity: 0.7;
}

.counter {
  display: flex;
  background: #EFECE8;
  border-radius: 25px;
  overflow: hidden;
  height: 30px;
}
.counter input::-webkit-outer-spin-button,
.counter input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
.counter input[type=number] {
  -moz-appearance: textfield;
  border: none;
  background: transparent;
  outline: none;
  text-align: center;
  width: 40px;
}
.counter__item {
  width: 30px;
  height: 100%;
  cursor: pointer;
  transition: opacity, background 404ms linear;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 500;
}
.counter__item:hover {
  background: #CACACA;
  opacity: 0.6;
}

.swiper-navigation {
  display: flex;
  align-items: center;
  font-size: 20px;
  color: #ffffff;
}
.swiper-navigation .swiper-pagination-current {
  font-size: 40px;
}

.search-shop-block {
  padding: 15px;
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.08);
  position: relative;
  z-index: 5;
  width: 100%;
}
@media (min-width: 768px) {
  .search-shop-block {
    border-radius: 30px;
    padding: 30px 25px;
    max-width: 500px;
  }
}
.search-shop-block::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(255, 255, 255, 0.6);
  -webkit-backdrop-filter: blur(20px);
  backdrop-filter: blur(20px);
  z-index: -1;
  border-radius: inherit;
}
.search-shop-block__title {
  margin-bottom: 10px;
}
.search-shop-block__description {
  font-size: 14px;
  margin-bottom: 5px;
}

.shop-item {
  display: flex;
  flex-direction: column;
  background: white;
  border-radius: 10px;
}
@media (hover: hover) {
  .shop-item {
    cursor: pointer;
  }
  .shop-item:hover .shop-item__picture img {
    transform: scale(1.1) rotate(-2deg);
  }
}
.shop-item__picture {
  height: 210px;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 10px 10px 0 0;
}
@media (min-width: 768px) {
  .shop-item__picture {
    height: 180px;
  }
}
.shop-item__picture img {
  object-fit: cover;
  height: 100%;
  width: 100%;
  transition: transform 404ms linear;
}
.shop-item__description {
  padding: 10px;
  font-size: 14px;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
}
.shop-item__name {
  font-weight: 500;
  margin-bottom: auto;
}
.shop-item__info {
  margin-top: 8px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.shop-item__price {
  font-size: 18px;
  font-weight: 500;
}

.info-place {
  display: flex;
  font-size: 14px;
  line-height: 1.2;
}
.info-place__left {
  padding-right: 20px;
}
.info-place__left a {
  font-weight: 500;
}
.info-place__logo {
  width: 62px;
  height: 50px;
  display: flex;
  flex-direction: column;
  margin-bottom: 10px;
}
.info-place__phones .item + .item {
  margin-top: 3px;
}
.info-place__city {
  font-weight: 500;
  margin-bottom: 5px;
}
.info-place__street {
  margin-bottom: 5px;
}
.info-place__schedule-name {
  margin-top: 15px;
  font-weight: 700;
  font-size: 12px;
  color: #888888;
}
.info-place__schedule-items {
  display: flex;
  flex-direction: column;
}
.info-place__schedule-items .item {
  display: flex;
  align-items: center;
  line-height: 1.1;
  margin-top: 6px;
}
.info-place__schedule-items .day {
  font-weight: 500;
  width: 55px;
  padding-right: 10px;
}

.breadcrumbs {
  display: flex;
  align-items: center;
  font-size: 14px;
  line-height: 20px;
  margin: 20px -10px;
}
@media (max-width: 992px) {
  .breadcrumbs {
    display: none;
  }
}
.breadcrumbs__item {
  cursor: pointer;
  margin: 0 10px;
  position: relative;
}
.breadcrumbs__item::after {
  content: "";
  position: absolute;
  right: -10px;
  top: 50%;
  transform: translate(50%, -50%);
  width: 4px;
  height: 4px;
  background: #AEACA8;
  border-radius: 50%;
}
.breadcrumbs__item:last-child::after {
  content: none;
}
.breadcrumbs__item a {
  color: #AEACA8;
  transition: color 404ms linear;
}
.breadcrumbs__item a:hover {
  color: #000000;
}

.static-block {
  display: grid;
  gap: 30px;
  font-size: 14px;
  line-height: 1.5;
}
.static-block .other-item {
  display: flex;
}
.static-block .other-item--full {
  width: 100vw;
  margin-inline-start: 50%;
  transform: translateX(-50%);
}
@media (min-width: 768px) {
  .static-block--other-text {
    grid-template-columns: 1fr 1fr;
  }
  .static-block--other-text .other-item {
    order: 0;
  }
  .static-block--other-text .content {
    order: 1;
  }
}
@media (min-width: 768px) {
  .static-block--text-other {
    grid-template-columns: 1fr 1fr;
  }
  .static-block--text-other .other-item {
    order: 1;
  }
  .static-block--text-other .content {
    order: 0;
  }
}
.static-block--full-width {
  width: 100%;
  display: flex;
  flex-direction: column;
}
.static-block img,
.static-block iframe {
  width: 100%;
  height: auto;
  object-fit: cover;
}
.static-block img {
  border-radius: 30px;
}
.static-block iframe {
  aspect-ratio: 16/9;
}

.google-search-location {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 6px;
  padding-bottom: 10px;
}
@media (min-width: 768px) {
  .google-search-location {
    padding-bottom: 20px;
    margin-bottom: 20px;
    position: relative;
  }
  .google-search-location:after {
    content: "";
    width: calc(100% + 30px);
    left: -15px;
    height: 1px;
    bottom: 0;
    position: absolute;
    background: #E4E4E4;
  }
}
.google-search-location label {
  font-size: 14px;
  display: none;
}
@media (min-width: 768px) {
  .google-search-location label {
    display: inline-flex;
  }
}

.address-item {
  display: grid;
  gap: 15px;
  grid-template-columns: 60px 1fr;
  padding-block: 20px;
  padding-inline: 10px;
  position: relative;
}
.address-item::after, .address-item::before {
  content: "";
  position: absolute;
  width: 100%;
  height: 1px;
  background-color: #e0d2c3;
  left: 0;
}
.address-item::after {
  top: 0;
  transform: translateY(-50%);
}
.address-item::before {
  bottom: 0;
  transform: translateY(50%);
}
@media (hover: hover) {
  .address-item {
    cursor: pointer;
    transition: opacity 404ms ease-in-out;
  }
  .address-item:hover {
    opacity: 0.7;
  }
}
.address-item__main {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 8px;
  line-height: 1;
}
.address-item__main h5 {
  color: #4F2B04;
  font-weight: 500;
}
.address-item__main p, .address-item__main a {
  font-size: 12px;
  color: rgba(0, 0, 0, 0.6);
}

input[type=email],
input[type=phone],
input[type=number],
input[type=password],
input[type=search],
textarea,
input[type=text] {
  background: #ffffff;
  border-radius: 5px;
  padding: 17px 12px;
  min-height: 50px;
  font-size: 14px;
  border: 1px solid rgba(79, 43, 4, 0.2);
  width: 100%;
  outline: none;
  transition: border 404ms linear;
}
input[type=email]:focus,
input[type=phone]:focus,
input[type=number]:focus,
input[type=password]:focus,
input[type=search]:focus,
textarea:focus,
input[type=text]:focus {
  border: 1px solid rgba(79, 43, 4, 0.4);
}
input[type=email].is-invalid,
input[type=phone].is-invalid,
input[type=number].is-invalid,
input[type=password].is-invalid,
input[type=search].is-invalid,
textarea.is-invalid,
input[type=text].is-invalid {
  border: 1px solid #E3000B;
  background: #FEF7F7;
}
input[type=email] + label,
input[type=phone] + label,
input[type=number] + label,
input[type=password] + label,
input[type=search] + label,
textarea + label,
input[type=text] + label {
  font-size: 14px;
  color: rgba(0, 0, 0, 0.8);
  position: absolute;
  left: 12px;
  bottom: calc(100% - 25px);
  transform: translateY(50%);
  transition: 303ms linear;
  z-index: 1;
  cursor: text;
}
input[type=email]:required + label::after,
input[type=phone]:required + label::after,
input[type=number]:required + label::after,
input[type=password]:required + label::after,
input[type=search]:required + label::after,
textarea:required + label::after,
input[type=text]:required + label::after {
  content: "*";
  position: absolute;
  top: 0;
  right: -6px;
  color: #E3000B;
}
input[type=email].has-value + label, input[type=email]:focus + label,
input[type=phone].has-value + label,
input[type=phone]:focus + label,
input[type=number].has-value + label,
input[type=number]:focus + label,
input[type=password].has-value + label,
input[type=password]:focus + label,
input[type=search].has-value + label,
input[type=search]:focus + label,
textarea.has-value + label,
textarea:focus + label,
input[type=text].has-value + label,
input[type=text]:focus + label {
  font-size: 12px;
  bottom: 100%;
  left: 5px;
  transform: translateY(50%);
  color: #000000;
  transition: 303ms linear;
  background: white;
  width: auto;
  border-radius: 5px;
  padding: 2px 4px;
}

textarea {
  resize: none;
  height: 120px;
}

input[type=search] {
  background: #ffffff url("/images/icons/search-icon.svg") no-repeat calc(100% - 15px);
}

input[type=number]::-webkit-outer-spin-button, input[type=number]::-webkit-inner-spin-button {
  -webkit-appearance: none;
  appearance: none;
  margin: 0;
}

.form-check {
  display: flex;
  align-items: center;
}

.toggle {
  --size: 25px;
  --width: calc(22px * 2);
  display: inline-grid;
  align-items: center;
  width: var(--width);
  height: var(--size);
  cursor: pointer;
  appearance: none;
}
.toggle::before {
  content: "";
  grid-area: 1/-1;
  width: var(--width);
  height: var(--size);
  transition: all 250ms ease;
  border-radius: 25px;
  background-color: rgba(79, 43, 4, 0.3);
}
.toggle:hover::before {
  box-shadow: 0 0 0 2px rgba(220, 172, 120, 0.3);
}
.toggle::after {
  content: "";
  grid-area: 1/-1;
  width: calc(var(--size) * 0.8);
  height: calc(var(--size) * 0.8);
  transform: translateX(10%);
  transform-origin: center center;
  transition: all 250ms ease;
  border-radius: 100%;
  background: #fff;
}
.toggle:checked::before {
  background-color: #4F2B04;
}
.toggle:checked::after {
  transform: translateX(calc(var(--width) - var(--size) * 0.8 - 10%));
}
.toggle:disabled {
  pointer-events: none;
  filter: grayscale(1);
}
.toggle:disabled::before {
  background: #2be;
}
.toggle + label {
  padding-left: 10px;
  cursor: pointer;
  font-size: 14px;
}
.toggle + .p-left {
  padding-left: 15px;
}

.form-group {
  display: flex;
  flex-direction: column;
  margin-bottom: 25px;
  position: relative;
}
.form-group label {
  cursor: pointer;
}
.form-group.hidden {
  opacity: 0;
  visibility: hidden;
}

.form__text {
  margin: 30px 0 20px 0;
}

.form__accordion__content {
  height: 0;
  overflow: hidden;
  transition: 0.5s;
}

.check-telegram {
  display: flex;
  align-items: center;
  gap: 15px;
}
.check-telegram .btn {
  width: auto;
  min-width: 150px;
  margin-left: auto;
  opacity: 0;
  visibility: hidden;
  pointer-events: none;
  transition: opacity 404ms linear;
  line-height: 30px;
}
.check-telegram .btn.show {
  opacity: 1;
  visibility: visible;
  pointer-events: auto;
}

[role*=alert] {
  position: absolute;
  top: 100%;
  font-size: 11px;
  left: 7px;
  color: #E3000B;
}

.custom-select {
  position: relative;
  font-size: 14px;
  min-height: 50px;
  width: 100%;
}
.custom-select select {
  display: none;
}
.custom-select.is-invalid .select-selected {
  border: 1px solid #E3000B;
  background: #FEF7F7;
}
.custom-select .select-selected {
  background: #ffffff;
  border-radius: 5px;
  padding: 14px 12px;
  font-size: 14px;
  border: 1px solid rgba(79, 43, 4, 0.2);
  width: 100%;
  position: relative;
  cursor: pointer;
  transition: all 404ms linear;
}
.custom-select .select-selected::before {
  content: "";
  position: absolute;
  bottom: 0;
  width: 0;
  left: 50%;
  transform: translateX(-50%);
  height: 1px;
  background: rgba(79, 43, 4, 0.2);
}
.custom-select .select-selected.select-arrow-active {
  border: 1px solid rgba(79, 43, 4, 0.4);
  border-bottom: 1px solid transparent;
  border-radius: 5px 5px 0 0;
  background: #ffffff;
}
.custom-select .select-selected.select-arrow-active::before {
  width: calc(100% - 12px);
  transition: width 404ms linear;
}
.custom-select:not(.search-element) .select-selected::after {
  content: "";
  position: absolute;
  top: 50%;
  right: 15px;
  background: url("/images/icons/select-arrow.svg") no-repeat center;
  background-size: contain;
  width: 12px;
  height: 10px;
  transform: translateY(-50%) scale(-1);
  opacity: 0.7;
  transition: all 404ms linear;
}
.custom-select:not(.search-element) .select-selected.select-arrow-active::after {
  transform: translateY(-50%) scale(1);
  opacity: 1;
}
.custom-select .select-items {
  max-height: 190px;
  padding-top: 10px;
  padding-bottom: 10px;
  overflow: auto;
  position: absolute;
  background-color: #ffffff;
  top: calc(100% - 1px);
  left: 0;
  right: 0;
  z-index: 99;
  border: 1px solid rgba(79, 43, 4, 0.5);
  border-top: none;
  border-radius: 0 0 5px 5px;
  transition: all 404ms linear;
  opacity: 1;
  visibility: visible;
  pointer-events: all;
  transform: translateY(0);
}
.custom-select .select-items .select-list div {
  line-height: 35px;
  padding: 0 12px;
  cursor: pointer;
  transition: background 404ms linear;
}
.custom-select .select-items .select-list div.same-as-selected, .custom-select .select-items .select-list div:hover {
  background: #F8F6F3;
}
.custom-select .select-items.select-hide {
  opacity: 0;
  visibility: hidden;
  pointer-events: none;
  transform: translateY(20px);
}
.custom-select .btn-search {
  position: absolute;
  width: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  right: 0;
  top: 0;
  transition: box-shadow 404ms linear;
  z-index: 1;
  cursor: pointer;
}
.custom-select .btn-search.disabled {
  pointer-events: none;
  opacity: 0.4;
}
.custom-select .btn-search:hover {
  box-shadow: inset 0 0 0 1px rgba(0, 0, 0, 0.1);
}
.custom-select .btn-search::after {
  content: "";
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: url("/images/icons/search-icon.svg") no-repeat center;
  width: 18px;
  height: 18px;
}
.custom-select .select-search {
  padding: 0 12px 10px;
}
.custom-select .select-search input {
  padding-top: 8px;
  padding-bottom: 8px;
  height: 30px;
  min-height: auto;
}
.custom-select.is-arrow .btn-search {
  display: none;
}
.custom-select.is-arrow .select-selected::after {
  content: "";
  position: absolute;
  top: 50%;
  right: 15px;
  background: url("/images/icons/select-arrow.svg") no-repeat center;
  background-size: contain;
  width: 12px;
  height: 10px;
  transform: translateY(-50%) scale(-1);
  opacity: 0.7;
  transition: all 404ms linear;
}
.custom-select.is-arrow .select-selected.select-arrow-active::after {
  transform: translateY(-50%) scale(1);
  opacity: 1;
}

.form-body {
  padding: 30px 15px;
  background: #ffffff;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
}
@media (min-width: 768px) {
  .form-body {
    padding: 30px 20px;
  }
}
.form-body.red-status {
  border: 1px solid #EE5454;
  background: rgba(238, 84, 84, 0.07);
}
.form-body .item-text {
  margin-bottom: 20px;
}
.form-body .item-text.not-margin {
  margin-bottom: 0;
}
.form-body .empty-text {
  font-size: 16px;
  font-weight: 500;
  line-height: 1.2;
  color: #AEACA8;
  display: flex;
  justify-content: center;
  text-align: center;
}
@media (min-width: 768px) {
  .form-body .empty-text {
    font-size: 18px;
  }
}
.form-body .label {
  font-size: 14px;
  font-weight: 500;
  line-height: 1;
  margin-bottom: 5px;
  color: #AEACA8;
}
.form-body .text {
  font-size: 14px;
  line-height: 1.2;
}
.form-body .paragraph {
  font-size: 14px;
  line-height: 21px;
}
.form-body .paragraph + .paragraph {
  margin-top: 15px;
}
.form-body .paragraph.m-top {
  display: flex;
  margin-top: 15px;
}
.form-body .photo {
  display: flex;
  align-items: center;
  position: relative;
  min-height: 20px;
}
.form-body .photo::after {
  content: "";
  position: absolute;
  left: 0;
  top: 43%;
  transform: translateY(-50%);
  width: 18px;
  height: 15px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: url("/images/icons/photo-icon.svg") no-repeat center;
}
.form-body .photo__name {
  font-size: 14px;
  padding-left: 25px;
}
.form-body .phones {
  display: flex;
  flex-direction: column;
}
.form-body .phones__item {
  font-size: 14px;
}
.form-body .m-bot {
  margin-bottom: 15px;
}
.form-body .work-schedule__item + .work-schedule__item {
  margin-top: 0;
}

.form-block + .form-block {
  margin-top: 25px;
}
.form-block.max-container {
  width: 100%;
  max-width: 600px;
  margin: 0 auto;
}

.form-action {
  display: flex;
  align-items: center;
  margin: 0 -9px;
}
@media (min-width: 768px) {
  .form-action {
    margin: 0 -15px;
  }
}
.form-action .btn {
  flex-grow: 1;
  max-width: 200px;
}
.form-action .action {
  margin: 0 9px;
  font-weight: 500;
}
@media (min-width: 768px) {
  .form-action .action {
    margin: 0 15px;
  }
}

.social-link .footer__title {
  display: flex;
  justify-content: center;
}
.social-link__items {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 25px;
}

.statistics {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: -20px;
  position: relative;
  z-index: 1;
}
@media (min-width: 768px) {
  .statistics {
    margin-top: -80px;
    flex-direction: row;
    justify-content: flex-end;
  }
}
.statistics__title {
  font-size: 20px;
  padding: 30px 14px;
  border-radius: 20px;
  overflow: hidden;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.08);
  display: inline-block;
  max-width: 350px;
  margin: 0 25px;
  position: relative;
}
.statistics__title::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(255, 255, 255, 0.8);
  backdrop-filter: blur(20px);
  z-index: -1;
}
@media (min-width: 768px) {
  .statistics__title {
    margin: 0;
    transform: translateX(30px);
    font-size: 24px;
  }
}
@media (min-width: 992px) {
  .statistics__title {
    padding: 50px 20px;
    max-width: 370px;
  }
}
.statistics__title b {
  font-weight: bold;
}
.statistics__items {
  padding: 40px 20px 25px;
  border-radius: 20px;
  overflow: hidden;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.08);
  margin-top: -35px;
  position: relative;
  z-index: -2;
}
.statistics__items::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(255, 255, 255, 0.8);
  backdrop-filter: blur(20px);
  z-index: -1;
}
@media (min-width: 768px) {
  .statistics__items {
    margin-top: 0;
    padding: 25px 50px;
  }
}
@media (min-width: 992px) {
  .statistics__items {
    display: flex;
    align-items: flex-start;
  }
}
.statistics__item {
  display: flex;
  align-items: center;
}
@media (min-width: 992px) {
  .statistics__item {
    flex-direction: column;
    margin: 0 15px;
  }
}
.statistics__number {
  font-size: 30px;
  font-weight: 300;
  min-width: 120px;
  display: flex;
}
@media (min-width: 992px) {
  .statistics__number {
    justify-content: center;
  }
}
.statistics__label {
  text-align: center;
  flex-grow: 1;
}
@media (min-width: 992px) {
  .statistics__label {
    max-width: 150px;
  }
}

.user-menu__icon {
  display: flex;
  flex-direction: column;
  width: 20px;
  height: auto;
  margin-right: 10px;
}
.user-menu__item {
  font-size: 14px;
  font-weight: 500;
  display: flex;
  align-items: center;
  line-height: 30px;
}
.user-menu__top {
  padding: 20px 0;
  background: #F9F7F4;
  position: relative;
  display: flex;
}
@media (min-width: 576px) {
  .user-menu__top {
    display: none;
  }
}
.user-menu__top::before, .user-menu__top::after {
  content: "";
  position: absolute;
  top: 0;
  height: 100%;
  width: 100vw;
  background: #F9F7F4;
  z-index: -1;
}
.user-menu__top::before {
  right: calc(100% - 2px);
}
.user-menu__top::after {
  left: calc(100% - 2px);
}
.user-menu__items .user-menu__item {
  margin-top: 20px;
}

.partner-menu {
  margin: 0 -30px;
}
.partner-menu__item.open-menu {
  background: #F7F3F0;
}
.partner-menu__item.open-menu .partner-menu__sub-link {
  height: auto;
}
.partner-menu__item.open-menu .expanded::after {
  transform: translateY(-50%) rotate(90deg);
}
.partner-menu__link {
  display: flex;
  padding: 15px 30px;
  position: relative;
  transition: background 404ms linear;
}
.partner-menu__link:hover {
  background: #EDE6E0;
}
.partner-menu__link.active {
  background: #EDE6E0;
}
.partner-menu__link.active::before {
  content: "";
  position: absolute;
  top: 50%;
  left: 0;
  width: 5px;
  transform: translateY(-50%);
  background: #4F2B04;
  height: calc(100% - 10px);
  border-radius: 0 4px 4px 0;
}
.partner-menu__link.expanded::after {
  content: "";
  position: absolute;
  right: 35px;
  top: 50%;
  transform: translateY(-50%);
  background: url("/images/icons/small-arrow.svg") no-repeat center;
  transition: transform 404ms linear;
  width: 10px;
  height: 10px;
}
.partner-menu__link.expanded.active {
  border-bottom: 1px solid #EDE6E0;
}
.partner-menu__link {
  display: flex;
  align-items: center;
  color: #4F2B04;
  font-size: 16px;
  font-weight: 500;
}
.partner-menu__link .icon {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 10px;
  width: 20px;
  height: 20px;
}
.partner-menu__link .icon--left {
  margin-right: 0;
  margin-left: 15px;
}
.partner-menu__sub-link {
  display: flex;
  flex-direction: column;
  height: 0;
  overflow: hidden;
  transition: height 404ms linear;
}
.partner-menu__sub-link a {
  padding: 10px 60px;
  transition: background 404ms linear;
}
.partner-menu__sub-link a.active {
  font-weight: 700;
}
.partner-menu__sub-link a:hover {
  background: #EDE6E0;
}

.pagination {
  display: flex;
  align-items: center;
  justify-content: center;
}
.pagination button:not([rel]),
.pagination a:not([rel]),
.pagination .active span {
  width: 30px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: none;
  border-radius: 5px;
  margin: 0 2px;
  cursor: pointer;
}
@media (max-width: 1440px) {
  .pagination button:not([rel]),
.pagination a:not([rel]),
.pagination .active span {
    width: calc(25px + 5 * ((100vw - 375px) / 1065));
  }
}
@media (max-width: 1440px) {
  .pagination button:not([rel]),
.pagination a:not([rel]),
.pagination .active span {
    height: calc(25px + 5 * ((100vw - 375px) / 1065));
  }
}
.pagination button:not([rel]),
.pagination a:not([rel]) {
  transition: background 404ms linear;
}
.pagination button:not([rel]):hover,
.pagination a:not([rel]):hover {
  background: #EFECE8;
}
.pagination button:not([rel])::before, .pagination button:not([rel])::after,
.pagination a:not([rel])::before,
.pagination a:not([rel])::after {
  content: none;
}
.pagination .active span {
  background: #DCAC78;
  font-weight: 500;
}
.pagination [aria-label] {
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 5px;
  width: 40px;
  height: 30px;
  margin: 0 4px;
  font-size: 0;
  border: none;
  background: #4F2B04;
  background-image: url("/images/icons/arrow-pagination-small.svg");
  background-repeat: no-repeat;
  background-position: center;
  transition: opacity 404ms linear;
  cursor: pointer;
}
.pagination [aria-label]:hover {
  opacity: 0.8;
}
.pagination [aria-label]::before, .pagination [aria-label]::after {
  content: none;
}
@media (min-width: 768px) {
  .pagination [aria-label] {
    width: 60px;
    background-image: url("/images/icons/arrow-pagination.svg");
  }
}
.pagination [aria-label*=«] {
  transform: scaleX(-1);
}
.pagination > .disabled {
  mix-blend-mode: difference;
  background-color: #545454;
  opacity: 0.4;
  pointer-events: none;
}
.pagination .m-top {
  margin-top: 20px;
}

.work-schedule {
  display: flex;
  flex-direction: column;
  margin-bottom: 5px;
}
.work-schedule__title {
  font-size: 16px;
  font-weight: bold;
  color: #888888;
  margin-bottom: 10px;
}
@media (min-width: 768px) {
  .work-schedule__title {
    font-size: 18px;
  }
}
.work-schedule__items {
  display: flex;
  flex-direction: column;
}
.work-schedule__item + .work-schedule__item {
  margin-top: 10px;
}
.work-schedule__item {
  display: flex;
  align-items: center;
  font-size: 14px;
}
.work-schedule__item .day {
  font-weight: bold;
  width: 50px;
  margin-right: 15px;
}
.work-schedule__item .hours {
  font-size: 14px;
}

.contacts {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}
.contacts__title {
  font-size: 16px;
  font-weight: bold;
  color: #888888;
  margin-bottom: 10px;
}
@media (min-width: 768px) {
  .contacts__title {
    font-size: 18px;
  }
}
.contacts__item {
  font-size: 24px;
}
@media (min-width: 768px) {
  .contacts__item {
    font-size: 26px;
  }
}
.contacts__item span {
  font-weight: 700;
}

.social-media {
  display: flex;
  flex-direction: column;
}
.social-media__title {
  font-size: 16px;
  font-weight: bold;
  color: #888888;
  margin-bottom: 10px;
}
@media (min-width: 768px) {
  .social-media__title {
    font-size: 18px;
  }
}
.social-media__items {
  display: flex;
  margin: 0 -10px;
}
.social-media__items a {
  margin: 0 10px;
}

.slider-block .swiper-navigation {
  bottom: 0;
  position: absolute;
  left: calc(50vw - 600px);
  z-index: 10;
}
.slider-block .swiper-navigation__pagination {
  margin: 0 5px;
}
.slider-block .swiper-navigation__next, .slider-block .swiper-navigation__prev {
  padding: 10px;
  cursor: pointer;
}
.slider-block .swiper-image {
  position: relative;
  border-radius: 20px;
  overflow: hidden;
}
@media (min-width: 1230px) {
  .slider-block .swiper-image {
    border-radius: 0;
    width: 100vw;
    left: calc(600px - 50vw);
  }
}
.slider-block .swiper-image .swiper-slide {
  display: flex;
  justify-content: center;
}
.slider-block .swiper-image img {
  height: 100%;
  min-height: 200px;
  object-fit: cover;
}
@media (min-width: 768px) {
  .slider-block .swiper-image img {
    height: 350px;
    width: 100%;
  }
}
@media (min-width: 992px) {
  .slider-block .swiper-image img {
    height: 550px;
  }
}
@media (min-width: 1230px) {
  .slider-block .swiper-image img {
    width: 100%;
    height: 550px;
    object-fit: cover;
  }
}

.menu {
  margin: 60px 0;
}
@media (min-width: 768px) {
  .menu {
    margin: 70px 0 100px;
  }
}
.menu__description {
  font-size: 14px;
  margin-bottom: 15px;
}
.menu__items {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin: 10px -10px;
}
.menu__items .shop-item {
  width: calc(100% - 20px);
  margin: 10px;
}
@media (min-width: 768px) {
  .menu__items .shop-item {
    width: calc(50% - 20px);
  }
}
@media (min-width: 992px) {
  .menu__items .shop-item {
    width: calc(33.3333333333% - 20px);
  }
}
@media (min-width: 1230px) {
  .menu__items .shop-item {
    width: calc(25% - 20px);
  }
}

.product {
  font-size: 14px;
}
.product__title {
  line-height: 1.2;
  font-weight: 500;
  margin-bottom: 10px;
}
.product__weight, .product__count {
  display: flex;
  align-items: center;
  margin: 15px 0;
}
.product__weight .product__sub-title, .product__count .product__sub-title {
  min-width: 90px;
  padding-right: 10px;
  margin-bottom: 0;
}
.product__price {
  font-size: 30px;
  font-weight: 500;
  line-height: 1;
  padding: 15px 0;
}
.product__btns {
  display: flex;
  align-items: center;
  margin: 20px -9px 0;
}
@media (min-width: 768px) {
  .product__btns {
    margin: 20px -14px 0;
  }
}
.product__btns .btn {
  flex-grow: 1;
  max-width: 200px;
}
.product__btns .action {
  margin: 0 9px;
  font-weight: 500;
}
@media (min-width: 768px) {
  .product__btns .action {
    margin: 0 14px;
  }
}
.product__info {
  font-size: 14px;
  margin-bottom: 20px;
}
.product__action {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}
.product__action .btn {
  width: auto;
  padding-inline: 15px;
}
.product__item {
  margin-top: 30px;
  display: flex;
  flex-direction: column;
}
.product__sub-title {
  font-size: 14px;
  font-weight: 500;
  line-height: 1;
  color: #888888;
  margin-bottom: 10px;
}
.product__video {
  width: 100%;
  height: 0;
  position: relative;
  padding-top: 56.25%;
  border-radius: 20px;
  overflow: hidden;
}
.product__video iframe {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  border: 0;
}

.recipes-step + .recipes-step {
  margin-top: 10px;
}
.recipes-step__name {
  font-weight: 500;
  margin-bottom: 5px;
}

.profile-header {
  position: relative;
  border-radius: 0 0 15px 15px;
  overflow: hidden;
  min-height: 90px;
  display: flex;
  align-items: center;
  justify-content: center;
}
@media (min-width: 992px) {
  .profile-header {
    font-size: 26px;
    min-height: 120px;
  }
}
.profile-header__image {
  position: absolute;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 100%;
  z-index: -1;
}
.profile-header__image img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.profile-header__title {
  font-size: 24px;
  font-weight: 500;
  color: #ffffff;
  padding: 15px;
}
@media (min-width: 768px) {
  .profile-header__title {
    font-size: 26px;
  }
}

.modal {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 15;
  opacity: 0;
  visibility: hidden;
  pointer-events: none;
}
.modal.opened {
  transition: opacity 404ms linear;
  opacity: 1;
  visibility: visible;
  pointer-events: auto;
}
.modal__overlay {
  background: rgba(0, 0, 0, 0.7);
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  cursor: pointer;
}
.modal__wrapper {
  padding: 30px 20px;
  border-radius: 20px;
  background: #F9F7F5;
  position: relative;
  z-index: 1;
  width: 100%;
  max-width: 700px;
  margin: 15px;
}
.modal__description {
  margin-bottom: 20px;
  text-align: center;
  font-size: 14px;
}
@media (min-width: 992px) {
  .modal__description {
    margin: 0 -35px 20px;
  }
}
.modal__title {
  text-align: center;
  font-size: 20px;
  font-weight: 700;
  padding-bottom: 20px;
}
@media (min-width: 992px) {
  .modal__title {
    font-size: 30px;
  }
}
.modal__content {
  width: 100%;
  max-width: 440px;
  margin: 0 auto;
}
.modal__actions {
  display: flex;
  justify-content: center;
  align-items: center;
}
.modal__actions .btn {
  max-width: none;
  flex-grow: 1;
}
.modal__actions .link {
  margin-left: 30px;
  font-weight: 500;
}
@media (min-width: 992px) {
  .modal__actions .link {
    margin: 0 40px;
  }
}

.header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 8px 15px;
  background: #ffffff;
  box-shadow: 0px 10px 15px rgba(0, 0, 0, 0.03);
  position: fixed;
  z-index: 10;
  top: 0;
  left: 0;
  right: 0;
  transition: transform 404ms linear;
  transform: translateY(0);
  min-height: 60px;
}
.header.scroll-up {
  transform: translateY(-100%);
}
@media (min-width: 992px) {
  .header {
    padding: 10px 15px;
    min-height: 82px;
  }
}
@media (min-width: 1230px) {
  .header:not(.header__profile) {
    padding-left: calc(50vw - 600px);
    padding-right: calc(50vw - 600px);
  }
}
.header::before, .header::after {
  content: "";
  position: absolute;
  top: 0;
  height: 100%;
  width: 100vw;
  background: #ffffff;
  z-index: -1;
}
.header::before {
  right: calc(100% - 2px);
}
.header::after {
  left: calc(100% - 2px);
}
.header__logo {
  display: flex;
  align-items: center;
  justify-content: center;
  max-width: 50px;
  width: 100%;
  height: auto;
  margin-right: auto;
}
@media (min-width: 992px) {
  .header__logo {
    max-width: 70px;
    margin-right: 2%;
  }
}
.header .burger {
  margin-left: auto;
}
.header .user-block {
  display: none;
}
@media (min-width: 576px) {
  .header .user-block {
    display: flex;
  }
}
.header__nav {
  display: none;
  margin-right: auto;
}
@media (min-width: 992px) {
  .header__nav {
    gap: 50px;
    display: flex;
    width: 100%;
  }
}
@media (min-width: 992px) and (max-width: 1440px) {
  .header__nav {
    gap: calc(20px + 30 * ((100vw - 375px) / 1065));
  }
}
.header__nav .item a {
  padding: 0 8px;
  line-height: 30px;
  transition: background 404ms linear;
  display: flex;
  border-radius: 5px;
  font-weight: 500;
  white-space: nowrap;
}
.header__nav .item a:hover {
  background: #F8F6F3;
}
.header__nav .item a.active {
  position: relative;
}
.header__nav .item a.active::before {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 2px;
  background: #4F2B04;
}
@media (min-width: 992px) {
  .header__nav .last {
    margin-left: auto;
  }
}
.header__profile {
  padding-top: 4px;
  padding-bottom: 4px;
  min-height: 70px;
}
.header__profile .burger {
  margin-left: 15px;
}
.header__profile .header__logo {
  margin-right: 15px;
}
.header__profile .header__messages {
  margin-left: auto;
  margin-right: 20px;
  position: relative;
  display: none;
}
@media (min-width: 768px) {
  .header__profile .header__messages {
    display: flex;
  }
}
.header__profile .header__messages.not-empty::after {
  content: "";
  position: absolute;
  top: 2px;
  right: 0;
  width: 7px;
  height: 7px;
  background: #DCAC78;
  border-radius: 50%;
  overflow: hidden;
}

.home-banner,
.contact-block {
  display: flex;
  flex-direction: column;
  margin-top: 30px;
  position: relative;
}
.home-banner::after,
.contact-block::after {
  content: "";
  background: url("/images/bg/home-banner.svg") no-repeat center;
  background-size: cover;
  position: absolute;
  top: 0;
  left: -15px;
  height: 80%;
  width: calc(100% + 30px);
  z-index: -2;
  transform: scaleX(-1);
}
@media (min-width: 768px) {
  .home-banner,
.contact-block {
    padding: 10% 30px 10% 0;
    width: 50%;
    max-width: 450px;
  }
  .home-banner::after,
.contact-block::after {
    background-size: cover;
    position: absolute;
    top: 10%;
    width: 900px;
    z-index: -2;
    left: -80%;
  }
}
.home-banner__title,
.contact-block__title {
  margin-bottom: 10px;
}
.home-banner__description,
.contact-block__description {
  font-size: 14px;
  margin-bottom: 20px;
}
.home-banner__picture,
.contact-block__picture {
  height: 230px;
  display: flex;
  flex-direction: column;
  position: relative;
}
@media (min-width: 768px) {
  .home-banner__picture,
.contact-block__picture {
    position: absolute;
    top: 0;
    left: 100%;
    width: 100%;
    height: 100%;
  }
}
@media (min-width: 992px) {
  .home-banner__picture,
.contact-block__picture {
    width: calc(100vw - 480px);
  }
}
@media (min-width: 1230px) {
  .home-banner__picture,
.contact-block__picture {
    width: 100vw;
    max-width: 750px;
  }
}
.home-banner__picture::after,
.contact-block__picture::after {
  content: "";
  position: absolute;
  width: calc(100% - 15px);
  height: calc(100% - 10px);
  right: 15px;
  bottom: 0;
  border: 2px dashed #DCAC78;
  z-index: -1;
  border-radius: 50px 0;
}
@media (min-width: 992px) {
  .home-banner__picture::after,
.contact-block__picture::after {
    right: 5px;
    border-radius: 125px 0;
  }
}
.home-banner__picture img,
.contact-block__picture img {
  height: 100%;
  object-fit: cover;
  padding: 0 0 15px 10px;
  border-radius: 50px 0;
  overflow: hidden;
}
@media (min-width: 992px) {
  .home-banner__picture img,
.contact-block__picture img {
    padding: 0 0 20px 30px;
    border-radius: 125px 0;
  }
}
.home-banner__actions,
.contact-block__actions {
  display: flex;
  align-items: center;
  margin: 20px -9px 0;
}
@media (min-width: 768px) {
  .home-banner__actions,
.contact-block__actions {
    margin: 0 -14px;
  }
}
.home-banner__actions .btn,
.contact-block__actions .btn {
  flex-grow: 1;
  max-width: 200px;
}
.home-banner__actions .action,
.contact-block__actions .action {
  margin: 0 9px;
  font-weight: 500;
}
@media (min-width: 768px) {
  .home-banner__actions .action,
.contact-block__actions .action {
    margin: 0 14px;
  }
}

@media (min-width: 768px) {
  .content-video {
    display: flex;
    flex-direction: row-reverse;
  }
}
@media (min-width: 768px) {
  .content-video__content {
    padding-top: 10px;
    padding-bottom: 10px;
    padding-right: 4%;
    width: 48%;
  }
}
.content-video__video {
  display: flex;
  margin-bottom: 20px;
}
@media (min-width: 768px) {
  .content-video__video {
    margin-bottom: 0;
    width: 52%;
  }
}
.content-video__video .youtube-item {
  width: 100%;
  overflow: hidden;
  aspect-ratio: 16/9;
}
.content-video__video .youtube-item iframe {
  width: 100%;
  height: 100%;
}
.content-video__title {
  margin-bottom: 10px;
}
.content-video__description {
  font-size: 14px;
}
.content-video__actions {
  display: flex;
  align-items: center;
  margin: 20px -9px 0;
}
@media (min-width: 768px) {
  .content-video__actions {
    margin: 20px -14px 0;
  }
}
.content-video__actions .btn {
  flex-grow: 1;
  max-width: 200px;
}
.content-video__actions .action {
  margin: 0 9px;
  font-weight: 500;
}
@media (min-width: 768px) {
  .content-video__actions .action {
    margin: 0 14px;
  }
}

.block-menu {
  display: flex;
  flex-direction: column;
}
@media (min-width: 992px) {
  .block-menu {
    padding-top: 40px;
    padding-bottom: 30px;
    position: relative;
  }
  .block-menu::before, .block-menu::after {
    content: "";
    position: absolute;
    top: 0;
    width: calc(100% + 140px);
    height: 100%;
    border-radius: 0 150px;
  }
  .block-menu::after {
    left: -70px;
    background: rgba(255, 255, 255, 0.8);
    z-index: -1;
  }
  .block-menu::before {
    border: 2px dashed #DCAC78;
    left: -90px;
    top: 20px;
    z-index: -2;
  }
}
.block-menu__title {
  margin-bottom: 10px;
}
.block-menu__description {
  font-size: 14px;
}
.block-menu__description a {
  font-weight: bold;
}
.block-menu__items {
  margin: 20px -10px 0;
  display: flex;
  flex-wrap: wrap;
}
.block-menu__items .menu-item {
  width: calc(50% - 20px);
  margin-left: 10px;
  margin-right: 10px;
}
@media (min-width: 992px) {
  .block-menu__items .menu-item {
    width: calc(25% - 20px);
  }
}
.block-menu__action {
  display: flex;
  justify-content: center;
}
.block-menu__action .btn {
  max-width: 200px;
}

.blog__header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 25px;
}
.blog__all {
  font-weight: 500;
}
.blog__items {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
}
@media (min-width: 768px) {
  .blog__items {
    margin-left: -10px;
    margin-right: -10px;
  }
}
.blog__items .blog-item {
  width: 100%;
  margin-bottom: 30px;
}
@media (min-width: 768px) {
  .blog__items .blog-item {
    width: calc(50% - 20px);
    margin-left: 10px;
    margin-right: 10px;
  }
}
@media (min-width: 992px) {
  .blog__items .blog-item {
    width: calc(33.3333333333% - 20px);
    margin: 0 10px;
  }
}

.info-items {
  display: flex;
  flex-wrap: wrap;
  margin: 0 -15px;
  justify-content: space-between;
}
.info-items .img-text-col {
  flex: 1 1 300px;
  margin: 0 15px 20px;
}
@media (min-width: 992px) {
  .info-items .img-text-col {
    margin: 10px 15px;
    max-width: 300px;
  }
}

.map {
  width: 100vw;
  height: 80vh;
}

.icon-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.footer {
  padding-top: 60px;
  padding-bottom: 50px;
  width: 100vw;
  margin-inline: 50%;
  transform: translateX(-50%);
  background: linear-gradient(180deg, #F7F3EF 0%, #F9F7F5 57.81%);
}
@media (max-width: 1440px) {
  .footer {
    padding-top: calc(40px + 20 * ((100vw - 375px) / 1065));
  }
}
@media (max-width: 1440px) {
  .footer {
    padding-bottom: calc(30px + 20 * ((100vw - 375px) / 1065));
  }
}
.footer__wrapper {
  gap: 150px;
  display: grid;
}
@media (max-width: 1440px) {
  .footer__wrapper {
    gap: calc(40px + 110 * ((100vw - 375px) / 1065));
  }
}
@media (min-width: 768px) {
  .footer__wrapper {
    grid-template-columns: repeat(2, 1fr);
  }
}
@media (min-width: 992px) {
  .footer__wrapper {
    grid-template-columns: auto auto 1fr auto;
  }
}
.footer__title {
  font-size: 20px;
  line-height: 1;
  font-weight: bold;
}
.footer__item {
  display: flex;
  flex-direction: column;
  gap: 15px;
}
@media (min-width: 992px) {
  .footer__item {
    max-width: 230px;
  }
}
.footer__nav {
  display: flex;
  flex-direction: column;
  gap: 10px;
}
.footer__nav span {
  justify-content: flex-start;
}
.footer__nav a.active {
  background-size: 100% 1px;
  background-position-x: left;
}
.footer .social-link {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}
@media (min-width: 768px) {
  .footer .social-link {
    grid-column: span 2;
  }
}
@media (min-width: 992px) {
  .footer .social-link {
    grid-column: span 1;
    grid-column-end: -1;
  }
}
.footer .social-link__title {
  margin-bottom: 20px;
}
.footer .social-link .btn {
  margin-top: 30px;
  width: auto;
  padding-inline: 40px;
}

.copyright {
  display: flex;
  align-items: center;
  justify-content: center;
  line-height: 44px;
  background: #ffffff;
  position: relative;
}
.copyright::before, .copyright::after {
  content: "";
  position: absolute;
  top: 0;
  height: 100%;
  width: 100vw;
  background: #ffffff;
}
.copyright::before {
  right: calc(100% - 2px);
}
.copyright::after {
  left: calc(100% - 2px);
}
.copyright a {
  font-weight: bold;
}
.copyright a span {
  color: #e2231a;
}

.why-galya {
  display: flex;
  flex-direction: column;
}
@media (min-width: 992px) {
  .why-galya {
    flex-direction: row;
    align-items: flex-start;
  }
}
.why-galya__content {
  padding-top: 20px;
}
@media (min-width: 992px) {
  .why-galya__content {
    padding-left: 40px;
    padding-top: 30px;
    padding-bottom: 30px;
    position: relative;
  }
  .why-galya__content::after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    z-index: -1;
    background: url("/images/bg/why-galya.svg") no-repeat left;
    height: 100%;
    width: calc(100% + 15px);
  }
}
@media (min-width: 1230px) {
  .why-galya__content::after {
    background-size: contain;
  }
}
.why-galya .img-text-row {
  margin-top: 20px;
}
.why-galya .img-text-row__content {
  padding-left: 15px;
}
.why-galya__image {
  display: flex;
  flex-direction: column;
  position: relative;
  padding: 0 20px 20px 0;
  max-width: 490px;
  width: 100%;
  margin: 0 auto;
}
.why-galya__image::after {
  content: "";
  position: absolute;
  width: calc(100% - 20px);
  height: calc(100% - 20px);
  right: 0;
  bottom: 0;
  border: 2px dashed #DCAC78;
  z-index: -1;
  border-radius: 0 50px;
}
@media (min-width: 992px) {
  .why-galya__image::after {
    border-radius: 0 125px;
  }
}
.why-galya__image .photo {
  display: flex;
  border-radius: 0 50px;
  overflow: hidden;
}
@media (min-width: 992px) {
  .why-galya__image .photo {
    border-radius: 0 125px;
  }
}
.why-galya__image .small-photo {
  position: absolute;
  right: -5px;
  bottom: -15px;
  width: 67%;
}

.mobile-menu {
  position: fixed;
  height: calc(100% - 60px);
  width: 100%;
  left: 0;
  background: #ffffff;
  z-index: 10;
  display: flex;
  flex-direction: column;
  opacity: 0;
  visibility: hidden;
  pointer-events: none;
  transition: all 404ms linear;
}
@media (min-width: 992px) {
  .mobile-menu {
    display: none;
  }
}
.mobile-menu.show-menu {
  opacity: 1;
  visibility: visible;
  pointer-events: auto;
}
.mobile-menu__wrapper {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  padding: 0 30px;
  overflow-y: auto;
  overflow-x: hidden;
}
.mobile-menu__lang {
  padding: 20px 0;
}
.mobile-menu .copyright {
  position: relative;
}
.mobile-menu .copyright::after {
  content: "";
  position: absolute;
  width: 100%;
  height: 1px;
  background: #F1F1F1;
  top: 0;
  left: 0;
}

.not-found {
  padding-bottom: 70px;
  padding-top: 30px;
}
@media (min-width: 768px) {
  .not-found {
    padding-bottom: 100px;
    padding-top: 100px;
    display: flex;
    flex-direction: row-reverse;
    align-items: center;
  }
}
.not-found__content {
  margin-top: 30px;
}
@media (min-width: 768px) {
  .not-found__content {
    margin-top: 0;
    padding-right: 20px;
    width: 47%;
  }
}
.not-found__image {
  max-width: 500px;
  margin: 0 auto;
}
@media (min-width: 768px) {
  .not-found__image {
    max-width: none;
    width: 53%;
  }
}
.not-found__title {
  font-weight: 500;
  margin-bottom: 10px;
}
.not-found__text {
  font-size: 14px;
  line-height: 21px;
}
.not-found__actions {
  display: flex;
  align-items: center;
  margin: 20px -9px 0;
}
@media (min-width: 768px) {
  .not-found__actions {
    margin: 20px -15px 0;
  }
}
.not-found__actions .btn {
  flex-grow: 1;
  max-width: 200px;
}
.not-found__actions .action {
  margin: 0 9px;
  font-weight: 500;
}
@media (min-width: 768px) {
  .not-found__actions .action {
    margin: 0 15px;
  }
}

.not-found-page {
  background: #ffffff;
}

.form-auth {
  display: flex;
  flex-direction: column;
}
@media (min-width: 768px) {
  .form-auth {
    flex-direction: row-reverse;
  }
}
.form-auth__form-wrapper {
  padding: 20px 0 70px;
}
@media (min-width: 768px) {
  .form-auth__form-wrapper {
    width: 50%;
    padding: 60px 0;
    min-height: 690px;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
}
.form-auth__picture {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 0 -15px;
  overflow: hidden;
}
@media (min-width: 768px) {
  .form-auth__picture {
    width: calc(50% + 15px);
    margin: 0 -15px 0 0;
  }
  .form-auth__picture img {
    height: 100%;
    object-fit: cover;
  }
}
@media (min-width: 1230px) {
  .form-auth__picture {
    width: 50vw;
    margin-right: calc(600px - 50vw);
  }
}
@media (min-width: 768px) {
  .form-auth__form {
    padding-right: 20px;
  }
}
@media (min-width: 992px) {
  .form-auth__form {
    padding-right: 25%;
  }
}
.form-auth__title {
  margin-bottom: 20px;
}
.form-auth__sub-description {
  font-size: 14px;
  margin-bottom: 20px;
}
@media (min-width: 992px) {
  .form-auth__sub-description {
    padding-right: 25%;
  }
}
.form-auth__description {
  font-size: 14px;
  margin-top: 20px;
}
@media (min-width: 992px) {
  .form-auth__description {
    padding-right: 25%;
  }
}
.form-auth__description a {
  font-weight: 500;
}
.form-auth__actions {
  display: flex;
  align-items: center;
  margin: 20px -9px 0;
}
@media (min-width: 768px) {
  .form-auth__actions {
    margin: 20px -15px 0;
  }
}
.form-auth__actions .btn {
  flex-grow: 1;
}
.form-auth__actions .action {
  margin: 0 9px;
  white-space: nowrap;
  font-size: 14px;
  font-weight: 500;
}
@media (min-width: 768px) {
  .form-auth__actions .action {
    margin: 0 15px;
  }
}

.page {
  margin: 30px 0 100px;
}
@media (min-width: 992px) {
  .page {
    margin: 40px 0 150px;
  }
}
.page.not-full-width {
  text-align: left;
}
@media (min-width: 992px) {
  .page.not-full-width {
    padding-right: 30%;
  }
}
.page__title {
  display: flex;
  justify-content: center;
  margin-bottom: 20px;
  white-space: break-spaces;
}
.page__title.left {
  justify-content: start;
}
@media (min-width: 992px) {
  .page__title {
    margin-bottom: 30px;
  }
}
.page__content {
  font-size: 14px;
  line-height: 21px;
}
.page__content p {
  margin-bottom: 15px;
}

.blog-page {
  display: flex;
  flex-direction: column;
}
.blog-page__items {
  display: flex;
  flex-wrap: wrap;
}
@media (min-width: 576px) {
  .blog-page__items {
    margin: 0 -10px;
  }
}
.blog-page__items .blog-item {
  width: 100%;
  margin-bottom: 30px;
}
@media (min-width: 576px) {
  .blog-page__items .blog-item {
    width: calc(50% - 20px);
    margin: 0 10px 30px;
  }
}
@media (min-width: 992px) {
  .blog-page__items .blog-item {
    width: calc(33.3333333333% - 20px);
    margin-bottom: 50px;
  }
}

.contact-block {
  margin-bottom: 90px;
}
.contact-block__picture {
  order: 1;
  margin-top: 40px;
}
.contact-block__items {
  margin-top: 20px;
  display: flex;
  flex-direction: column;
}
.contact-block__items .social-media {
  margin-top: 20px;
}
@media (min-width: 576px) {
  .contact-block__items .social-media {
    margin-top: 0;
  }
}
@media (min-width: 576px) {
  .contact-block__items {
    flex-direction: row;
    justify-content: space-between;
  }
}
@media (min-width: 768px) {
  .contact-block__items {
    margin-top: 30px;
  }
}

.article-blog {
  margin-top: 30px;
  margin-bottom: 80px;
}
.article-blog > * + * {
  margin-top: 10px;
}
.article-blog img {
  border-radius: 30px;
}
@media (min-width: 768px) {
  .article-blog {
    margin-top: 50px;
    margin-bottom: 150px;
  }
}
.article-blog__header {
  display: flex;
  flex-direction: column;
  margin-bottom: 15px;
}
.article-blog__date {
  font-size: 18px;
}
.article-blog iframe {
  width: 100%;
  aspect-ratio: 16/9;
}
.article-blog .picture-block {
  display: flex;
  justify-content: center;
}
.article-blog .content {
  font-size: 14px;
  line-height: 21px;
  max-width: 840px;
  padding: 10px 0;
  white-space: break-spaces;
  flex-grow: 1;
}
.article-blog .image {
  display: flex;
}
@media (min-width: 768px) {
  .article-blog .image {
    width: 50%;
    flex: none;
  }
}
.article-blog .picture-text,
.article-blog .text-picture {
  display: flex;
  flex-direction: column;
}
@media (min-width: 768px) {
  .article-blog .picture-text,
.article-blog .text-picture {
    flex-direction: row;
  }
}
@media (min-width: 768px) {
  .article-blog .picture-text .content,
.article-blog .text-picture .content {
    padding-right: 4%;
  }
}
.article-blog .picture-text {
  display: flex;
  flex-direction: column;
}
@media (min-width: 768px) {
  .article-blog .picture-text {
    flex-direction: row;
  }
}
@media (min-width: 768px) {
  .article-blog .picture-text .content {
    padding-left: 4%;
  }
}

.side-cart {
  position: fixed;
  top: 0;
  right: 0;
  max-width: 450px;
  width: 100%;
  height: 100%;
  background: #ffffff;
  z-index: 20;
  background: linear-gradient(180deg, #F7F3EF 0%, #F9F7F5 57.81%);
  box-shadow: 0 10px 15px rgba(0, 0, 0, 0.03);
  padding: 0 15px;
  display: flex;
  flex-direction: column;
  transform: translateX(100%);
  transition: transform 404ms linear;
}
.side-cart__overlay {
  position: absolute;
  width: 100vw;
  height: 100%;
  background: rgba(0, 0, 0, 0.8);
  right: 100%;
  transform: translateX(-100%);
  transition: transform 404ms linear;
}
.side-cart.open-cart {
  transform: translateX(0);
}
.side-cart.open-cart .side-cart__overlay {
  transform: translateX(0);
}
.side-cart__header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 30px 0;
  border-bottom: 1px solid #CACACA;
}
.side-cart__title {
  font-size: 30px;
  font-weight: bold;
  line-height: 1;
}
.side-cart__count {
  margin-left: auto;
  font-size: 12px;
}
.side-cart__count span {
  padding-left: 5px;
}
.side-cart__close {
  width: 20px;
  height: 20px;
  display: flex;
  align-items: center;
  margin-left: 15px;
  cursor: pointer;
  background: url("/images/icons/close-cart.svg") no-repeat center;
}
.side-cart__main {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  padding: 20px 15px 20px 0;
  margin-right: -15px;
  overflow: auto;
}
.side-cart__footer {
  padding: 20px 0;
}
.side-cart .cart-item + .cart-item {
  margin-top: 15px;
}

.side-cart-empty {
  margin: auto;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.side-cart-empty__title {
  font-size: 24px;
  font-weight: bold;
  margin-bottom: 10px;
}
.side-cart-empty .btn {
  max-width: 180px;
}

.all-pricing {
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 24px;
  line-height: 36px;
  font-weight: bold;
  padding-bottom: 15px;
  position: relative;
}
.all-pricing::after {
  content: "";
  position: absolute;
  bottom: calc(100% + 20px);
  width: 100%;
  height: 1px;
  background: #CACACA;
}

.search-shop {
  padding: 70px 0;
  position: relative;
  min-height: 220px;
}
.search-shop::after {
  content: "";
  position: absolute;
  top: 0;
  left: -15px;
  width: 100vw;
  height: 100%;
  background: url("/images/bg/map.jpg") no-repeat center;
  background-size: cover;
  z-index: -1;
}
@media (min-width: 1230px) {
  .search-shop::after {
    left: calc(600px - 50vw);
  }
}
@media (min-width: 768px) {
  .search-shop {
    padding: 40px 0;
  }
}

.menu-page .search-shop {
  padding: 0;
}
.menu-page .search-shop-block {
  margin: 0 auto;
  transform: translateY(60px);
}
.menu-page .menu .custom-select {
  display: block;
}
@media (min-width: 992px) {
  .menu-page .menu .custom-select {
    display: none;
  }
}
.menu-page .position-menu {
  margin-top: 15px;
  margin-bottom: 20px;
}
@media (min-width: 992px) {
  .menu-page .position-menu {
    margin-top: 25px;
  }
}
.menu-page .position-menu__items {
  display: none;
  flex-wrap: wrap;
}
@media (min-width: 992px) {
  .menu-page .position-menu__items {
    display: flex;
    flex-wrap: wrap;
    gap: 15px;
  }
}
.menu-page .position-menu__items .item {
  font-size: 18px;
  line-height: 18px;
  cursor: pointer;
  transition: text-shadow 404ms linear;
}
.menu-page .position-menu__items .item:hover {
  text-shadow: 0 0 1px;
}
.menu-page .position-menu__items .item.active {
  font-size: 24px;
  font-weight: 500;
}

@media (max-width: 992px) {
  .position-menu {
    position: -webkit-sticky;
    position: sticky;
    top: 65px;
    transition: top 404ms linear;
    z-index: 1;
  }

  .hidden-menu .position-menu {
    top: 5px;
  }
}
.product-page {
  display: flex;
  flex-direction: column;
  margin: 25px 0 80px;
}
@media (min-width: 768px) {
  .product-page {
    flex-direction: row;
    flex-shrink: 1;
    margin: 30px 0 100px;
  }
  .product-page > div {
    width: 50%;
  }
  .product-page .product {
    padding-left: 30px;
  }
}
.product-page__picture {
  display: flex;
  justify-content: space-around;
  align-items: flex-start;
  margin-bottom: 15px;
}
@media (min-width: 768px) {
  .product-page__picture {
    margin-bottom: 0;
  }
}
.product-page__image-wrapper {
  display: flex;
  flex-direction: column;
  border-radius: 10px;
  overflow: hidden;
}

@media (min-width: 768px) {
  .sticky-element {
    position: -webkit-sticky;
    position: sticky;
    top: 90px;
    transition: top 404ms linear;
    z-index: 1;
  }

  .hidden-menu .sticky-element {
    top: 10px;
  }
}
.our-stores {
  height: calc(100vh + 150px);
  width: calc(100% + 30px);
  overflow: hidden;
  position: relative;
  left: -15px;
}
@media (min-width: 992px) {
  .our-stores {
    height: calc(100vh - 82px);
    overflow: visible;
  }
}
.our-stores .our-stores-map {
  width: 100%;
  margin-top: 150px;
  height: calc(100% + 170px);
}
@media (min-width: 768px) {
  .our-stores .our-stores-map {
    margin-top: 0;
  }
}
@media (min-width: 992px) {
  .our-stores .our-stores-map {
    height: 100%;
    width: calc(100vw + 150px);
  }
}
@media (min-width: 1230px) {
  .our-stores .our-stores-map {
    left: calc(600px - 50vw);
  }
}
.our-stores .position-count-label {
  padding-bottom: 9px;
}
.our-stores .search-shop-block {
  position: absolute;
  top: 0;
  width: 100%;
}
@media (min-width: 768px) {
  .our-stores .search-shop-block {
    left: 50%;
    top: 15px;
    transform: translateX(-50%);
    width: calc(100% - 30px);
    max-width: 550px;
  }
}
@media (min-width: 992px) {
  .our-stores .search-shop-block {
    left: 15px;
    top: 50%;
    transform: translateY(-50%);
  }
}
@media (min-width: 992px) {
  .our-stores .search-shop-block .current-location {
    margin-block: 10px;
  }
}
.our-stores__contents {
  margin-top: 20px;
  display: grid;
}
.our-stores__tab {
  display: grid;
  grid-template-rows: 0fr;
  opacity: 0;
  filter: blur(3px);
  transition: grid-template-rows 0.5s ease-out, opacity 0.5s ease-out, filter 0.5s ease-out;
}
.our-stores__tab > div {
  overflow: hidden;
}
.our-stores__tab .component {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 15px;
}
.our-stores__tab.show {
  grid-template-rows: 1fr;
  opacity: 1;
  filter: blur(0);
}
.our-stores__tab.show > div {
  animation-name: isHidden;
  animation-fill-mode: forwards;
  animation-timing-function: linear;
  animation-duration: 404ms;
}

@keyframes isHidden {
  0%, 99% {
    overflow: hidden;
  }
  100% {
    overflow: visible;
  }
}
.profile-wrapper {
  display: flex;
  background: url("/images/bg/footer.png");
  min-height: calc(100vh - 70px);
  flex-direction: column-reverse;
}
@media (min-width: 992px) {
  .profile-wrapper {
    height: calc(100vh - 70px);
    min-height: auto;
    flex-direction: row;
  }
}
.profile-wrapper__side-nav {
  display: flex;
  flex-direction: column;
  margin-top: auto;
}
@media (min-width: 992px) {
  .profile-wrapper__side-nav {
    margin-top: 0;
    flex-grow: 1;
    height: 100%;
    overflow-y: auto;
  }
  .profile-wrapper__side-nav .partner-menu__link.active::before {
    left: 15px;
  }
}
.profile-wrapper__side-nav .copyright {
  font-size: 12px;
}
.profile-wrapper__side-nav .copyright::after, .profile-wrapper__side-nav .copyright::before {
  content: none;
}
.profile-wrapper .partner-menu {
  margin: 0 0 0 -15px;
  flex: none;
  background: #ffffff;
  display: none;
}
@media (min-width: 992px) {
  .profile-wrapper .partner-menu {
    display: block;
    width: 280px;
    flex-grow: 1;
  }
}
.profile-wrapper .partner-menu__items {
  display: flex;
  flex-direction: column;
  height: 100%;
}
.profile-wrapper .partner-menu__items .mt-auto {
  margin-top: auto;
}
.profile-wrapper .profile-content {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
}
@media (min-width: 992px) {
  .profile-wrapper .profile-content {
    width: calc(100vw - 265px);
    overflow-y: auto;
    overflow-x: hidden;
    margin-bottom: 15px;
    height: calc(100vh - 90px);
    min-height: auto;
  }
}
.profile-wrapper + .copyright {
  box-shadow: 0px -10px 15px rgba(0, 0, 0, 0.03);
}

.profile-information {
  display: flex;
  flex-direction: column;
  padding: 15px 10px 30px;
  height: 100%;
}
@media (min-width: 1230px) {
  .profile-information {
    padding: 30px;
  }
}

.instagram__title {
  margin-bottom: 10px;
}
.instagram__items {
  display: flex;
  flex-wrap: wrap;
  margin: 0 -5px;
}
.instagram__item {
  width: calc(50% - 10px);
  margin: 0 5px 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 170px;
  overflow: hidden;
}
.instagram__item img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
@media (min-width: 768px) {
  .instagram__item {
    height: 230px;
    width: calc(33.3333333333% - 10px);
  }
}
@media (min-width: 992px) {
  .instagram__item {
    width: calc(25% - 10px);
  }
}
@media (min-width: 1230px) {
  .instagram__item {
    width: calc(20% - 10px);
  }
}

.our-contacts {
  display: flex;
  flex-direction: column;
}
@media (min-width: 768px) {
  .our-contacts {
    flex-direction: row;
  }
  .our-contacts > div {
    width: 50%;
  }
}
.our-contacts__title {
  margin-bottom: 20px;
}
.our-contacts__content {
  padding-top: 20px;
}
@media (min-width: 768px) {
  .our-contacts__content {
    padding: 30px 0 30px 8%;
  }
}
.our-contacts .work-schedule {
  padding-top: 20px;
}
@media (min-width: 992px) {
  .our-contacts .work-schedule {
    padding-top: 20px;
  }
}
.our-contacts__map {
  margin: 0 -15px;
  min-height: 220px;
  border-radius: 20px;
  box-shadow: 0 10px 15px rgba(0, 0, 0, 0.03);
  overflow: hidden;
}
@media (min-width: 768px) {
  .our-contacts__map {
    margin: 0;
  }
}

.map-locations {
  width: 100%;
  height: 100%;
}

.block-shops {
  display: flex;
  flex-direction: column-reverse;
}
@media (min-width: 768px) {
  .block-shops {
    flex-direction: row;
    align-items: center;
  }
  .block-shops::after {
    background-size: cover;
    position: absolute;
    top: 10%;
    width: 900px;
    z-index: -2;
    left: -80%;
  }
  .block-shops > div {
    width: 50%;
  }
}
.block-shops__content {
  padding: 30px 0 0 0;
  position: relative;
}
@media (min-width: 768px) {
  .block-shops__content {
    padding: 10% 30px 10% 0;
  }
}
.block-shops__content::after {
  content: "";
  background: url("/images/bg/home-banner.svg") no-repeat center;
  background-size: cover;
  position: absolute;
  top: 0;
  right: 0;
  height: calc(100% + 30px);
  width: calc(100% + 30px);
  z-index: -2;
  transform: scaleX(-1);
}
@media (min-width: 768px) {
  .block-shops__content::after {
    background-size: cover;
    position: absolute;
    height: 100%;
    width: 900px;
    z-index: -2;
    right: -10%;
  }
}
.block-shops__map {
  height: 250px;
  position: relative;
  margin-left: 15px;
}
@media (min-width: 768px) {
  .block-shops__map {
    margin-left: 0;
    height: 420px;
    flex-direction: row;
    align-items: center;
  }
}
.block-shops__map .map-locations {
  overflow: hidden;
  border-radius: 10px 50px;
}
@media (min-width: 992px) {
  .block-shops__map .map-locations {
    border-radius: 10px 125px;
  }
}
.block-shops__map::after {
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  right: 15px;
  bottom: -15px;
  border: 2px dashed #DCAC78;
  z-index: -1;
  border-radius: 0 50px;
}
@media (min-width: 992px) {
  .block-shops__map::after {
    right: 15px;
    border-radius: 0 125px;
  }
}
.block-shops__title {
  margin-bottom: 10px;
}
.block-shops__description {
  font-size: 14px;
  margin-bottom: 20px;
}
.block-shops__actions {
  display: flex;
  align-items: center;
  margin: 20px -9px 0;
}
@media (min-width: 768px) {
  .block-shops__actions {
    margin: 0 -14px;
  }
}
.block-shops__actions .btn {
  flex-grow: 1;
  max-width: 230px;
}
.block-shops__actions .action {
  margin: 0 9px;
  font-weight: 500;
}
@media (min-width: 768px) {
  .block-shops__actions .action {
    margin: 0 14px;
  }
}

.block-content-social {
  display: flex;
  flex-direction: column;
  gap: 0 20px;
}
@media (min-width: 768px) {
  .block-content-social {
    flex-direction: row;
  }
  .block-content-social > div {
    width: 50%;
    display: flex;
    flex-direction: column;
  }
}
.block-content-social__content {
  padding: 30px 0 0 0;
  position: relative;
  display: flex;
  flex-direction: column;
}
@media (min-width: 768px) {
  .block-content-social__content {
    padding: 5% 0 5% 35px;
    justify-content: center;
  }
}
.block-content-social__title {
  margin-bottom: 10px;
  line-height: 1.2;
}
.block-content-social__description {
  font-size: 14px;
  margin-bottom: 20px;
  align-self: flex-start;
}
.block-content-social__picture {
  display: flex;
  position: relative;
}
@media (min-width: 768px) {
  .block-content-social__picture {
    min-height: 420px;
  }
}
.block-content-social__picture img {
  width: 100%;
  object-fit: cover;
  border-radius: 0 50px;
  overflow: hidden;
  padding-right: 15px;
  height: 280px;
}
@media (min-width: 768px) {
  .block-content-social__picture img {
    height: 100%;
  }
}
@media (min-width: 992px) {
  .block-content-social__picture img {
    padding-right: 25px;
    border-radius: 0 125px;
  }
}
.block-content-social__picture::after {
  content: "";
  position: absolute;
  width: calc(100% - 15px);
  height: 100%;
  right: 0;
  bottom: -15px;
  border: 2px dashed #DCAC78;
  z-index: -1;
  border-radius: 0 50px;
}
@media (min-width: 992px) {
  .block-content-social__picture::after {
    right: 0;
    bottom: -25px;
    width: calc(100% - 25px);
    border-radius: 0 125px;
  }
}
.block-content-social .social-link {
  align-self: flex-start;
}

.content-map {
  display: grid;
  gap: 20px;
}
@media (min-width: 768px) {
  .content-map {
    grid-template-columns: repeat(2, 1fr);
  }
}
.content-map__title {
  font-size: 36px;
  line-height: 1;
}
@media (max-width: 1440px) {
  .content-map__title {
    font-size: calc(24px + 12 * ((100vw - 375px) / 1065));
  }
}
.content-map__map {
  display: flex;
  flex-direction: column;
  aspect-ratio: 1.8;
  border-radius: 10px;
  background-color: #E8D8C8;
  overflow: hidden;
}
.content-map__map .block-map {
  width: 100%;
  height: 100%;
}
.content-map__content {
  display: flex;
  flex-direction: column;
  gap: 10px;
}
@media (min-width: 768px) {
  .content-map__content {
    padding-top: 30px;
    order: -1;
  }
}
@media (min-width: 768px) and (max-width: 1440px) {
  .content-map__content {
    padding-top: calc(0px + 30 * ((100vw - 375px) / 1065));
  }
}
.content-map__description {
  font-size: 14px;
  max-width: 370px;
}
.content-map__actions {
  margin-top: 15px;
  display: flex;
  flex-direction: column;
}
@media (max-width: 1440px) {
  .content-map__actions {
    margin-top: calc(10px + 5 * ((100vw - 375px) / 1065));
  }
}
@media (min-width: 768px) {
  .content-map__actions {
    align-items: flex-start;
  }
}
.content-map__actions .btn {
  width: auto;
  padding-inline: 70px;
}

.domain-banner {
  padding-top: 200px;
  padding-bottom: 110px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
  border-radius: 60px 10px;
  overflow: hidden;
  margin-top: 15px;
}
@media (max-width: 1440px) {
  .domain-banner {
    padding-top: calc(150px + 50 * ((100vw - 375px) / 1065));
  }
}
@media (max-width: 1440px) {
  .domain-banner {
    padding-bottom: calc(80px + 30 * ((100vw - 375px) / 1065));
  }
}
@media (min-width: 992px) {
  .domain-banner {
    border-radius: 150px 10px;
  }
}
@media (min-width: 1440px) {
  .domain-banner {
    margin-inline: -70px;
  }
}
.domain-banner__picture {
  position: absolute;
  inset: 0;
  border-radius: inherit;
}
.domain-banner__picture img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.domain-banner__picture::after {
  content: "";
  position: absolute;
  inset: 0;
  background-color: rgba(0, 0, 0, 0.5);
}
.domain-banner__main {
  gap: 70px;
  isolation: isolate;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
@media (max-width: 1440px) {
  .domain-banner__main {
    gap: calc(30px + 40 * ((100vw - 375px) / 1065));
  }
}
.domain-banner__main .btn {
  width: auto;
  min-width: 200px;
}
.domain-banner__header {
  text-align: center;
  color: #ffffff;
}
.domain-banner__header h1 {
  display: flex;
  flex-direction: column;
}

.feedback {
  display: flex;
  flex-direction: column;
  padding-top: 10px;
  padding-bottom: 10px;
  position: relative;
}
@media (min-width: 992px) {
  .feedback {
    padding-top: 40px;
    padding-bottom: 30px;
  }
}
.feedback::before, .feedback::after {
  content: "";
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
  border-radius: 0 70px;
}
@media (min-width: 992px) {
  .feedback::before, .feedback::after {
    width: calc(100% + 140px);
    border-radius: 0 150px;
  }
}
.feedback::after {
  left: 0;
  background: rgba(255, 255, 255, 0.8);
  z-index: -1;
}
@media (min-width: 992px) {
  .feedback::after {
    left: -70px;
  }
}
.feedback::before {
  border: 2px dashed #DCAC78;
  left: -10px;
  top: 10px;
  z-index: -2;
}
@media (min-width: 992px) {
  .feedback::before {
    left: -90px;
    top: 20px;
  }
}
.feedback__main {
  gap: 240px;
  display: grid;
  padding-top: 50px;
  padding-bottom: 60px;
  padding-inline: 20px;
}
@media (max-width: 1440px) {
  .feedback__main {
    gap: calc(60px + 180 * ((100vw - 375px) / 1065));
  }
}
@media (min-width: 992px) {
  .feedback__main {
    grid-template-columns: repeat(2, 1fr);
    padding-inline: 30px;
  }
}
.feedback__main--one-item {
  grid-template-columns: 1fr;
}
.feedback__main--one-item .feedback__item {
  max-width: 460px;
  width: 100%;
  margin-inline: auto;
}
.feedback__items {
  display: flex;
  flex-direction: column;
  gap: 40px;
}
.feedback__item {
  display: flex;
  flex-direction: column;
  gap: 20px;
}
.feedback__item form {
  display: grid;
}
.feedback__item input,
.feedback__item textarea:not(.is-invalid) {
  border-color: transparent;
  box-shadow: 0 4px 39px rgba(0, 0, 0, 0.05);
}
.feedback__item:nth-child(2) {
  position: relative;
}
.feedback__item:nth-child(2)::after {
  top: -120px;
  content: "";
  position: absolute;
  height: 1px;
  left: 0;
  width: 100%;
  background: repeating-linear-gradient(to right, #DCAC78, #DCAC78 10px, transparent 10px, transparent 20px);
}
@media (max-width: 1440px) {
  .feedback__item:nth-child(2)::after {
    top: calc(-30px + -90 * ((100vw - 375px) / 1065));
  }
}
@media (min-width: 992px) {
  .feedback__item:nth-child(2)::after {
    left: -120px;
    top: -10%;
    height: 120%;
    width: 1px;
    background: repeating-linear-gradient(to bottom, #DCAC78, #DCAC78 10px, transparent 10px, transparent 20px);
    transform: translateX(-50%);
  }
}
@media (min-width: 992px) and (max-width: 1440px) {
  .feedback__item:nth-child(2)::after {
    left: calc(-30px + -90 * ((100vw - 375px) / 1065));
  }
}
.feedback__action {
  display: flex;
  justify-content: center;
}
@media (min-width: 576px) {
  .feedback__action [type=submit] {
    width: auto;
    min-width: 200px;
  }
}

.form-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 5px;
}
.form-header__title {
  font-size: 24px;
  font-weight: 500;
}
@media (min-width: 992px) {
  .form-header__title {
    font-size: 26px;
  }
}
.form-header__link {
  font-size: 14px;
  line-height: 1.2;
  cursor: pointer;
  border-radius: 4px;
  transition: background 404ms linear;
  padding: 4px;
}
.form-header__link:hover {
  background: #F0CAC8;
}

.statistics-profile {
  display: flex;
  flex-wrap: wrap;
  margin: -15px -4px 10px;
  padding: 0 8px;
}
@media (min-width: 992px) {
  .statistics-profile {
    margin: -30px -10px 0;
    padding: 0 30px;
  }
}
.statistics-profile__item {
  width: calc(50% - 8px);
  margin: 0 4px 8px;
}
@media (min-width: 768px) {
  .statistics-profile__item {
    margin: 0 10px 8px;
    width: calc(25% - 20px);
  }
}

.statistics-profile-item {
  display: flex;
  flex-direction: column;
  padding: 35px 10px 10px;
  border-radius: 10px;
  background: #ffffff;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  position: relative;
  text-align: center;
}
@media (min-width: 992px) {
  .statistics-profile-item {
    padding: 50px 10px 20px;
  }
}
.statistics-profile-item__picture {
  position: absolute;
  top: 0;
  left: 0;
  width: 48px;
  height: 30px;
  border-radius: 10px;
  background: #DCAC78;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
}
@media (min-width: 992px) {
  .statistics-profile-item__picture {
    width: 70px;
    height: 42px;
  }
}
.statistics-profile-item__number {
  font-size: 24px;
  line-height: 1;
  font-weight: 500;
  margin-bottom: 5px;
}
@media (min-width: 992px) {
  .statistics-profile-item__number {
    font-size: 26px;
  }
}
.statistics-profile-item__title {
  font-size: 14px;
  line-height: 1.2;
  font-weight: 500;
}
@media (min-width: 992px) {
  .statistics-profile-item__title {
    font-size: 18px;
    line-height: 1;
    margin-bottom: 8px;
  }
}
.statistics-profile-item__status {
  font-size: 14px;
  line-height: 1;
  display: none;
  color: #45BD60;
}
@media (min-width: 992px) {
  .statistics-profile-item__status {
    display: block;
  }
}
.statistics-profile-item__status.gray {
  color: #CACACA;
}
.statistics-profile-item__status.red {
  color: #E3000B;
}
.statistics-profile-item__status.orange {
  color: #FF9900;
}

.box-document {
  background: #ffffff;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  padding: 30px 15px 15px;
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: 14px;
}
.box-document__icon {
  display: flex;
  justify-content: center;
}
.box-document__name {
  margin-top: 10px;
  margin-bottom: 15px;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 100%;
  text-align: center;
}
.box-document__download {
  font-weight: 500;
}

.message-alert {
  display: flex;
  align-items: center;
  border-radius: 10px;
  min-height: 40px;
  padding: 13px 15px 13px 40px;
  position: relative;
  font-size: 12px;
  line-height: 1.1;
  max-width: 600px;
  width: 100%;
}
.message-alert::after {
  content: "";
  position: absolute;
  left: 15px;
  top: 11px;
  width: 15px;
  height: 15px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.message-alert__full-width {
  max-width: none;
}
.message-alert.m-top {
  margin-top: 10px;
}
.message-alert.m-bot {
  margin-bottom: 10px;
}
.message-alert.error {
  box-shadow: inset 0 0 0 1px #F0CAC8;
  color: #E3000B;
  background: rgba(227, 0, 11, 0.06);
}
.message-alert.error::after {
  background: url("/images/icons/messages/error.svg") no-repeat;
}
.message-alert.moderation {
  box-shadow: inset 0 0 0 1px #E89C2B;
  color: #E89C2B;
  background: rgba(232, 156, 43, 0.06);
}
.message-alert.moderation::after {
  background: url("/images/icons/messages/moderation.svg") no-repeat;
}

.c-table {
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  background: #ffffff;
}
.c-table__header {
  background: #F7F3F0;
  display: none;
  align-items: center;
  padding: 10px 15px 6px;
  font-size: 12px;
  font-weight: bold;
  color: #AEACA8;
}
@media (min-width: 768px) {
  .c-table__header {
    display: flex;
  }
}
.c-table__header > div:not(.c-table__photo),
.c-table .c-table-row > div:not(.c-table__photo) {
  padding: 0 10px;
}
@media (min-width: 768px) {
  .c-table__photo {
    width: 125px;
    flex: none;
  }
}
.c-table__name {
  flex-grow: 1;
  width: calc(100% - 60px);
}
.c-table__weight {
  display: none;
}
@media (min-width: 768px) {
  .c-table__weight {
    display: flex;
    min-width: 120px;
  }
}
.c-table__price {
  min-width: 140px;
  order: 1;
  flex-grow: 1;
}
@media (min-width: 768px) {
  .c-table__price {
    order: 0;
    flex-grow: 0;
  }
}
.c-table__status {
  min-width: 75px;
}
.c-table__action {
  min-width: 60px;
  order: 2;
}
@media (min-width: 1230px) {
  .c-table__action {
    order: 0;
    min-width: 150px;
  }
}

.c-table-row {
  display: flex;
  align-items: center;
  padding: 15px;
  font-size: 14px;
  flex-wrap: wrap;
}
@media (min-width: 768px) {
  .c-table-row {
    flex-wrap: nowrap;
  }
}
.c-table-row__photo {
  display: flex;
  width: 60px;
  height: 36px;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  border-radius: 10px;
  flex: none;
  margin-bottom: auto;
}
@media (min-width: 768px) {
  .c-table-row__photo {
    width: 125px;
    height: 75px;
  }
}
.c-table-row__photo img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.c-table-row__name {
  font-weight: 500;
  flex-grow: 1;
  line-height: 1.1;
  width: calc(100% - 60px);
}
@media (min-width: 768px) {
  .c-table-row__name {
    order: 0;
    width: auto;
  }
}
.c-table-row__name .weight {
  font-size: 12px;
  line-height: 1;
  font-weight: 400;
  margin-bottom: 12px;
  margin-top: 7px;
  display: flex;
}
@media (min-width: 768px) {
  .c-table-row__name .weight {
    display: none;
  }
}
.c-table-row__price {
  font-weight: 500;
  text-align: center;
}
.c-table-row__price .name {
  font-size: 12px;
  color: #000;
}
@media (min-width: 768px) {
  .c-table-row__price {
    order: 0;
    text-align: left;
  }
}
.c-table-row__action {
  margin-right: -10px;
}
@media (min-width: 768px) {
  .c-table-row__action {
    margin-right: 0;
    display: flex;
  }
}
.c-table-row__status {
  margin-left: -15px;
  height: 36px;
  display: flex;
  align-items: center;
}
@media (min-width: 768px) {
  .c-table-row__status {
    margin-left: 0;
    height: auto;
  }
}
.c-table-row .edit {
  display: flex;
  align-items: center;
  justify-content: end;
  margin-left: auto;
}
.c-table-row .edit__image {
  display: flex;
  align-items: center;
  transform: translateY(-2px);
  width: 36px;
  height: 36px;
  justify-content: center;
  background: #F7F3EF;
  border-radius: 5px;
}
@media (min-width: 1230px) {
  .c-table-row .edit__image {
    display: flex;
    margin-right: 10px;
    width: auto;
    height: auto;
    background: transparent;
    border-radius: 0;
  }
}
.c-table-row .edit__name {
  line-height: 1;
  font-weight: 500;
  display: none;
}
@media (min-width: 1230px) {
  .c-table-row .edit__name {
    display: flex;
  }
}
.c-table-row + .c-table-row {
  position: relative;
}
.c-table-row + .c-table-row::after {
  content: "";
  position: absolute;
  bottom: 100%;
  left: 15px;
  transform: translateY(-50%);
  height: 1px;
  width: calc(100% - 30px);
  background: #EFECE8;
}

.pay-item {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: repeat(4, 1fr);
  gap: 15px;
  grid-template-areas: "number number" "name name" "pay pay" "date price";
}
@media (min-width: 768px) {
  .pay-item {
    grid-template-columns: repeat(3, 1fr);
    grid-template-rows: repeat(2, 1fr);
    gap: 15px;
    grid-template-areas: "number name name" "pay date price";
  }
}
@media (min-width: 1230px) {
  .pay-item {
    display: flex;
    justify-content: space-between;
  }
  .pay-item .name {
    width: 220px;
  }
}
.pay-item__status--red {
  color: #EE5454;
  font-weight: 500;
}
.pay-item .number {
  grid-area: number;
}
.pay-item .name {
  grid-area: name;
}
.pay-item .pay {
  grid-area: pay;
}
.pay-item .date {
  grid-area: date;
}
.pay-item .price {
  grid-area: price;
}

.payment-page .form-body + .form-body {
  margin-top: 10px;
}

.message {
  display: flex;
  flex-direction: column;
  padding: 20px 15px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  background: #ffffff;
  font-size: 14px;
}
.message + .message {
  margin-top: 8px;
}
@media (min-width: 768px) {
  .message {
    flex-direction: row;
  }
}
@media (min-width: 992px) {
  .message {
    padding: 25px 20px;
  }
}
.message.unread {
  position: relative;
}
.message.unread::after {
  content: "";
  position: absolute;
  left: 5px;
  top: 5px;
  width: 7px;
  height: 7px;
  border-radius: 50%;
  background: #DCAC78;
}
.message__left {
  column-gap: 5px;
  min-width: 150px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
}
@media (min-width: 768px) {
  .message__left {
    flex-direction: column;
    width: 180px;
    flex: none;
  }
}
.message__left .title {
  font-weight: 500;
}
.message__left .date {
  color: #888888;
}
.message__center {
  flex-grow: 1;
  margin: 8px 0 0 0;
}
@media (min-width: 768px) {
  .message__center {
    margin: 0 15px;
  }
}
.message__right {
  color: #888888;
}
.message__open {
  background: #EDE6E0;
  border-radius: 5px;
  padding: 0 10px;
  line-height: 36px;
  display: flex;
  font-weight: 500;
  transition: opacity 404ms linear;
  justify-content: center;
  margin-top: 10px;
}
@media (min-width: 768px) {
  .message__open {
    margin-top: 0;
  }
}
.message__open:hover {
  opacity: 0.7;
}
.message.read .message__right {
  display: none;
}
@media (min-width: 768px) {
  .message.read .message__right {
    display: flex;
  }
}
@media (min-width: 768px) {
  .message.read .message__left .date {
    display: none;
  }
}

.element-location {
  display: flex;
  max-width: 300px;
  width: 100%;
  background: #F8F6F3;
  border-radius: 5px;
  padding: 12px;
}
.element-location__picture {
  display: flex;
  flex-direction: column;
  margin-right: 10px;
  flex: none;
  width: 28px;
}
.element-location__city {
  font-weight: 500;
}

.my-shop-item {
  width: 100%;
}
.my-shop-item .form-header {
  justify-content: end;
}
.my-shop-item .form-body {
  display: flex;
  position: relative;
}
.my-shop-item.not-active .form-body {
  opacity: 0.7;
  mix-blend-mode: luminosity;
  cursor: not-allowed;
}
.my-shop-item .phones {
  margin-top: 5px;
}
.my-shop-item__labels {
  position: absolute;
  left: 10px;
  top: 5px;
  height: 20px;
  display: flex;
  align-items: center;
}
.my-shop-item__status {
  font-size: 14px;
  font-weight: 500;
  line-height: 1;
  padding-top: 2px;
}
.my-shop-item__status.active {
  color: #45BD60;
}
.my-shop-item__delivery {
  position: relative;
  width: 20px;
  height: 20px;
  margin-right: 5px;
}
.my-shop-item__delivery::after {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  overflow: hidden;
  background: #DCAC78 url("/images/icons/cart-white.svg") no-repeat 40%;
}
.my-shop-item__logo {
  width: 75px;
  display: flex;
  flex-direction: column;
  margin-right: 10px;
  flex: none;
  margin-bottom: 15px;
}
@media (min-width: 992px) {
  .my-shop-item__logo {
    margin-right: 15px;
    margin-bottom: 0;
  }
}
.my-shop-item__content {
  flex-grow: 1;
  display: flex;
  flex-wrap: wrap;
  font-size: 14px;
  gap: 15px;
}
@media (min-width: 1230px) {
  .my-shop-item__content {
    flex-wrap: wrap;
  }
}
.my-shop-item__info {
  width: 50%;
}

.my-shop-wrapper {
  display: flex;
  flex-wrap: wrap;
  row-gap: 20px;
}
@media (min-width: 768px) {
  .my-shop-wrapper {
    margin: 0 -10px;
  }
}
@media (min-width: 768px) {
  .my-shop-wrapper .my-shop-item {
    width: calc(50% - 20px);
    margin: 0 10px;
  }
}

.booking-item {
  display: flex;
  flex-direction: column;
  background: #F8F6F3;
  padding: 8px 10px;
  border-radius: 5px;
  overflow: hidden;
}
.booking-item--not-bg {
  padding: 0;
  background: transparent;
  overflow: visible;
}
.booking-item--not-bg .booking-item__main {
  margin: 0;
}
.booking-item__header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 12px;
  margin-bottom: 10px;
}
.booking-item__btn {
  font-weight: 500;
  padding: 0 10px;
  line-height: 20px;
  border-radius: 5px;
  background: #E8D8C8;
}
.booking-item__info {
  line-height: 20px;
}
.booking-item__main {
  display: flex;
  font-size: 14px;
  cursor: pointer;
  transition: background 404ms linear;
  border-radius: 5px;
  padding: 4px;
  margin: 0 -4px;
}
.booking-item__main.active, .booking-item__main:hover {
  background: #ded9d2;
}
.booking-item__main * {
  pointer-events: none;
}
.booking-item__marker-info {
  display: flex;
  font-size: 14px;
  transition: background 404ms linear;
  padding: 4px;
  cursor: auto;
  max-width: 300px;
}
.booking-item__logo {
  width: 50px;
  display: flex;
  margin-right: 10px;
  flex: none;
}
.booking-item__city {
  font-weight: 600;
  margin-bottom: 4px;
}

.locations {
  display: flex;
  flex-direction: column;
  margin-top: 15px;
  flex-grow: 1;
  overflow: hidden;
  position: relative;
}
@media (min-width: 768px) {
  .locations {
    margin-top: 30px;
  }
}
.locations__title {
  font-size: 14px;
  margin-bottom: 10px;
}
.locations__item {
  margin: 7px -7px;
  position: relative;
}
.locations__item::after {
  content: "";
  position: absolute;
  top: -7px;
  width: calc(100% - 14px);
  left: 7px;
  height: 1px;
  background: #E4E4E4;
}
.locations__item + .locations__item {
  margin-top: 14px;
}
.locations__items {
  overflow-y: auto;
  height: 100%;
}

.location-profile {
  display: flex;
  border-radius: 5px;
  padding: 10px;
  transition: background 404ms linear;
}
.location-profile:hover {
  background: #F8F6F3;
}
.location-profile__wrapper {
  display: flex;
  flex-direction: column;
  font-size: 14px;
}
.location-profile__logo {
  width: 50px;
  display: flex;
  margin-right: 10px;
}
.location-profile__city {
  font-weight: 600;
  margin-bottom: 4px;
}

.booking-wrapper {
  width: 100%;
  height: auto;
  background: #ffffff;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  z-index: 1;
  opacity: 0;
  pointer-events: none;
  visibility: hidden;
  transition: opacity 404ms linear;
  display: none;
}
@media (min-width: 768px) {
  .booking-wrapper {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
  }
}
.booking-wrapper.show {
  opacity: 1;
  pointer-events: auto;
  visibility: visible;
  display: flex;
}
.booking-wrapper__text {
  margin-bottom: 15px;
  text-align: center;
  font-size: 12px;
  padding: 0 15px;
  font-weight: 500;
}
.booking-wrapper__btn {
  background: #4F2B04;
  color: #ffffff;
  border-radius: 20px 5px;
  line-height: 36px;
  padding: 0 20px;
  font-size: 14px;
  font-weight: 500;
  cursor: pointer;
  transition: background 404ms linear;
}
.booking-wrapper__btn:hover {
  background: #DCAC78;
}

.booking-info-message,
.booking-can-not {
  margin: auto;
}
.booking-info-message__text,
.booking-can-not__text {
  font-size: 18px;
  font-weight: 600;
  text-align: center;
}

.nearest-locations {
  display: none;
}
.nearest-locations.show {
  display: flex;
  flex-direction: column;
  height: 100%;
}
.nearest-locations__list {
  overflow: auto;
}
.nearest-locations .booking-item {
  margin-top: 14px;
  position: relative;
}
.nearest-locations .booking-item::after {
  content: "";
  position: absolute;
  width: 100%;
  height: 1px;
  top: -7px;
  transform: translateY(-50%);
  background: #E4E4E4;
}

.uploaded-files-container {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(100px, 1fr));
  gap: 10px;
}
.uploaded-files-container .uploaded-files-item {
  display: flex;
  position: relative;
}
.uploaded-files-container .uploaded-files-item img {
  aspect-ratio: 16/9;
  object-fit: contain;
  filter: brightness(1);
  transition: filter 404ms;
}
.uploaded-files-container .uploaded-files-item .delete {
  width: 20px;
  height: 20px;
  display: flex;
  position: absolute;
  right: 5px;
  border-radius: 5px;
  top: 5px;
  background: url("/images/icons/icon-delete.svg") no-repeat center, #ffffff;
  background-size: 70%, auto;
  cursor: pointer;
}
@media (hover: hover) {
  .uploaded-files-container .uploaded-files-item .delete {
    transform: scale(0.5);
    opacity: 0.5;
    transition: transform 404ms, opacity 404ms;
  }
}
@media (hover: hover) {
  .uploaded-files-container .uploaded-files-item:hover img {
    filter: brightness(0.3);
  }
  .uploaded-files-container .uploaded-files-item:hover .delete {
    transform: scale(1);
    opacity: 1;
  }
}

.product-form {
  display: flex;
  flex-wrap: wrap;
}
@media (min-width: 768px) {
  .product-form > div {
    width: 50%;
  }
}
.product-form__picture {
  border-radius: 10px;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;
  height: 190px;
  align-items: center;
  justify-content: center;
}
@media (min-width: 768px) {
  .product-form__picture {
    margin-bottom: 0;
    height: auto;
    justify-content: flex-start;
  }
}
.product-form__picture img {
  width: 100%;
  height: auto;
  object-fit: cover;
}
@media (min-width: 768px) {
  .product-form__form {
    padding-left: 15px;
  }
}
.product-form__form .form-group {
  margin-bottom: 20px;
}
.product-form__price {
  display: flex;
  align-items: center;
  margin-top: 10px;
}
.product-form__price input {
  max-width: 160px;
  font-size: 26px;
  font-weight: 500;
  padding-top: 10px;
  padding-bottom: 10px;
}
.product-form__price-name {
  margin-left: 10px;
  font-weight: 500;
}

.c-file-input {
  display: flex;
}
.c-file-input__input {
  opacity: 0;
  height: 0;
  width: 0;
  pointer-events: none;
  visibility: hidden;
}
.c-file-input__label {
  line-height: 33px;
  padding-left: 40px;
  padding-right: 15px;
  position: relative;
  background: #F8F6F3;
  border-radius: 5px;
  transition: background-color 404ms linear;
  max-width: 350px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}
.c-file-input__label:hover {
  background-color: rgba(220, 172, 120, 0.5);
}
.c-file-input__label::after {
  content: "";
  position: absolute;
  left: 10px;
  top: 8px;
  width: 18px;
  height: 15px;
  display: flex;
  background: url("/images/icons/photo-icon.svg") no-repeat center;
}
.c-file-input__label.is-document::after {
  background: url("/images/icons/document-icon.svg") no-repeat center;
}

.form-tab-lang {
  display: flex;
  align-items: center;
  border-bottom: 1px solid #EDE6E0;
  margin-bottom: 20px;
}
.form-tab-lang__item {
  max-width: 150px;
  width: 100%;
  display: flex;
  justify-content: center;
  line-height: 1;
  padding-bottom: 10px;
  cursor: pointer;
  position: relative;
}
.form-tab-lang__item::before {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  border-radius: 3px;
  height: 3px;
  width: 100%;
  background: #4F2B04;
  transform: translateY(50%);
  opacity: 0;
  transition: opacity 404ms linear;
}
.form-tab-lang__item:hover::before {
  opacity: 1;
  background: rgba(79, 43, 4, 0.6);
}
.form-tab-lang__item.active {
  transition: font-weight 404ms linear;
  font-weight: 500;
}
.form-tab-lang__item.active::before {
  opacity: 1;
}

[data-lang-item] {
  display: none;
}
[data-lang-item].show {
  display: flex;
}

.search-block {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 20px;
}
.search-block__search, .search-block__select {
  flex-grow: 1;
}
.search-block__search {
  max-width: 480px;
}
.search-block__select {
  max-width: 200px;
}

.content-block-3-wrapper {
  display: flex;
  flex-wrap: wrap;
}
.content-block-3-wrapper .content-block-3 {
  width: 100%;
  position: relative;
}
.content-block-3-wrapper .content-block-3 .item-text:last-child {
  margin-bottom: 0;
}
.content-block-3-wrapper .content-block-3:not(:last-child) {
  padding-bottom: 20px;
  margin-bottom: 20px;
}
.content-block-3-wrapper .content-block-3:not(:last-child)::after {
  content: "";
  position: absolute;
  width: 100%;
  height: 1px;
  bottom: 0;
  background: #CACACA;
}
@media (min-width: 768px) {
  .content-block-3-wrapper .content-block-3 {
    width: 50%;
    padding-right: 15px;
  }
}

.profile-companies {
  font-size: 16px;
  font-weight: 500;
}
@media (min-width: 576px) {
  .profile-companies {
    margin-right: auto;
  }
}
.profile-companies__active {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  z-index: 1;
  cursor: pointer;
}
@media (min-width: 576px) {
  .profile-companies__active {
    position: relative;
    transform: none;
    left: 0;
    top: 0;
  }
}
.profile-companies__active.multiple::after {
  content: "";
  position: absolute;
  background: url("/images/icons/arrow-profile-companies.svg") no-repeat center;
  display: flex;
  align-items: center;
  left: calc(100% + 5px);
  top: 50%;
  transform: translateY(-50%);
  width: 10px;
  height: 7px;
}
.profile-companies__active.active.multiple::after {
  transform: translateY(-50%) scale(-1);
}
@media (min-width: 768px) {
  .profile-companies {
    position: relative;
  }
}
.profile-companies__items {
  position: absolute;
  top: 100%;
  left: 0;
  width: 100%;
  background-color: #ffffff;
  text-align: center;
  padding: 25px 15px;
  box-shadow: inset 0 1px 15px rgba(0, 0, 0, 0.1), 0 10px 15px rgba(0, 0, 0, 0.1);
  opacity: 0;
  pointer-events: none;
  visibility: hidden;
  transition: opacity 404ms linear;
  display: flex;
  flex-direction: column;
  align-items: center;
}
@media (min-width: 768px) {
  .profile-companies__items {
    width: 250px;
    align-items: flex-start;
    left: -15px;
    margin-top: 15px;
  }
}
.profile-companies__items.show {
  opacity: 1;
  pointer-events: all;
  visibility: visible;
}
.profile-companies__item + .profile-companies__item {
  margin-top: 20px;
}
.profile-companies__item {
  cursor: pointer;
}

.box-document-wrapper {
  display: flex;
  flex-wrap: wrap;
  margin: 30px -10px 0;
}
.box-document-wrapper.not-margin {
  margin-top: 0;
}
.box-document-wrapper .box-document {
  width: calc(100% - 20px);
  margin: 10px 10px;
}
@media (min-width: 768px) {
  .box-document-wrapper .box-document {
    width: calc(50% - 20px);
  }
}
@media (min-width: 992px) {
  .box-document-wrapper .box-document {
    width: calc(33.3333333333% - 20px);
  }
}
@media (min-width: 1230px) {
  .box-document-wrapper .box-document {
    width: calc(25% - 20px);
  }
}

.contracts {
  display: grid;
  grid-template-columns: repeat(auto-fit, 1fr);
  gap: 20px;
}
@media (min-width: 768px) {
  .contracts {
    grid-template-columns: repeat(auto-fit, minmax(320px, calc(50% - 10px)));
  }
}
.contracts__item {
  display: flex;
  flex-wrap: wrap;
}
.contracts__number {
  width: 100%;
  margin-bottom: 15px;
}
@media (min-width: 1230px) {
  .contracts__number {
    width: 33%;
    margin-bottom: 0;
  }
}
.contracts__number .paragraph {
  font-weight: 500;
}
.contracts__date-start, .contracts__date-end {
  width: 50%;
}
@media (min-width: 1230px) {
  .contracts__date-start, .contracts__date-end {
    width: 33%;
  }
}

.single-message {
  display: flex;
  flex-direction: column;
}
.single-message__header {
  position: relative;
  padding-left: 30px;
  margin-bottom: 10px;
}
@media (min-width: 768px) {
  .single-message__header {
    padding-left: 40px;
  }
}
.single-message__back-btn {
  position: absolute;
  top: 5px;
  left: 0;
  width: 17px;
  transform: scale(-1);
  display: flex;
  flex: none;
}
.single-message__back-btn img {
  width: 100%;
}
@media (min-width: 768px) {
  .single-message__back-btn {
    width: 20px;
    top: 8px;
  }
}
.single-message__title {
  font-size: 18px;
  font-weight: 500;
}
@media (min-width: 768px) {
  .single-message__title {
    font-size: 26px;
  }
}
.single-message__date {
  font-size: 14px;
  color: #CACACA;
}
.single-message .paragraph a {
  font-weight: 600;
}
.single-message .form-action,
.single-message .element-location {
  margin-top: 20px;
}

.profile-map-page {
  height: calc(100vh - 115px);
  width: 100%;
  position: relative;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
}
@media (min-width: 768px) {
  .profile-map-page {
    height: 100%;
    flex-direction: column;
  }
}

.profile-map-wrapper, .profile-map {
  width: 100%;
  height: 100%;
}

.profile-map-menu {
  width: 100%;
  padding: 15px;
  background: rgba(255, 255, 255, 0.9);
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  z-index: 5;
  display: flex;
  flex-direction: column;
}
@media (min-width: 768px) {
  .profile-map-menu {
    position: absolute;
    left: 10px;
    top: 0;
    max-width: 320px;
    height: 100%;
  }
}

.profile-map-search {
  display: flex;
  flex-direction: column;
}
.profile-map-search__title {
  font-size: 24px;
  margin-bottom: 10px;
}
@media (min-width: 992px) {
  .profile-map-search__title {
    font-size: 26px;
  }
}
.profile-map-search__description {
  font-size: 14px;
  margin-bottom: 5px;
}
.profile-map-search .btn {
  margin-top: 15px;
}

.booking-location {
  overflow: auto;
  height: 160px;
  flex: none;
}
@media (min-width: 768px) {
  .booking-location {
    height: 290px;
  }
}
.booking-location__items {
  display: flex;
  flex-direction: column;
  min-height: 200px;
}
.booking-location .booking-item + .booking-item {
  margin-top: 10px;
}

.recipe-wrapper {
  display: flex;
  flex-wrap: wrap;
}
.recipe-wrapper > * {
  width: 100%;
  margin: 5px;
}
@media (min-width: 576px) {
  .recipe-wrapper > * {
    width: calc(50% - 10px);
  }
}
@media (min-width: 992px) {
  .recipe-wrapper > * {
    width: calc(33.3333333333% - 10px);
  }
}
@media (min-width: 1230px) {
  .recipe-wrapper > * {
    width: calc(20% - 10px);
  }
}

.recipe-item {
  display: flex;
  flex-direction: column;
  gap: 5px;
}
.recipe-item__title {
  font-weight: 700;
}
.recipe-item img {
  width: 100%;
  aspect-ratio: 16/10;
  object-fit: cover;
}

.recipe-tabs {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: 15px;
  margin-bottom: 20px;
}
.recipe-tabs a:not(.active) {
  transition: text-shadow;
}
@media (hover: hover) {
  .recipe-tabs a:not(.active) {
    cursor: pointer;
  }
  .recipe-tabs a:not(.active):hover {
    text-shadow: 0.25px 0 0 black, -0.25px 0 0 black;
  }
}
.recipe-tabs .active {
  font-weight: 700;
}

.shop-section {
  margin-top: 50px;
  margin-bottom: 200px;
  display: flex;
  flex-direction: column;
  gap: 25px;
}
@media (max-width: 1440px) {
  .shop-section {
    margin-top: calc(30px + 20 * ((100vw - 375px) / 1065));
  }
}
@media (max-width: 1440px) {
  .shop-section {
    margin-bottom: calc(50px + 150 * ((100vw - 375px) / 1065));
  }
}
.shop-section__header {
  display: flex;
  flex-direction: column;
  gap: 10px;
}
@media (min-width: 768px) {
  .shop-section__header {
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-end;
  }
}
.shop-section__link {
  font-weight: 700;
  font-size: 14px;
}
.shop-section__items {
  column-gap: 50px;
  display: grid;
}
@media (max-width: 1440px) {
  .shop-section__items {
    column-gap: calc(10px + 40 * ((100vw - 375px) / 1065));
  }
}
@media (min-width: 768px) {
  .shop-section__items {
    grid-template-columns: repeat(2, 1fr);
  }
}
@media (min-width: 992px) {
  .shop-section__items {
    grid-template-columns: repeat(3, 1fr);
  }
}