.breadcrumbs {
  display: flex;
  align-items: center;
  font-size: 14px;
  line-height: 20px;
  margin: 20px -10px;

  @media (max-width: 992px) {
    display: none;
  }

  &__item {
    cursor: pointer;
    margin: 0 10px;
    position: relative;

    &::after {
      content: '';
      position: absolute;
      right: -10px;
      top: 50%;
      transform: translate(50%, -50%);
      width: 4px;
      height: 4px;
      background: $cloudy;
      border-radius: 50%;
    }

    &:last-child::after {
      content: none;
    }

    a {
      color: $cloudy;
      transition: color 404ms linear;

      &:hover {
        color: $black;
      }
    }
  }
}
