.info-items {
  display: flex;
  flex-wrap: wrap;
  margin: 0 -15px;
  justify-content: space-between;

  .img-text-col {
    flex: 1 1 300px;
    margin: 0 15px 20px;

    @media ($desktop) {
      margin: 10px 15px;
      max-width: 300px;
    }
  }
}
