.content-block-3-wrapper {
  display: flex;
  flex-wrap: wrap;

  .content-block-3 {
    width: 100%;
    position: relative;

    .item-text:last-child {
      margin-bottom: 0;
    }

    &:not(:last-child) {
      padding-bottom: 20px;
      margin-bottom: 20px;

      &::after {
        content: '';
        position: absolute;
        width: 100%;
        height: 1px;
        bottom: 0;
        background: $silver;
      }
    }

    @media ($tablet) {
      width: 50%;
      padding-right: 15px;
    }
  }
}
