.locations {
  display: flex;
  flex-direction: column;
  margin-top: 15px;
  flex-grow: 1;
  overflow: hidden;
  position: relative;

  @media ($tablet) {
    margin-top: 30px;
  }

  &__title {
    font-size: 14px;
    margin-bottom: 10px;
  }

  &__item {
    margin: 7px -7px;
    position: relative;

    &::after {
      content: '';
      position: absolute;
      top: -7px;
      width: calc(100% - 14px);
      left: 7px;
      height: 1px;
      background: $mercury;
    }
  }

  &__item + &__item {
    margin-top: 14px;
  }

  &__items {
    overflow-y: auto;
    height: 100%;
  }
}

.location-profile {
  display: flex;
  border-radius: 5px;
  padding: 10px;
  transition: background 404ms linear;

  &:hover {
    background: $pampas;
  }

  &__wrapper {
    display: flex;
    flex-direction: column;
    font-size: 14px;
  }

  &__logo {
    width: 50px;
    display: flex;
    margin-right: 10px;
  }

  &__city {
    font-weight: 600;
    margin-bottom: 4px;
  }
}

.booking-wrapper {
  width: 100%;
  height: auto;
  background: $white;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  z-index: 1;
  opacity: 0;
  pointer-events: none;
  visibility: hidden;
  transition: opacity 404ms linear;
  display: none;

  @media ($tablet) {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
  }

  &.show {
    opacity: 1;
    pointer-events: auto;
    visibility: visible;
    display: flex;
  }

  &__text {
    margin-bottom: 15px;
    text-align: center;
    font-size: 12px;
    padding: 0 15px;
    font-weight: 500;
  }

  &__btn {
    background: $bracken;
    color: $white;
    border-radius: 20px 5px;
    line-height: 36px;
    padding: 0 20px;
    font-size: 14px;
    font-weight: 500;
    cursor: pointer;
    transition: background 404ms linear;

    &:hover {
      background: $tumbleweed;
    }
  }
}

.booking-info-message,
.booking-can-not {
  margin: auto;

  &__text {
    font-size: 18px;
    font-weight: 600;
    text-align: center;
  }
}

.nearest-locations {
  display: none;

  &.show {
    display: flex;
    flex-direction: column;
    height: 100%;
  }

  &__list {
    overflow: auto;
  }

  .booking-item {
    margin-top: 14px;
    position: relative;

    &::after {
      content: '';
      position: absolute;
      width: 100%;
      height: 1px;
      top: -7px;
      transform: translateY(-50%);
      background: $mercury;
    }
  }
}
