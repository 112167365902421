.block-shops {
  display: flex;
  flex-direction: column-reverse;

  @media ($tablet) {
    flex-direction: row;
    align-items: center;

    &::after {
      background-size: cover;
      position: absolute;
      top: 10%;
      width: 900px;
      z-index: -2;
      left: -80%;
    }

    > div {
      width: 50%;
    }
  }

  &__content {
    padding: 30px 0 0 0;
    position: relative;

    @media ($tablet) {
      padding: 10% 30px 10% 0;
    }

    &::after {
      content: '';
      background: url("/images/bg/home-banner.svg") no-repeat center;
      background-size: cover;
      position: absolute;
      top: 0;
      right: 0;
      height: calc(100% + 30px);
      width: calc(100% + 30px);
      z-index: -2;
      transform: scaleX(-1);

      @media ($tablet) {
        background-size: cover;
        position: absolute;
        height: 100%;
        width: 900px;
        z-index: -2;
        right: -10%;
      }
    }
  }

  &__map {
    height: 250px;
    position: relative;
    margin-left: 15px;

    @media ($tablet) {
      margin-left: 0;
      height: 420px;
      flex-direction: row;
      align-items: center;
    }

    .map-locations {
      overflow: hidden;
      border-radius: 10px 50px;

      @media ($desktop) {
        border-radius: 10px 125px;
      }
    }

    &::after {
      content: '';
      position: absolute;
      width: 100%;
      height: 100%;
      right: 15px;
      bottom: -15px;
      border: 2px dashed $tumbleweed;
      z-index: -1;
      border-radius: 0 50px;

      @media ($desktop) {
        right: 15px;
        border-radius: 0 125px;
      }
    }
  }

  &__title {
    margin-bottom: 10px;
  }

  &__description {
    font-size: 14px;
    margin-bottom: 20px;
  }

  &__actions {
    display: flex;
    align-items: center;
    margin: 20px -9px 0;

    @media ($tablet) {
      margin: 0 -14px;
    }

    .btn {
      flex-grow: 1;
      max-width: 230px;
    }

    .action {
      margin: 0 9px;
      font-weight: 500;

      @media ($tablet) {
        margin: 0 14px;
      }
    }
  }
}
