.file-upload {
  display: flex;
  gap: 14px;

  input {
    display: none;
  }

  [role="list"] {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 14px;

    li {
      &::marker {
        content: none;
      }
    }
  }

  .preview:empty {
    display: none;
  }
}

.file-upload-wrapper {
  position: relative;
  display: grid;

  [role="alert"] {
    bottom: 6px;
    left: 0;
    top: auto;
  }
}
