.shop-section {
  @include adaptive-value('margin-top', 50, 30, 1);
  @include adaptive-value('margin-bottom', 200, 50, 1);
  display: flex;
  flex-direction: column;
  gap: 25px;

  &__header {
    display: flex;
    flex-direction: column;
    gap: 10px;

    @include breakpoint('md') {
      flex-direction: row;
      justify-content: space-between;
      align-items: flex-end;
    }
  }

  &__link {
    font-weight: 700;
    font-size: 14px;
  }

  &__items {
    @include adaptive-value('column-gap', 50, 10, 1);
    display: grid;

    @include breakpoint('md') {
      grid-template-columns: repeat(2, 1fr);
    }

    @include breakpoint('lg') {
      grid-template-columns: repeat(3, 1fr);
    }
  }
}
