.shop-item {
  display: flex;
  flex-direction: column;
  background: white;
  border-radius: 10px;

  @media (hover: hover) {
    cursor: pointer;

    &:hover & {
      &__picture img {
        transform: scale(1.1) rotate(-2deg);
      }
    }
  }

  &__picture {
    height: 210px;
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 10px 10px 0 0;

    @media ($tablet) {
      height: 180px;
    }

    img {
      object-fit: cover;
      height: 100%;
      width: 100%;
      transition: transform 404ms linear;
    }
  }

  &__description {
    padding: 10px;
    font-size: 14px;
    display: flex;
    flex-direction: column;
    flex-grow: 1;
  }

  &__name {
    font-weight: 500;
    margin-bottom: auto;
  }

  &__info {
    margin-top: 8px;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  &__price {
    font-size: 18px;
    font-weight: 500;
  }
}
