.block-menu {
  display: flex;
  flex-direction: column;

  @media ($desktop) {
    padding-top: 40px;
    padding-bottom: 30px;
    position: relative;

    &::before,
    &::after {
      content: '';
      position: absolute;
      top: 0;
      width: calc(100% + 140px);
      height: 100%;
      border-radius: 0 150px;
    }

    &::after {
      left: -70px;
      background: rgba($white, .8);
      z-index: -1;
    }

    &::before {
      border: 2px dashed $tumbleweed;
      left: -90px;
      top: 20px;
      z-index: -2;
    }
  }

  &__title {
    margin-bottom: 10px;
  }

  &__description {
    font-size: 14px;

    a {
      font-weight: bold;
    }
  }

  &__items {
    margin: 20px -10px 0;
    display: flex;
    flex-wrap: wrap;

    .menu-item {
      width: calc(50% - 20px);
      margin-left: 10px;
      margin-right: 10px;

      @media ($desktop) {
        width: calc(25% - 20px);
      }
    }
  }

  &__action {
    display: flex;
    justify-content: center;

    .btn {
      max-width: 200px;
    }
  }
}
