input[type='email'],
input[type='phone'],
input[type='number'],
input[type='password'],
input[type='search'],
textarea,
input[type='text'] {
  background: $white;
  border-radius: 5px;
  padding: 17px 12px;
  min-height: 50px;
  font-size: 14px;
  border: 1px solid rgba(79, 43, 4, 0.2);
  width: 100%;
  outline: none;
  transition: border 404ms linear;

  &:focus {
    border: 1px solid rgba(79, 43, 4, 0.4);
  }

  &.is-invalid {
    border: 1px solid $red;
    background: #FEF7F7;
  }

  & + label {
    font-size: 14px;
    color: rgba($black, .8);
    position: absolute;
    left: 12px;
    bottom: calc(100% - 25px);
    transform: translateY(50%);
    transition: 303ms linear;
    z-index: 1;
    cursor: text;
  }

  &:required + label {
    &::after {
      content: '*';
      position: absolute;
      top: 0;
      right: -6px;
      color: $red;
    }
  }

  &.has-value + label,
  &:focus + label {
    font-size: 12px;
    bottom: 100%;
    left: 5px;
    transform: translateY(50%);
    color: $black;
    transition: 303ms linear;
    background: white;
    width: auto;
    border-radius: 5px;
    padding: 2px 4px;
  }
}

textarea {
  resize: none;
  height: 120px;
}

input[type='search'] {
  background: $white url('/images/icons/search-icon.svg') no-repeat calc(100% - 15px);
}

input[type='number'] {
  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
    appearance: none;
    margin: 0;
  }
}
