.instagram {
  &__title {
    margin-bottom: 10px;
  }

  &__items {
    display: flex;
    flex-wrap: wrap;
    margin: 0 -5px;
  }

  &__item {
    width: calc(50% - 10px);
    margin: 0 5px 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 170px;
    overflow: hidden;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }

    @media ($tablet) {
      height: 230px;
      width: calc((100% / 3) - 10px);
    }

    @media ($desktop) {
      width: calc((100% / 4) - 10px);
    }

    @media ($large) {
      width: calc((100% / 5) - 10px);
    }
  }
}
