.social-link {
  .footer__title {
    display: flex;
    justify-content: center;
  }

  &__items {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 25px;
  }
}
