.social-item {
  width: 36px;
  height: 36px;
  display: flex;
  align-items: center;
  justify-content: center;

  svg path {
    transition: all 404ms linear;
  }

  &:hover {
    svg path {
      fill: $tumbleweed;
    }
  }
}
