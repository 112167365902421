.my-shop-item {
  width: 100%;

  .form-header {
    justify-content: end;
  }

  .form-body {
    display: flex;
    position: relative;
  }

  &.not-active {
    .form-body {
      opacity: .7;
      mix-blend-mode: luminosity;
      cursor: not-allowed;
    }
  }

  .phones {
    margin-top: 5px;
  }

  &__labels {
    position: absolute;
    left: 10px;
    top: 5px;
    height: 20px;
    display: flex;
    align-items: center;
  }

  &__status {
    font-size: 14px;
    font-weight: 500;
    line-height: 1;
    padding-top: 2px;

    &.active {
      color: $green;
    }
  }

  &__delivery {
    position: relative;
    width: 20px;
    height: 20px;
    margin-right: 5px;

    &::after {
      content: '';
      position: absolute;
      left: 0;
      top: 0;
      width: 20px;
      height: 20px;
      border-radius: 50%;
      overflow: hidden;
      background: $tumbleweed url('/images/icons/cart-white.svg') no-repeat 40%;
    }
  }

  &__logo {
    width: 75px;
    display: flex;
    flex-direction: column;
    margin-right: 10px;
    flex: none;
    margin-bottom: 15px;

    @media ($desktop) {
      margin-right: 15px;
      margin-bottom: 0;
    }
  }

  &__content {
    flex-grow: 1;
    display: flex;
    flex-wrap: wrap;
    font-size: 14px;
    gap: 15px;

    @media ($large) {
      flex-wrap: wrap;
    }
  }

  &__info {
    width: 50%;
  }


}

.my-shop-wrapper {
  display: flex;
  flex-wrap: wrap;
  row-gap: 20px;

  @media ($tablet) {
    margin: 0 -10px;
  }

  .my-shop-item {
    @media ($tablet) {
      width: calc(50% - 20px);
      margin: 0 10px;
    }
  }
}
