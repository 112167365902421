.content-map {
  display: grid;
  gap: 20px;

  @include breakpoint('md') {
    grid-template-columns: repeat(2, 1fr);
  }

  &__title {
    @include adaptive-value('font-size', 36, 24, 1);
    line-height: 1;
  }

  &__map {
    display: flex;
    flex-direction: column;
    aspect-ratio: 1.8;
    border-radius: 10px;
    background-color: $bone;
    overflow: hidden;

    .block-map {
      width: 100%;
      height: 100%;
    }
  }

  &__content {
    display: flex;
    flex-direction: column;
    gap: 10px;

    @include breakpoint('md') {
      @include adaptive-value('padding-top', 30, 0, 1);
      order: -1;
    }
  }

  &__description {
    font-size: 14px;
    max-width: 370px;
  }

  &__actions {
    @include adaptive-value('margin-top', 15, 10, 1);
    display: flex;
    flex-direction: column;

    @include breakpoint('md') {
      align-items: flex-start;
    }

    .btn {
      width: auto;
      padding-inline: 70px;
    }
  }
}
