.btn {
  @extend .unselect;
  line-height: 50px;
  font-size: 14px;
  font-weight: 500;
  transition: background 404ms linear;
  display: flex;
  justify-content: center;
  padding: 0 5px;
  border: none;
  width: 100%;
  cursor: pointer;

  &__border {
    border-radius: 20px 5px;
    box-shadow: 0 0 0 2px $bracken;
    background: $white;
    transition: background, color, opacity 404ms linear;

    &.active {
      background: $bracken;
      color: $white;
    }

    &:hover {
      opacity: .8;
    }
  }

  &__brown {
    background: $bracken;
    color: $white;
    border-radius: 20px 5px;

    &:hover {
      background: $tumbleweed;
    }

    &[disabled] {
      pointer-events: none;
      opacity: 0.3;
    }
  }
}

.current-location {
  @extend .unselect;

  background: transparent;
  outline: none;
  border: none;
  font-size: 14px;
  line-height: 1;
  color: $bracken;
  padding: 0;
  font-weight: 500;
  text-decoration: underline;
  text-underline-offset: 2px;
  position: relative;

  &.empty {
    cursor: not-allowed;
    padding-left: 20px;
    color: $bracken;

    &::before {
      content: '';
    }
  }

  &::before {
    content: none;
    background: url('/images/icons/messages/circle-warning.svg') no-repeat center;
    background-size: contain;
    position: absolute;
    left: 0;
    top: 50%;
    transform: translateY(-50%);
    width: 18px;
    aspect-ratio: 1;
  }

  &::after {
    content: "";
    position: absolute;
    left: 50%;
    top: 50%;
    opacity: 0;
    visibility: hidden;
    transition: opacity 404ms;
    transform: translate(-50%,-50%) rotate(0);
    transition-property: transform;
    animation-name: rotateSpinner;
    animation-duration: 1.2s;
    animation-iteration-count: infinite;
    animation-timing-function: linear;
    width: 20px;
    height: 20px;
    border: 2px solid $bracken;
    border-top: 4px solid transparent;
    border-radius: 50%;
    z-index: 2;
  }

  &.spinner {
    pointer-events: none;
    color: $gray;

    &::after {
      opacity: 1;
      visibility: visible;
    }
  }

  &:not(.empty)[disabled] {
    cursor: not-allowed;
    color: $gray;
  }

  @media (hover: hover) {
    cursor: pointer;
    transition: color 404ms ease-in-out;

    &:not(&[disabled]):hover {
      color: $tumbleweed;
    }
  }
}

@keyframes rotateSpinner {
  0% {
    transform: translate(-50%, -50%) rotate(0)
  }
  to {
    transform: translate(-50%, -50%) rotate(360deg)
  }
}
