.profile-companies {
  font-size: 16px;
  font-weight: 500;

  @media ($large-handset) {
    margin-right: auto;
  }

  &__active {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    z-index: 1;
    cursor: pointer;

    @media ($large-handset) {
      position: relative;
      transform: none;
      left: 0;
      top: 0;
    }

    &.multiple::after {
      content: '';
      position: absolute;
      background: url("/images/icons/arrow-profile-companies.svg") no-repeat center;
      display: flex;
      align-items: center;
      left: calc(100% + 5px);
      top: 50%;
      transform: translateY(-50%);
      width: 10px;
      height: 7px;
    }

    &.active {
      &.multiple::after {
        transform: translateY(-50%) scale(-1);
      }
    }
  }

  @media ($tablet) {
    position: relative;
  }

  &__items {
    position: absolute;
    top: 100%;
    left: 0;
    width: 100%;
    background-color: $white;
    text-align: center;
    padding: 25px 15px;
    box-shadow: inset 0 1px 15px rgb(0 0 0 / 10%), 0 10px 15px rgba(0, 0, 0, 0.1);
    opacity: 0;
    pointer-events: none;
    visibility: hidden;
    transition: opacity 404ms linear;
    display: flex;
    flex-direction: column;
    align-items: center;

    @media ($tablet) {
      width: 250px;
      align-items: flex-start;
      left: -15px;
      margin-top: 15px;
    }

    &.show {
      opacity: 1;
      pointer-events: all;
      visibility: visible;
    }
  }

  &__item + &__item {
    margin-top: 20px;
  }

  &__item {
    cursor: pointer;
  }
}
