.uploaded-files-container {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(100px, 1fr));
  gap: 10px;

  .uploaded-files-item {
    display: flex;
    position: relative;

    img {
      aspect-ratio: 16/9;
      object-fit: contain;
      filter: brightness(1);
      transition: filter 404ms;
    }

    .delete {
      width: 20px;
      height: 20px;
      display: flex;
      position: absolute;
      right: 5px;
      border-radius: 5px;
      top: 5px;
      background: url("/images/icons/icon-delete.svg") no-repeat center, $white;
      background-size: 70%, auto;
      cursor: pointer;

      @media (hover: hover) {
        transform: scale(.5);
        opacity: .5;
        transition: transform 404ms, opacity 404ms;
      }
    }

    @media (hover: hover) {
      &:hover {

        img {
          filter: brightness(0.3);
        }

        .delete {
          transform: scale(1);
          opacity: 1;
        }
      }
    }
  }
}
