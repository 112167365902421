.blog-page {
  display: flex;
  flex-direction: column;

  &__items {
    display: flex;
    flex-wrap: wrap;

    @media ($large-handset) {
      margin: 0 -10px;
    }


    .blog-item {
      width: 100%;
      margin-bottom: 30px;

      @media ($large-handset) {
        width: calc(50% - 20px);
        margin: 0 10px 30px;
      }

      @media ($desktop) {
        width: calc((100% / 3) - 20px);
        margin-bottom: 50px;
      }
    }
  }
}
