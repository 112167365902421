.search-block {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 20px;

  &__search,
  &__select {
    flex-grow: 1;
  }

  &__search {
    max-width: 480px;
  }

  &__select {
    max-width: 200px;
  }
}
