.burger {
  width: 22px;
  height: 19px;
  cursor: pointer;
  z-index: 30;
  transition: transform 1s ease-in;
  position: relative;
  display: flex;
  justify-content: flex-end;

  @media ($desktop) {
    display: none;
  }

  span {
    display: block;
    position: absolute;
    height: 2px;
    right: 0;
    background: $black;
    border-radius: 2px;
    opacity: 1;
    transform: rotate(0deg);
    transition: .25s ease-in-out;

    &:nth-child(1) {
      top: 0;
      width: 100%;
    }

    &:nth-child(2) {
      top: 9px;
      width: 75%;
    }

    &:nth-child(3) {
      top: 17px;
      width: 50%;
    }
  }

  &.active span {
    &:nth-child(1) {
      top: 8px;
      transform: rotate(-135deg);
    }

    &:nth-child(2) {
      opacity: 0;
    }

    &:nth-child(3) {
      top: 8px;
      width: 100%;
      transform: rotate(135deg);
    }

  }
}
