.product-form {
  display: flex;
  flex-wrap: wrap;

  > div {
    @media ($tablet) {
      width: 50%;
    }
  }

  &__picture {
    border-radius: 10px;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    margin-bottom: 20px;
    height: 190px;
    align-items: center;
    justify-content: center;

    @media ($tablet) {
      margin-bottom: 0;
      height: auto;
      justify-content: flex-start;
    }

    img {
      width: 100%;
      height: auto;
      object-fit: cover;
    }
  }

  &__form {
    @media ($tablet) {
      padding-left: 15px;
    }

    .form-group {
      margin-bottom: 20px;
    }
  }

  &__price {
    display: flex;
    align-items: center;
    margin-top: 10px;

    input {
      max-width: 160px;
      font-size: 26px;
      font-weight: 500;
      padding-top: 10px;
      padding-bottom: 10px;
    }
  }

  &__price-name {
    margin-left: 10px;
    font-weight: 500;

  }
}
