.why-galya {
  display: flex;
  flex-direction: column;

  @media ($desktop) {
    flex-direction: row;
    align-items: flex-start;
  }

  &__content {
    padding-top: 20px;

    @media ($desktop) {
      padding-left: 40px;
      padding-top: 30px;
      padding-bottom: 30px;
      position: relative;

      &::after {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        z-index: -1;
        background: url('/images/bg/why-galya.svg') no-repeat left;
        height: 100%;
        width: calc(100% + 15px);
      }
    }

    @media ($large) {
      &::after {
        background-size: contain;
      }
    }
  }

  .img-text-row {
    margin-top: 20px;

    &__content {
      padding-left: 15px;
    }
  }

  &__image {
    display: flex;
    flex-direction: column;
    position: relative;
    padding: 0 20px 20px 0;
    max-width: 490px;
    width: 100%;
    margin: 0 auto;

    &::after {
      content: '';
      position: absolute;
      width: calc(100% - 20px);
      height: calc(100% - 20px);
      right: 0;
      bottom: 0;
      border: 2px dashed $tumbleweed;
      z-index: -1;
      border-radius: 0 50px;

      @media ($desktop) {
        border-radius: 0 125px;
      }
    }

    .photo {
      display: flex;
      border-radius: 0 50px;
      overflow: hidden;

      @media ($desktop) {
        border-radius: 0 125px;
      }
    }

    .small-photo {
      position: absolute;
      right: -5px;
      bottom: -15px;
      width: 67%;
    }
  }
}
