.user-block,
.cart {
  margin: 0 15px;
  display: flex;
  align-items: center;
  cursor: pointer;
  padding: 3px 7px;
  transition: background 404ms linear;
  border-radius: 5px;

  @media ($desktop) {
    margin: 0 20px 0 0;
  }

  &__icon {
    display: flex;
    align-items: center;
    margin-right: 8px;
    border-radius: 5px;
  }

  &__label {
    font-weight: 500;
  }

  &:hover {
    background: $pampas;
  }
}

.cart {
  &__icon {
    position: relative;

    &::after {
      content: attr(data-count);
      position: absolute;
      top: 0;
      right: 0;
      background: $bracken;
      font-size: 8px;
      color: $white;
      border-radius: 2px;
      padding: 0 2px;
      transform: translateX(50%);
    }
  }
}
