.footer {
  @include adaptive-value('padding-top', 60, 40, 1);
  @include adaptive-value('padding-bottom', 50, 30, 1);
  width: 100vw;
  margin-inline: 50%;
  transform: translateX(-50%);
  background: $bgGradient;

  &__wrapper {
    @include adaptive-value('gap', 150, 40, 1);
    display: grid;

    @include breakpoint('md') {
      grid-template-columns: repeat(2, 1fr);
    }

    @include breakpoint('lg') {
      grid-template-columns: auto auto 1fr auto;
    }
  }

  &__title {
    font-size: 20px;
    line-height: 1;
    font-weight: bold;
  }

  &__item {
    display: flex;
    flex-direction: column;
    gap: 15px;

    @include breakpoint('lg') {
      max-width: 230px;
    }
  }

  &__nav {
    display: flex;
    flex-direction: column;
    gap: 10px;

    span {
      justify-content: flex-start;
    }

    a.active {
      background-size: 100% 1px;
      background-position-x: left;
    }
  }

  .social-link {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;

    @include breakpoint('md') {
      grid-column: span 2;
    }

    @include breakpoint('lg') {
      grid-column: span 1;
      grid-column-end: -1;
    }

    &__title {
      margin-bottom: 20px;
    }

    .btn {
      margin-top: 30px;
      width: auto;
      padding-inline: 40px;
    }
  }
}
