.our-stores {
  height: calc(100vh + 150px);
  width: calc(100% + 30px);
  overflow: hidden;
  position: relative;
  left: -15px;

  @media ($desktop) {
    height: calc(100vh - 82px);
    overflow: visible;
  }

  .our-stores-map {
    width: 100%;
    margin-top: 150px;
    height: calc(100% + 170px);

    @include breakpoint('md') {
      margin-top: 0;
    }

    @include breakpoint('lg') {
      height: 100%;
      width: calc(100vw + 150px);
    }

    @media ($large) {
      left: calc(600px - 50vw)
    }
  }

  .position-count-label {
    padding-bottom: 9px;
  }

  .search-shop-block {
    position: absolute;
    top: 0;
    width: 100%;

    @include breakpoint('md') {
      left: 50%;
      top: 15px;
      transform: translateX(-50%);
      width: calc(100% - 30px);
      max-width: 550px;
    }

    @include breakpoint('lg') {
      left: 15px;
      top: 50%;
      transform: translateY(-50%);
    }

    .current-location {
      @include breakpoint('lg') {
        margin-block: 10px;
      }
    }
  }

  &__contents {
    margin-top: 20px;
    display: grid;
  }

  &__tab {
    display: grid;
    grid-template-rows: 0fr;
    opacity: 0;
    filter: blur(3px);
    transition: grid-template-rows 0.5s ease-out, opacity 0.5s ease-out, filter 0.5s ease-out;

    & > div {
      overflow: hidden;
    }

    .component {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      gap: 15px;
    }

    &.show {
      grid-template-rows: 1fr;
      opacity: 1;
      filter: blur(0);

      & > div {
        animation-name: isHidden;
        animation-fill-mode: forwards;
        animation-timing-function: linear;
        animation-duration: 404ms;
      }
    }
  }
}

@keyframes isHidden {
  0%, 99% {
    overflow: hidden;
  }
  100% {
    overflow: visible;
  }
}
