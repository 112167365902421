.form-auth {
  display: flex;
  flex-direction: column;

  @media ($tablet) {
    flex-direction: row-reverse;
  }

  &__form-wrapper {
    padding: 20px 0 70px;

    @media ($tablet) {
      width: 50%;
      padding: 60px 0;
      min-height: 690px;
      display: flex;
      flex-direction: column;
      justify-content: center;
    }
  }

  &__picture {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin: 0 -15px;
    overflow: hidden;

    @media ($tablet) {
      width: calc(50% + 15px);
      margin: 0 -15px 0 0;

      img {
        height: 100%;
        object-fit: cover;
      }
    }

    @media ($large) {
      width: 50vw;
      margin-right: calc(600px - 50vw);
    }
  }

  &__form {
    @media ($tablet) {
      padding-right: 20px;
    }

    @media ($desktop) {
      padding-right: 25%;
    }
  }

  &__title {
    margin-bottom: 20px;
  }

  &__sub-description {
    font-size: 14px;
    margin-bottom: 20px;

    @media ($desktop) {
      padding-right: 25%;
    }
  }

  &__description {
    font-size: 14px;
    margin-top: 20px;

    @media ($desktop) {
      padding-right: 25%;
    }

    a {
      font-weight: 500;
    }
  }

  &__actions {
    display: flex;
    align-items: center;
    margin: 20px -9px 0;

    @media ($tablet) {
      margin: 20px -15px 0;
    }

    .btn {
      flex-grow: 1;
    }

    .action {
      margin: 0 9px;
      white-space: nowrap;
      font-size: 14px;
      font-weight: 500;

      @media ($tablet) {
        margin: 0 15px;
      }
    }
  }
}
