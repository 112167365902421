.form-group {
  display: flex;
  flex-direction: column;
  margin-bottom: 25px;
  position: relative;

  label {
    cursor: pointer;
  }

  &.hidden {
    opacity: 0;
    visibility: hidden;
  }

  .label {
    font-size: 12px;
    line-height: 1.5;
    margin-bottom: 4px;
  }

  &:has(textarea[required]),
  &:has(input[required]) {
    .label {
      &::after {
        content: '*';
        color: $red;
      }
    }
  }
}

.form__text {
  margin: 30px 0 20px 0;
}

.form__accordion__content {
  height: 0;
  overflow: hidden;
  transition: 0.5s;
}

.check-telegram {
  display: flex;
  align-items: center;
  gap: 15px;

  .btn {
    width: auto;
    min-width: 150px;
    margin-left: auto;
    opacity: 0;
    visibility: hidden;
    pointer-events: none;
    transition: opacity 404ms linear;
    line-height: 30px;

    &.show {
      opacity: 1;
      visibility: visible;
      pointer-events: auto;
    }
  }
}


