.content-video {
  @media ($tablet) {
    display: flex;
    flex-direction: row-reverse;
  }

  &__content {
    @media ($tablet) {
      padding-top: 10px;
      padding-bottom: 10px;
      padding-right: 4%;
      width: 48%;
    }
  }

  &__video {
    display: flex;
    margin-bottom: 20px;

    @media ($tablet) {
      margin-bottom: 0;
      width: 52%;

    }

    .youtube-item {
      width: 100%;
      overflow: hidden;
      aspect-ratio: 16/9;

      iframe {
        width: 100%;
        height: 100%;
      }
    }
  }

  &__title {
    margin-bottom: 10px;
  }

  &__description {
    font-size: 14px;
  }

  &__actions {
    display: flex;
    align-items: center;
    margin: 20px -9px 0;

    @media ($tablet) {
      margin: 20px -14px 0;
    }

    .btn {
      flex-grow: 1;
      max-width: 200px;
    }

    .action {
      margin: 0 9px;
      font-weight: 500;

      @media ($tablet) {
        margin: 0 14px;
      }
    }
  }
}
