.switcher {
  display: none;
  flex-direction: column;
  justify-content: center;
  position: relative;
  margin-left: 25px;
  font-size: 16px;
  font-weight: 500;
  text-transform: uppercase;
  cursor: pointer;
  min-width: 30px;
  text-align: center;

  @media ($desktop) {
    display: flex;
  }

  &__current {
    padding: 6px 0 4px;
    line-height: 1;
  }

  &::after {
    content: '';
    position: absolute;
    top: 50%;
    left: -25px;
    transform: translateY(-50%);
    background: url('/images/icons/lang.svg') no-repeat center;
    width: 20px;
    height: 20px;
  }

  .drop {
    position: absolute;
    top: 100%;
    width: 100%;
    opacity: 0;
    pointer-events: none;
    visibility: hidden;
    transition: opacity 404ms linear;

    li {
      transition: background 404ms linear;
    }

    li:hover,
    .active {
      background: $pampas;
      border-radius: 5px;
    }
  }

  &.show {
    box-shadow: 0 0 4px rgba(0, 0, 0, 0.1);
    border-radius: 5px 5px 0 0;

    .drop {
      opacity: 1;
      pointer-events: auto;
      visibility: visible;
      box-shadow: 0 3px 4px 0 rgb(0 0 0 / 10%);
      border-radius: 0 0 5px 5px;
      background: white;
    }
  }
}
