.recipe-wrapper {
  display: flex;
  flex-wrap: wrap;

  & > * {
    width: 100%;
    margin: 5px;

    @media ($large-handset) {
      width: calc((100% / 2 ) - 10px);
    }

    @media ($desktop) {
      width: calc((100% / 3 ) - 10px);
    }

    @media ($large) {
      width: calc((100% / 5 ) - 10px);
    }
  }
}

.recipe-item {
  display: flex;
  flex-direction: column;
  gap: 5px;

  &__title {
    font-weight: 700;
  }

  img {
    width: 100%;
    aspect-ratio: 16/10;
    object-fit: cover;
  }
}

.recipe-tabs {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: 15px;
  margin-bottom: 20px;

  a:not(.active) {
    transition: text-shadow;

    @media (hover: hover) {
      cursor: pointer;

      &:hover {
        text-shadow: .25px 0 0 black, -.25px 0 0 black;
      }
    }
  }

  .active {
    font-weight: 700;
  }
}
