.statistics-profile-item {
  display: flex;
  flex-direction: column;
  padding: 35px 10px 10px;
  border-radius: 10px;
  background: $white;
  box-shadow: 0 0 10px rgba($black, .1);
  position: relative;
  text-align: center;

  @media ($desktop) {
    padding: 50px 10px 20px;
  }

  &__picture {
    position: absolute;
    top: 0;
    left: 0;
    width: 48px;
    height: 30px;
    border-radius: 10px;
    background: $tumbleweed;
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: center;

    @media ($desktop) {
      width: 70px;
      height: 42px;
    }
  }

  &__number {
    font-size: 24px;
    line-height: 1;
    font-weight: 500;
    margin-bottom: 5px;

    @media ($desktop) {
      font-size: 26px;
    }
  }

  &__title {
    font-size: 14px;
    line-height: 1.2;
    font-weight: 500;

    @media ($desktop) {
      font-size: 18px;
      line-height: 1;
      margin-bottom: 8px;
    }
  }

  &__status {
    font-size: 14px;
    line-height: 1;
    display: none;
    color: $green;

    @media ($desktop) {
      display: block;
    }

    &.gray {
      color: $silver;
    }

    &.red {
      color: $red;
    }
    &.orange {
      color: $orange;
    }
  }
}
