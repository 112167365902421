.cart-item {
  border-radius: 10px;
  background: $white;
  display: flex;
  position: relative;

  &__picture {
    width: 160px;
    display: flex;
    flex-direction: column;
    flex: none;
    border-radius: 10px 0 0 10px;
    overflow: hidden;

    img {
      height: 100%;
      object-fit: cover;
    }

    @media ($tablet) {
      width: 180px;
    }
  }

  &__content {
    padding: 8px 10px;
    width: 100%;
    display: flex;
    flex-direction: column;
  }

  &__title {
    font-weight: 500;
    font-size: 14px;
    line-height: 18px;
  }

  &__gram {
    font-size: 12px;
    margin: 8px 0;
  }

  &__actions {
    margin-top: auto;
    display: flex;
    align-items: center;

    .counter {
      margin-right: auto;
    }
  }

  &__price {
    font-size: 14px;
    font-weight: 500;

    span {
      margin-right: 4px;
    }
  }

  &__close {
    width: 16px;
    height: 16px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    overflow: hidden;
    position: absolute;
    right: 0;
    top: 0;
    background: #DDDDDD url('/images/icons/close-item-cart.svg') no-repeat center;
    transform: translateY(-5px);
    cursor: pointer;
    transition: opacity 404ms linear;
    opacity: 1;

    &:hover {
      opacity: .7;
    }
  }
}
