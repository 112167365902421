.box-document-wrapper {
  display: flex;
  flex-wrap: wrap;
  margin: 30px -10px 0;

  &.not-margin {
    margin-top: 0;
  }

  .box-document {
    width: calc(100% - 20px);
    margin: 10px 10px;

    @media ($tablet) {
      width: calc(50% - 20px);
    }

    @media ($desktop) {
      width: calc((100% / 3) - 20px);
    }

    @media ($large) {
      width: calc((100% / 4) - 20px);
    }
  }
}
