.domain-banner {
  @include adaptive-value('padding-top', 200, 150, 1);
  @include adaptive-value('padding-bottom', 110, 80, 1);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
  border-radius: 60px 10px;
  overflow: hidden;
  margin-top: 15px;

  @include breakpoint('lg') {
    border-radius: 150px 10px;
  }

  @include breakpoint('xl') {
    margin-inline: -70px;
  }

  &__picture {
    position: absolute;
    inset: 0;
    border-radius: inherit;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }

    &::after {
      content: '';
      position: absolute;
      inset: 0;
      background-color: rgb($black, .5);
    }
  }

  &__main {
    @include adaptive-value('gap', 70, 30, 1);
    isolation: isolate;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    .btn {
      width: auto;
      min-width: 200px;
    }
  }

  &__header {
    text-align: center;
    color: $white;

    h1 {
      display: flex;
      flex-direction: column;
    }
  }
}
