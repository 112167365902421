.booking-item {
  display: flex;
  flex-direction: column;
  background: $pampas;
  padding: 8px 10px;
  border-radius: 5px;
  overflow: hidden;

  &--not-bg {
    padding: 0;
    background: transparent;
    overflow: visible;

    .booking-item__main {
      margin: 0;
    }
  }

  &__header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 12px;
    margin-bottom: 10px;
  }

  &__btn {
    font-weight: 500;
    padding: 0 10px;
    line-height: 20px;
    border-radius: 5px;
    background: $bone;
  }

  &__info {
    line-height: 20px;
  }

  &__main {
    display: flex;
    font-size: 14px;
    cursor: pointer;
    transition: background 404ms linear;
    border-radius: 5px;
    padding: 4px;
    margin: 0 -4px;

    &.active,
    &:hover {
      background: #ded9d2;
    }

    * {
      pointer-events: none;
    }
  }

  &__marker-info {
    display: flex;
    font-size: 14px;
    transition: background 404ms linear;
    padding: 4px;
    cursor: auto;
    max-width: 300px;
  }

  &__logo {
    width: 50px;
    display: flex;
    margin-right: 10px;
    flex: none;
  }

  &__city {
    font-weight: 600;
    margin-bottom: 4px;
  }
}
