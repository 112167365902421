$black: #000000;
$white: #ffffff;
$springWood: #F9F7F4;
$bracken: #4F2B04;
$tumbleweed: #DCAC78;
$gray: #888888;
$pearlBush: #EDE6E0;
$pampas: #F8F6F3;
$bone: #E8D8C8;
$pampasTwo: #EFECE8;
$mercury: #E4E4E4;
$seashell: #F1F1F1;
$silver: #CACACA;
$cloudy: #AEACA8;
$red: #E3000B;
$burntSienna: #EE5454;
$beautyBush: #F0CAC8;
$green: #45BD60;
$orange: #FF9900;
$fireBush: #E89C2B;
$bgGradient: linear-gradient(180deg, #F7F3EF 0%, #F9F7F5 57.81%)
