.element-location {
  display: flex;
  max-width: 300px;
  width: 100%;
  background: $pampas;
  border-radius: 5px;
  padding: 12px;

  &__picture {
    display: flex;
    flex-direction: column;
    margin-right: 10px;
    flex: none;
    width: 28px;
  }

  &__city {
    font-weight: 500;
  }
}
