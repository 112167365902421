h1, h2 {
  font-size: 24px;

  @media ($desktop) {
    font-size: 40px;
  }
}

h3 {
  font-size: 18px;
}

b {
  font-weight: bold;
}

.bold {
  font-weight: 500;
}


