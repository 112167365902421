.c-table {
  box-shadow: 0 0 10px rgba($black, .1);
  border-radius: 10px;
  background: $white;

  &__header {
    background: #F7F3F0;
    display: none;
    align-items: center;
    padding: 10px 15px 6px;
    font-size: 12px;
    font-weight: bold;
    color: #AEACA8;

    @media ($tablet) {
      display: flex;
    }
  }

  &__header,
  .c-table-row {
    > div:not(.c-table__photo) {
      padding: 0 10px;
    }
  }

  &__photo {
    @media ($tablet) {
      width: 125px;
      flex: none;
    }
  }

  &__name {
    flex-grow: 1;
    width: calc(100% - 60px);
  }

  &__weight {
    display: none;

    @media ($tablet) {
      display: flex;
      min-width: 120px;
    }
  }

  &__price {
    min-width: 140px;
    order: 1;
    flex-grow: 1;

    @media ($tablet) {
      order: 0;
      flex-grow: 0;
    }
  }

  &__status {
    min-width: 75px;
  }

  &__action {
    min-width: 60px;
    order: 2;

    @media ($large) {
      order: 0;
      min-width: 150px;
    }
  }
}
