.pagination {
  display: flex;
  align-items: center;
  justify-content: center;

  button:not([rel]),
  a:not([rel]),
  .active span {
    @include adaptive-value('width', 30, 25, 1);
    @include adaptive-value('height', 30, 25, 1);
    display: flex;
    align-items: center;
    justify-content: center;
    border: none;
    border-radius: 5px;
    margin: 0 2px;
    cursor: pointer;
  }

  button:not([rel]),
  a:not([rel]) {
    transition: background 404ms linear;

    &:hover {
      background: #EFECE8;
    }

    &::before,
    &::after {
      content: none;
    }
  }

  .active span {
    background: $tumbleweed;
    font-weight: 500;
  }

  [aria-label] {
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 5px;
    width: 40px;
    height: 30px;
    margin: 0 4px;
    font-size: 0;
    border: none;
    background: $bracken;
    background-image: url("/images/icons/arrow-pagination-small.svg");
    background-repeat: no-repeat;
    background-position: center;
    transition: opacity 404ms linear;
    cursor: pointer;

    &:hover {
      opacity: .8;
    }

    &::before,
    &::after {
      content: none;
    }

    @media ($tablet) {
      width: 60px;
      background-image: url("/images/icons/arrow-pagination.svg");
    }
  }

  [aria-label*="«"] {
    transform: scaleX(-1);
  }

  > .disabled {
    mix-blend-mode: difference;
    background-color: #545454;
    opacity: 0.4;
    pointer-events: none;
  }

  .m-top {
    margin-top: 20px;
  }
}
