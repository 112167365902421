.img-text-col {
  display: flex;
  flex-direction: column;
  position: relative;

  &::after {
    content: '';
    position: absolute;
    left: -10px;
    top: 10px;
    background: url("/images/bg/img-text-col.svg") no-repeat top left;
    background-size: contain;
    height: 70%;
    max-height: 110px;
    width: 100%;
    z-index: -1;
  }

  &__picture {
    width: 80px;
    display: flex;
    align-items: center;
    flex: none;
    margin-bottom: 10px;
  }

  &__title {
    margin-bottom: 5px;
    font-size: 24px;
  }

  &__description {
    font-size: 14px;
    line-height: 21px;
  }
}
