.work-schedule {
  display: flex;
  flex-direction: column;
  margin-bottom: 5px;

  &__title {
    font-size: 16px;
    font-weight: bold;
    color: $gray;
    margin-bottom: 10px;

    @media ($tablet) {
      font-size: 18px;
    }
  }

  &__items {
    display: flex;
    flex-direction: column;
  }

  &__item + &__item {
    margin-top: 10px;
  }

  &__item {
    display: flex;
    align-items: center;
    font-size: 14px;

    .day {
      font-weight: bold;
      width: 50px;
      margin-right: 15px;
    }

    .hours {
      font-size: 14px;
    }
  }
}
