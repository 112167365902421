.user-menu {
  &__icon {
    display: flex;
    flex-direction: column;
    width: 20px;
    height: auto;
    margin-right: 10px;
  }

  &__item {
    font-size: 14px;
    font-weight: 500;
    display: flex;
    align-items: center;
    line-height: 30px;
  }

  &__top {
    padding: 20px 0;
    background: $springWood;
    position: relative;
    display: flex;

    @media ($large-handset) {
      display: none;
    }

    &::before,
    &::after {
      content: '';
      position: absolute;
      top: 0;
      height: 100%;
      width: 100vw;
      background: $springWood;
      z-index: -1;
    }

    &::before {
      right: calc(100% - 2px);
    }

    &::after {
      left: calc(100% - 2px);
    }
  }

  &__items & {
    &__item {
      margin-top: 20px;
    }
  }
}
