.counter {
  display: flex;
  background: $pampasTwo;
  border-radius: 25px;
  overflow: hidden;
  height: 30px;

  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  input[type=number] {
    -moz-appearance: textfield;
    border: none;
    background: transparent;
    outline: none;
    text-align: center;
    width: 40px;
  }

  &__item {
    width: 30px;
    height: 100%;
    cursor: pointer;
    transition: opacity, background 404ms linear;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: 500;

    &:hover {
      background: $silver;
      opacity: .6;
    }
  }
}
