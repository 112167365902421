.contracts {
  display: grid;
  grid-template-columns: repeat(auto-fit, 1fr);
  gap: 20px;

  @media ($tablet) {
    grid-template-columns: repeat(auto-fit, minmax(320px, calc(50% - 10px)));
  }

  &__item {
    display: flex;
    flex-wrap: wrap;
  }

  &__number {
    width: 100%;
    margin-bottom: 15px;

    @media ($large) {
      width: 33%;
      margin-bottom: 0;
    }

    .paragraph {
      font-weight: 500;
    }
  }

  &__date-start,
  &__date-end {
    width: 50%;

    @media ($large) {
      width: 33%;
    }
  }

}
