.static-block {
  display: grid;
  gap: 30px;
  font-size: 14px;
  line-height: 1.5;

  .other-item {
    display: flex;

    &--full {
      width: 100vw;
      margin-inline-start: 50%;
      transform: translateX(-50%);
    }
  }

  &--other-text {
    @media ($tablet) {
      grid-template-columns: 1fr 1fr;

      .other-item {
        order: 0;
      }

      .content {
        order: 1;
      }
    }
  }

  &--text-other {
    @media ($tablet) {
      grid-template-columns: 1fr 1fr;

      .other-item {
        order: 1;
      }

      .content {
        order: 0;
      }
    }
  }

  &--full-width {
    width: 100%;
    display: flex;
    flex-direction: column;
  }

  img,
  iframe {
    width: 100%;
    height: auto;
    object-fit: cover;
  }

  img {
    border-radius: 30px;
  }

  iframe {
    aspect-ratio: 16/9;
  }
}
