.mobile-menu {
  position: fixed;
  height: calc(100% - 60px);
  width: 100%;
  left: 0;
  background: $white;
  z-index: 10;
  display: flex;
  flex-direction: column;
  opacity: 0;
  visibility: hidden;
  pointer-events: none;
  transition: all 404ms linear;

  @media ($desktop) {
    display: none;
  }

  &.show-menu {
    opacity: 1;
    visibility: visible;
    pointer-events: auto;
  }

  &__wrapper {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    padding: 0 30px;
    overflow-y: auto;
    overflow-x: hidden;
  }

  &__lang {
    padding: 20px 0;
  }

  .copyright {
    position: relative;

    &::after {
      content: '';
      position: absolute;
      width: 100%;
      height: 1px;
      background: $seashell;
      top: 0;
      left: 0;
    }
  }
}
