.info-place {
  display: flex;
  font-size: 14px;
  line-height: 1.2;

  &__left {
    padding-right: 20px;

    a {
      font-weight: 500;
    }
  }

  &__logo {
    width: 62px;
    height: 50px;
    display: flex;
    flex-direction: column;
    margin-bottom: 10px;
  }

  &__right {

  }

  &__phones {
    .item + .item {
      margin-top: 3px;
    }
  }

  &__city {
    font-weight: 500;
    margin-bottom: 5px;
  }

  &__street {
    margin-bottom: 5px;
  }

  &__schedule-name {
    margin-top: 15px;
    font-weight: 700;
    font-size: 12px;
    color: #888888;
  }

  &__schedule-items {
    display: flex;
    flex-direction: column;

    .item {
      display: flex;
      align-items: center;
      line-height: 1.1;
      margin-top: 6px;
    }

    .day {
      font-weight: 500;
      width: 55px;
      padding-right: 10px;
    }
  }
}
