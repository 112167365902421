.menu-item {
  display: flex;
  flex-direction: column;
  margin-bottom: 30px;

  &__picture {
    display: flex;
    border-radius: 10px;
    overflow: hidden;

    img {
      object-fit: cover;
      min-height: 180px;
      max-height: 180px;
      width: 100%;
      transition: transform 404ms linear;

      @media (max-width: 500px){
          min-height: 106px;
          max-height: 106px;
          max-width: 160px;
      }

    }
  }

  @media (hover: hover) {
    cursor: pointer;

    &:hover & {
      &__picture img {
        transform: scale(1.1) rotate(-2deg);
      }
    }
  }

  &__name {
    font-size: 14px;
    font-weight: 500;
    margin-top: 8px;
    text-align: center;

    @media ($tablet) {
      font-size: 16px;
    }
  }
}
