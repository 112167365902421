.feedback {
  display: flex;
  flex-direction: column;
  padding-top: 10px;
  padding-bottom: 10px;
  position: relative;

  @include breakpoint('lg') {
    padding-top: 40px;
    padding-bottom: 30px;
  }

  &::before,
  &::after {
    content: '';
    position: absolute;
    top: 0;
    width: 100%;
    height: 100%;
    border-radius: 0 70px;

    @include breakpoint('lg') {
      width: calc(100% + 140px);
      border-radius: 0 150px;
    }
  }

  &::after {
    left: 0;
    background: rgba($white, .8);
    z-index: -1;

    @include breakpoint('lg') {
      left: -70px;
    }
  }

  &::before {
    border: 2px dashed $tumbleweed;
    left: -10px;
    top: 10px;
    z-index: -2;

    @include breakpoint('lg') {
      left: -90px;
      top: 20px;
    }
  }

  &__main {
    @include adaptive-value('gap', 240, 60, 1);
    display: grid;
    padding-top: 50px;
    padding-bottom: 60px;
    padding-inline: 20px;

    @include breakpoint('lg') {
      grid-template-columns: repeat(2, 1fr);
      padding-inline: 30px;
    }

    &--one-item {
      grid-template-columns: 1fr;

      .feedback__item {
        max-width: 460px;
        width: 100%;
        margin-inline: auto;
      }
    }
  }

  &__items {
    display: flex;
    flex-direction: column;
    gap: 40px;
  }

  &__item {
    display: flex;
    flex-direction: column;
    gap: 20px;

    form {
      display: grid;
    }

    input,
    textarea:not(.is-invalid) {
      border-color: transparent;
      box-shadow: 0 4px 39px rgba($black, .05);
    }

    &:nth-child(2) {
      position: relative;

      &::after {
        @include adaptive-value('top', -120, -30, 1);
        content: '';
        position: absolute;
        height: 1px;
        left: 0;
        width: 100%;
        background: repeating-linear-gradient(
            to right,
            $tumbleweed,
            $tumbleweed 10px,
            transparent 10px,
            transparent 20px
        );

        @include breakpoint('lg') {
          @include adaptive-value('left', -120, -30, 1);
          top: -10%;
          height: 120%;
          width: 1px;
          background: repeating-linear-gradient(
              to bottom,
              $tumbleweed,
              $tumbleweed 10px,
              transparent 10px,
              transparent 20px
          );
          transform: translateX(-50%);
        }
      }
    }
  }

  &__action {
    display: flex;
    justify-content: center;

    [type="submit"] {

      @include breakpoint('sm') {
        width: auto;
        min-width: 200px;
      }
    }
  }
}
