.profile-information {
  display: flex;
  flex-direction: column;
  padding: 15px 10px 30px;
  height: 100%;

  @media ($large) {
    padding: 30px;
  }
}
