.form-tab-lang {
  display: flex;
  align-items: center;
  border-bottom: 1px solid $pearlBush;
  margin-bottom: 20px;

  &__item {
    max-width: 150px;
    width: 100%;
    display: flex;
    justify-content: center;
    line-height: 1;
    padding-bottom: 10px;
    cursor: pointer;
    position: relative;

    &::before {
      content: '';
      position: absolute;
      bottom: 0;
      left: 0;
      border-radius: 3px;
      height: 3px;
      width: 100%;
      background: $bracken;
      transform: translateY(50%);
      opacity: 0;
      transition: opacity 404ms linear;
    }

    &:hover {
      &::before {
        opacity: 1;
        background: rgba($bracken, .6);
      }
    }

    &.active {
      transition: font-weight 404ms linear;
      font-weight: 500;

      &::before {
        opacity: 1;
      }
    }
  }
}

[data-lang-item] {
  display: none;

  &.show {
    display: flex;
  }
}
