.statistics {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: -20px;
  position: relative;
  z-index: 1;

  @media ($tablet) {
    margin-top: -80px;
    flex-direction: row;
    justify-content: flex-end;
  }

  &__title {
    font-size: 20px;
    padding: 30px 14px;
    border-radius: 20px;
    overflow: hidden;
    box-shadow: 0 4px 10px rgba($black, .08);
    display: inline-block;
    max-width: 350px;
    margin: 0 25px;
    position: relative;

    &::after {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background: rgba($white, .8);
      backdrop-filter: blur(20px);
      z-index: -1;
    }

    @media ($tablet) {
      margin: 0;
      transform: translateX(30px);
      font-size: 24px;
    }

    @media ($desktop) {
      padding: 50px 20px;
      max-width: 370px;
    }

    b {
      font-weight: bold;
    }
  }

  &__items {
    padding: 40px 20px 25px;
    border-radius: 20px;
    overflow: hidden;
    box-shadow: 0 4px 10px rgba($black, .08);
    margin-top: -35px;
    position: relative;
    z-index: -2;

    &::after {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background: rgba($white, .8);
      backdrop-filter: blur(20px);
      z-index: -1;
    }

    @media ($tablet) {
      margin-top: 0;
      padding: 25px 50px;
    }

    @media ($desktop) {
      display: flex;
      align-items: flex-start;
    }
  }

  &__item {
    display: flex;
    align-items: center;

    @media ($desktop) {
      flex-direction: column;
      margin: 0 15px;
    }
  }

  &__number {
    font-size: 30px;
    font-weight: 300;
    min-width: 120px;
    display: flex;

    @media ($desktop) {
      justify-content: center;
    }
  }

  &__label {
    text-align: center;
    flex-grow: 1;

    @media ($desktop) {
      max-width: 150px;
    }
  }
}
