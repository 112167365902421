.copyright {
  display: flex;
  align-items: center;
  justify-content: center;
  line-height: 44px;
  background: $white;
  position: relative;

  &::before,
  &::after {
    content: '';
    position: absolute;
    top: 0;
    height: 100%;
    width: 100vw;
    background: $white;
  }

  &::before {
    right: calc(100% - 2px);
  }

  &::after {
    left: calc(100% - 2px);
  }

  a {
    font-weight: bold;

    span {
      color: #e2231a;
    }
  }
}
