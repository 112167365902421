.search-shop-block {
  padding: 15px;
  box-shadow: 0 4px 15px rgba($black, .08);
  position: relative;
  z-index: 5;
  width: 100%;

  @include breakpoint('md') {
    border-radius: 30px;
    padding: 30px 25px;
    max-width: 500px;
  }

  &::after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba($white, 0.6);
    -webkit-backdrop-filter: blur(20px);
    backdrop-filter: blur(20px);
    z-index: -1;
    border-radius: inherit;
  }

  &__title {
    margin-bottom: 10px;
  }

  &__description {
    font-size: 14px;
    margin-bottom: 5px;
  }
}
