.partner-menu {
  margin: 0 -30px;

  &__item {
    &.open-menu {
      background: #F7F3F0;

      .partner-menu__sub-link {
        height: auto;
    }
      .expanded::after {
        transform: translateY(-50%) rotate(90deg);
      }
    }


  }

  &__link {
    display: flex;
    padding: 15px 30px;
    position: relative;
    transition: background 404ms linear;

    &:hover {
      background: $pearlBush;
    }

    &.active {
      background: $pearlBush;

      &::before {
        content: '';
        position: absolute;
        top: 50%;
        left: 0;
        width: 5px;
        transform: translateY(-50%);
        background: $bracken;
        height: calc(100% - 10px);
        border-radius: 0 4px 4px 0;
      }
    }

    &.expanded {
      &::after {
        content: '';
        position: absolute;
        right: 35px;
        top: 50%;
        transform: translateY(-50%);
        background: url("/images/icons/small-arrow.svg") no-repeat center;
        transition: transform 404ms linear;
        width: 10px;
        height: 10px;
      }

      &.active {
        border-bottom: 1px solid $pearlBush;
      }
    }
  }

  &__link {
    display: flex;
    align-items: center;
    color: $bracken;
    font-size: 16px;
    font-weight: 500;

    .icon {
      display: flex;
      justify-content: center;
      align-items: center;
      margin-right: 10px;
      width: 20px;
      height: 20px;

      &--left {
        margin-right: 0;
        margin-left: 15px;
      }
    }
  }

  &__sub-link {
    display: flex;
    flex-direction: column;
    height: 0;
    overflow: hidden;
    transition: height 404ms linear;



    a {
      padding: 10px 60px;
      transition: background 404ms linear;

      &.active {
        font-weight: 700;
      }

      &:hover {
        background: $pearlBush;
      }
    }
  }
}
